import { render, staticRenderFns } from "./VadSpecificDetails.vue?vue&type=template&id=6859c7eb&"
import script from "./VadSpecificDetails.vue?vue&type=script&lang=ts&"
export * from "./VadSpecificDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/components/organs/heart/_locales/VadSpecificDetails.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Forgans%2Fheart%2FVadSpecificDetails.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports