
import { Getter, State } from "vuex-class";
import { GenericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import { Component, Prop, Vue } from "vue-property-decorator";
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { LivingDonor, LivingDonorJourney } from '@/store/livingDonors/types';

// Define this sub-section's editable form fields
interface DonationDestionationForm {
  donateTo: string|null;
  destinationSpecification: string|null;
}

// Configuration for per-organ destination options
interface DestinationConfig {
  organ_code: OrganCodeValue;
  options: DestinationOption[];
}

// Options available for the 'Living Donor to donate to' field
interface DestinationOption {
  code: DestinationCode;
  value: string;
  journey_params?: LivingDonorJourney;
  specification_field?: string;
  destination_specifications?: DestinationSpecification[]
}

// Options available for secondary destination specification dropdown
interface DestinationSpecification {
  code: DestinationCode;
  value: string;
  journey_params?: LivingDonorJourney;
}

// This sub-section is only used for certain organ types
const DESTINATION_ORGANS = [
  OrganCodeValue.Lung,
  OrganCodeValue.Liver,
  OrganCodeValue.Kidney,
];

// Define option codes (note: these string codes are NOT saved to the back-end)
enum DestinationCode {
  DirectedDonation = 'DIRECTED_DONATION',
  NonDirectedDonation = 'NON_DIRECTED_DONATION',
  PreSelectedRecipient = 'PRE_SELECTED_RECIPIENT',
  ToBeAllocatedToARecipient = 'TO_BE_ALLOCATED_TO_A_RECIPENT',
  IntendedRecipient = 'INTENDED_RECIPIENT',
  KidneyPairedDonation = 'KIDNEY_PAIRED_DONATION',
  ListExchange = 'LIST_EXCHANGE',
  NonDirectedEndOfChain = 'NON_DIRECTED_END_OF_CHAIN',
}

// Configure mapping of destination options (note: these boolean properties are what actually get saved)
const DESTINATION_CONFIGURATIONS: DestinationConfig[] = [
  {
    organ_code: OrganCodeValue.Lung,
    options: [
      {
        code: DestinationCode.DirectedDonation,
        value: 'directed_donation_option',
        journey_params: {
          directed_donation: true,
        },
      }
    ],
  },
  {
    organ_code: OrganCodeValue.Liver,
    options: [
      {
        code: DestinationCode.DirectedDonation,
        value: 'directed_donation_option',
        journey_params: {
          directed_donation: true,
          kidney_paired_donation: false,
          list_exchange: false,
        },
      },
      // tp-14191, based on the ticket commenting out for now to hide this option from dropdown
      // {
      //   code: DestinationCode.NonDirectedDonation,
      //   value: 'non_directed_donation_option',
      //   journey_params: {
      //     directed_donation: false,
      //     kidney_paired_donation: false,
      //     list_exchange: false,
      //   },
      // },
    ],
  },
  {
    organ_code: OrganCodeValue.Kidney,
    options: [
      {
        code: DestinationCode.PreSelectedRecipient,
        value: 'pre_selected_recipient_option',
        journey_params: {
          directed_donation: true,
        },
        specification_field: 'pre_selected_recipient_field',
        destination_specifications: [
          {
            code: DestinationCode.IntendedRecipient,
            value: 'intended_recipient_option',
            journey_params: {
              kidney_paired_donation: false,
              list_exchange: false,
            },
          },
          {
            code: DestinationCode.KidneyPairedDonation,
            value: 'kidney_paired_donation_option',
            journey_params: {
              kidney_paired_donation: true,
              list_exchange: false,
            },
          },
        ]
      },
      {
        code: DestinationCode.ToBeAllocatedToARecipient,
        value: 'to_be_allocated_to_a_recipient_option',
        journey_params: {
          directed_donation: false,
        },
        specification_field: 'to_be_allocated_to_a_recipient_field',
        destination_specifications: [
          {
            code: DestinationCode.ListExchange,
            value: 'list_exchange_option',
            journey_params: {
              kidney_paired_donation: false,
              list_exchange: true,
            },
          },
          {
            code: DestinationCode.NonDirectedDonation,
            value: 'non_directed_donation_option',
            journey_params: {
              kidney_paired_donation: false,
              list_exchange: false,
            },
          },
          {
            code: DestinationCode.NonDirectedEndOfChain,
            value: 'non_directed_end_of_chain_option',
            journey_params: {
              kidney_paired_donation: true,
              list_exchange: false,
            },
          },
        ],
      },
    ],
  }
];

@Component({
  components: {
    SubSection,
    SelectInput,
  }
})
export default class DonationDestination extends Vue implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.donationDestination) editState!: DonationDestionationForm;

  // Getters
  @Getter('show', { namespace: 'livingDonors' }) private selectedLivingDonor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) private selectedLivingDonorId!: string;
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;
  @Getter('isLockRecoveryAndDonorInformation', { namespace: 'livingDonors' }) private isLockRecoveryAndDonorInformation!: boolean;

  // Properties
  @Prop({ default: false }) newLivingDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Show this sub-section's save button?
  get showSaveButton(): boolean {
    return !this.newLivingDonor && this.canSave;
  }

  // Does this sub-section apply to the selected Living Donor Journey
  get isDestinationSectionApplicable(): boolean {
    if (!this.selectedLivingDonorJourney) return false;

    const organCode = this.selectedLivingDonorJourney.organ_code;
    if (!organCode) return false;

    return DESTINATION_ORGANS.includes(organCode);
  }

  // Disable this sub-section?
  get isDisabled(): boolean {
    if (this.newLivingDonor) return true;
    return !this.isDestinationSectionApplicable || this.isLockRecoveryAndDonorInformation;
  }

  // Which per-organ option configuration is applicable?
  get organDestinationConfig(): DestinationConfig|null {
    if (!this.selectedLivingDonorJourney) return null;

    // Fetch per-organ options from structured option constant defined above
    const organCode = this.selectedLivingDonorJourney.organ_code;
    const result = DESTINATION_CONFIGURATIONS.find((donateToOption: DestinationConfig) => {
      return donateToOption.organ_code == organCode;
    });
    return result || null;
  }

  // Per-organ options available for the 'Living Donor to donate to' dropdown
  get donateToOptions(): GenericCodeValue[] {
    if (!this.organDestinationConfig) return [];

    // Map any options we found to an array of translated dropdown options
    const options = this.organDestinationConfig.options || [];
    const result = options.map((donateToOption: DestinationOption): GenericCodeValue => {
      return {
        code: donateToOption.code,
        value: this.$t(donateToOption.value).toString(),
      };
    });
    return result;
  }

  // Which per-organ option has been selected?
  get selectedDestinationOption(): DestinationOption|null {
    if (!this.editState || !this.organDestinationConfig) return null;

    // Find option metadata based on the selected code in editable form state
    const selectedCode = this.editState.donateTo;
    const options = this.organDestinationConfig.options || [];
    const selectedOption = options.find((donateToOption: DestinationOption) => {
      return donateToOption.code === selectedCode;
    });

    return selectedOption || null;
  }

  // Secondary options depending on what the main 'Living Donor to date to' dropdown was set to
  get destinationSpecificationOptions(): GenericCodeValue[] {
    if (!this.selectedDestinationOption) return [];

    // Check metadata for secondary options
    const options = this.selectedDestinationOption.destination_specifications || [];
    const result = options.map((destinationSpecification: DestinationSpecification): GenericCodeValue => {
      return {
        code: destinationSpecification.code,
        value: this.$t(destinationSpecification.value).toString(),
      };
    });
    return result;
  }

  // Which specification in the secondary dropdown has been selected?
  get selectedDestinationSpecification(): DestinationSpecification|null {
    if (!this.editState || !this.selectedDestinationOption) return null;

    // Find specification metadata based on the selected code in editable form state
    const selectedCode = this.editState.destinationSpecification;
    const specifications = this.selectedDestinationOption.destination_specifications || [];
    const selectedSpecification = specifications.find((destinationSpecification: DestinationSpecification) => {
      return destinationSpecification.code === selectedCode;
    });

    return selectedSpecification || null;
  }

  // Does the selected option have any secondary options to select from?
  get showDestinationSpecification(): boolean {
    return this.destinationSpecificationOptions.length > 0;
  }

  // What field name key should be used for the secondary dropdown?
  get destinationSpecificationNameKey(): string|null {
    if (!this.selectedDestinationOption) return null;

    return this.selectedDestinationOption.specification_field || null;
  }

  // Clear secondary dropdown when the first dropdown field changes
  private onDonateToChanged(): void {
    if (!this.editState) return;

    Vue.set(this.editState, 'destinationSpecification', null);
  }

  private mounted(): void {
    this.initializeForm();
  }

  // Build an editable form state and commit it to the page state module
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'donationDestination',
      value: this.buildDonationDestinationForm(),
    });
  }

  // Re-initialize this sub-section
  public reinitialize(): void {
    this.initializeForm();
  }

  // Define form state for user interaction
  public buildDonationDestinationForm(): DonationDestionationForm {
    const donor: LivingDonor|null = this.selectedLivingDonor;
    const journey: LivingDonorJourney|null = this.selectedLivingDonorJourney;

    // Setup default form fields
    const result: DonationDestionationForm = {
      donateTo: null,
      destinationSpecification: null,
    };

    // Fetch per-organ destination option metadata from configuration constant
    const organDestinationConfig: DestinationConfig|null = DESTINATION_CONFIGURATIONS.find((destinationConfig: DestinationConfig) => {
      return destinationConfig.organ_code === journey?.organ_code;
    }) || null;
    const options = organDestinationConfig?.options || [];

    // Search options based on donor-level and journey-level boolean parameters
    const params: LivingDonorJourney = {
      directed_donation: journey?.directed_donation,
      list_exchange: journey?.list_exchange,
      kidney_paired_donation: donor?.indicators?.kidney_paired_donation,
    };
    const matchingOption: DestinationOption|null = options.find((option: DestinationOption) => {
      return option?.journey_params?.directed_donation == params.directed_donation;
    }) || null;

    // Search additional specifications if the matching option has them defined
    const specifications = matchingOption?.destination_specifications || [];
    const matchingSpecification: DestinationSpecification|null = specifications.find((specification: DestinationSpecification) => {
      return specification?.journey_params?.kidney_paired_donation === params.kidney_paired_donation && specification?.journey_params?.list_exchange === params.list_exchange;
    }) || null;

    // Set form fields based on first matching option and specification found in configuration constant
    if (matchingOption) Object.assign(result, {
      donateTo: matchingOption.code
    });
    if (matchingSpecification) Object.assign(result, {
      destinationSpecification: matchingSpecification.code
    });

    return result;
  }

  // Define request payload for saving
  public extractPatch(): LivingDonorJourney {
    const result: LivingDonorJourney = {
      directed_donation: null,
      list_exchange: null,
      kidney_paired_donation: null,
    };

    // Fetch journey parameters for any selected 'donate to' option 
    if (this.selectedDestinationOption) {
      Object.assign(result, this.selectedDestinationOption.journey_params || {});
    }

    // Fetch journey parameters for any selected specification option
    if (this.selectedDestinationSpecification) {
      Object.assign(result, this.selectedDestinationSpecification.journey_params || {});
    }

    return result;
  }

  // Save this sub-section
  public savePatch(): void {
    const livingDonorJourneyId = this.selectedLivingDonorJourney?._id?.$oid || null;
    const livingDonorJourneyPatch: LivingDonorJourney = this.extractPatch();
    const params = {
      livingDonorId: this.selectedLivingDonorId,
      livingDonorJourneyId,
      livingDonorJourneyPatch,
    };
    const saveProvider = this.$refs.saveDonationDestination as unknown as SaveProvider;
    this.$emit('clear');
    this.$store.dispatch('livingDonors/saveLivingDonorJourney', params).then((success: SaveResult) => {
      // If successful, reload living donor and show success notification
      this.$emit('reload');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      saveProvider.registerSaveResult(error);
      this.$emit('handleErrors', error);
    });
  }

  // Reset this sub-section's save toolbar
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveDonationDestination as unknown as SaveProvider;
    if (saveProvider) saveProvider.resetSaveToolbar();
  }

  // Define which input elements in the template should display which field-level validation errors
  public idLookup(): {[key: string]: string} {
    const result = {
      'kidney_paired_donation' : 'donation-destination-destination-specification',
      'list_exchange'          : 'donation-destination-destination-specification',
    };

    // Show errors for 'directed vs non-directed' on secondary dropdown if there is one
    if (this.showDestinationSpecification) {
      Object.assign(result, {
        'directed_donation' : 'donation-destination-destination-specification',
      });
    } else {
      Object.assign(result, {
        'directed_donation' : 'donation-destination-donate-to',
      });
    }
    return result;
  }
}
