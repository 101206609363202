
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from "vuex-class";
import { TableConfig } from "@/types";
import { RootState, ObjectId } from "@/store/types";
import TextInput from "@/components/shared/TextInput.vue";
import SelectInput from "@/components/shared/SelectInput.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import DateInput from "@/components/shared/DateInput.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import CheckboxInput from "@/components/shared/CheckboxInput.vue";
import CardSection from "@/components/shared/CardSection.vue";
import SubSection from "@/components/shared/SubSection.vue";
import { PancreasIsletsInfusion } from "@/store/organSpecificDetails/types";
import { Recipient } from "@/store/recipients/types";
import {AllocatedDonorDetails, RecipientJourney} from "@/store/recipientJourney/types";
import { SaveProvider, SaveResult } from "@/types";
import { PancreasIsletsSpecificPageState } from "@/components/organs/pancreas/PancreasIsletsSpecificDetails.vue";
import { DonorDetails } from "@/store/allocations/types";

export interface InfusionForm {
  _id?: { $oid: string };
  infusionDate?: string;
  donorId?: string | undefined | null;
  deceasedDonorId?: string | null;
  clientId?: string | null;
  finalInfusion?: boolean;
}

interface InfusionRow {
  _id?: ObjectId;
  infusionDate?: string;
  donorId?: string;
  deceasedDonorId?: string | number | null;
  finalInfusion?: string;
}

@Component({
  components: {
    DateInput,
    TextInput,
    SubSection,
    NumberInput,
    CardSection,
    CheckboxInput,
    SelectInput
  }
})
export default class Infusion extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.pageState.currentPage.pancreasIsletsDetails)  editState!: PancreasIsletsSpecificPageState;
  @State(state => state.organSpecificDetails.pancreasInfusion)  private pancreasInfusions!: PancreasIsletsInfusion[];
  @State(state => state.organSpecificDetails.selectedPancreasInfusion) private selectedPancreasInfusion!: PancreasIsletsInfusion;

  // Getters
  @Getter("selectedJourney", { namespace: "journeyState" }) private journey!: RecipientJourney;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter("allocatedDonorDetails", { namespace: "journeyState" }) allocatedDonor!: AllocatedDonorDetails[];
  @Getter("noAllocatedDonorIdOptionsText", { namespace: "journeyState" }) noDonorIdOptionsText!: string;
  @Getter("isTransplantDetailsApplicable", { namespace: "journeyState" }) isTransplantDetailsApplicable!: boolean;
  @Getter('isLastEntry', { namespace: 'utilities' }) private isLastEntry!: (id: ObjectId, entries: any[]) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

   /**
   * Return true if we can edit the selected pancreas infusion
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }

    if (!!this.pancreasInfusions && !!this.selectedPancreasInfusion) {
      if (this.selectedPancreasInfusion._id?.$oid) {
        const is_final_infusion = this.pancreasInfusions.find((item: PancreasIsletsInfusion)=> {
          return item.final_infusion;
        });
        return this.isLastEntry(this.selectedPancreasInfusion._id, this.pancreasInfusions) && ( is_final_infusion ? false : true );
      }
    }

    if(!!this.pancreasInfusions) {
       const isFinalInfusion = this.pancreasInfusions.find((item: PancreasIsletsInfusion)=> {
         return item.final_infusion;
       });
       return isFinalInfusion?.final_infusion ? false : true;
    }

    return true;
  }

   /**
   * Gets a string used to populate the confirmation alert dialog when saving final infusion
   *
   * @returns {string|undefined} text for confirmation property of card section
  */
  get confirmationText(): string|undefined {
    if (!this.editState?.infusion?.finalInfusion) {
      return undefined;
    } else {
      // only show dialog box when certain final infusion is marked
      return this.$t('infusion_marked_final').toString();
    }
  }


  /**
   * Loads a form edit state based on the procedure, or a new state if there is none
   *
   * @param procedure Pancreas Islets procedure fetched from API, or undefined
   */
  public initializePancreasInfusion(procedure?: PancreasIsletsInfusion): void {
    // Clear table selections from subsection component
    this.$store.commit('organSpecificDetails/selectPancreasInfusions', procedure);
    // Initialize subsection component form edit states
    this.$store.commit('pageState/set', {
      pageKey: 'pancreasIsletsDetails',
      componentKey: 'infusion',
      value: this.buildInfusionForm(procedure),
    });
  }

    /**
   * Returns Pancreas Infusion form edit state based on recipient pancreas infusion procedure document
   *
   * @param procedure Pancreas Infusion procedure document fetched from API
   * @returns {InfusionForm} editable infusion form state
   */
  public buildInfusionForm(procedure?: PancreasIsletsInfusion): InfusionForm {
    if (!procedure) {
      return { };
    }
    return {
      _id: procedure._id,

      infusionDate: procedure.infusion_date
        ? this.parseDateUi(procedure.infusion_date)
        : undefined,
      donorId: procedure.donor_id? procedure.donor_id.$oid : null,
      finalInfusion: procedure.final_infusion,
      deceasedDonorId: procedure.deceased_donor_id,
      clientId: procedure.client_id
    };
  }

  // Begin fetching the recipient's Pancreas Infusions
  public loadPancreasInfusions(): void {
    const recipientId = this.recipient.client_id;
    this.$store.dispatch("organSpecificDetails/loadPancreasInfusions", { recipientId: recipientId, journeyId: this.journeyId });
  }

    /**
   * Gets table configuration for the Pancreas Islets Infusion subsection table
   *
   * @returns {TableConfig} table configuration
   */
  public infusionTableConfig(): TableConfig {
    return {
      data: this.infusionRows || [],
      columns: [
        { label: this.$t('infusion_date').toString(), field: "infusionDate", width: "20%" },
        { label: this.$t('donor_id').toString(), field: "deceasedDonorId", width: "10%" },
        { label: this.$t('final_infusion').toString(), field: "finalInfusion", width: "10%" }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canEdit,
      createText: this.$t('create_infusion').toString(),
      pagination: true
    };
  }

  /**
   * Gets table data for recipient pancreas islets Infusions
   *
   * @returns {InfusionRow[]} infusion table data
   */
  get infusionRows(): InfusionRow[] {
    if ( !this.pancreasInfusions) {
      return [];
    }

    return this.pancreasInfusions.map((procedure: PancreasIsletsInfusion) => {
      return {
        _id: procedure._id,
        infusionDate: procedure.infusion_date? this.parseDisplayDateUi(procedure.infusion_date) : "-",
        deceasedDonorId: procedure.deceased_donor_id === undefined ? "-" : procedure.deceased_donor_id,
        finalInfusion: procedure.final_infusion ? this.$t('yes').toString() : this.$t('no').toString()
      };
    });
  }

 // PRIVATE

  /**
   * Builds form edit state based on selected document
   *
   * @param event select event
   */
  private selectInfusion(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row._id && event.row._id.$oid ? event.row._id!.$oid : undefined;
    if (!selectedId || !this.pancreasInfusions) {
      return;
    }
    // Find the selected source document
    const found = this.pancreasInfusions.find((each: PancreasIsletsInfusion) => {
      return each._id && each._id.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Build form state based on selected document
    this.initializePancreasInfusion(found);
  }

  /**
   * Build an empty new form edit state
   */
  public createInfusion(): void {
    this.$store.commit("pageState/set", {
      pageKey: "pancreasIsletsDetails",
      componentKey: "infusion",
      value: {}
    });
    this.initializePancreasInfusion();
    this.$emit('clear');
    this.resetSaveToolbar();
  }


  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = (this.$refs.saveInfusion as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  /**
   * PRIVATE
   */

  /**
   * Gets the entry for editSate
   *
   * Converts the entry for InfusionInformation from editState
   * into a payload ready for the API.
   *
   * @returns {PancreasIsletsInfusion} object containing any field changes
   */
  private extractInfusionPatch(): PancreasIsletsInfusion {
    if (!this.editState || !this.editState.infusion) {
      return {};
    } else {
      const infusion = this.editState.infusion;

      return {
        infusion_date: infusion.infusionDate? this.sanitizeDateApi(infusion.infusionDate) : null,
        donor_id: infusion.donorId ? { $oid: infusion.donorId } : null,
        final_infusion: infusion.finalInfusion,
        journey_id: this.journeyId
      };
    }
  }

    /**
   * Saves current form state for pancreas infusion
   */
  private saveInfusion(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveInfusion as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'infusion');
    // Generate payload based on current edit state
    const infusionPayload = {
      id: !!this.selectedPancreasInfusion ? this.selectedPancreasInfusion._id : undefined,
      recipientId: this.recipient.client_id,
      infusion: this.extractInfusionPatch(),
    };
    // Dispatch save action and register the response
    this.$store.dispatch('organSpecificDetails/savePancreasInfusion', infusionPayload).then((success: SaveResult) => {
     Promise.all([
        // Reload journey data and transplant
        this.$store.dispatch('recipients/get', this.recipient.client_id).then(() => {
          this.$store.dispatch('journeyState/getJourney', this.journeyId);
          this.$store.dispatch('journeyState/getTransplant',{journeyId: this.journeyId, recipientId: this.recipient.client_id});
        })
      ]).finally(() => {
        // If successful reload table data, clear form, and show success notification
        this.loadPancreasInfusions();
        this.createInfusion();
        saveProvider.registerSaveResult(success);
        // Clear any errors
        this.$emit('clear');
        this.$emit('reloadTransplantDetails');
      });
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }


  // Event handler for Donor ID Change
  private onDonorIdChanged(donorId: string): void {
    if (!this.editState || !this.editState.infusion) return;
    if(donorId){
      const formState = this.editState.infusion;
      const selectedDonor = this.allocatedDonor.find((allocatedDonorDetails: AllocatedDonorDetails) =>{
        return allocatedDonorDetails.code == donorId ? allocatedDonorDetails.donor_client_id : null;
      });
      Object.assign(formState, {
        clientId: selectedDonor?.donor_client_id
      });

      Vue.set(this.editState, 'infusion', formState);
    }
  }

   // API response keys on the left, id for our UI on the right
  public idLookup(): { [key: string]: string } {
    const result: { [key: string]: string } = {
      'pancreas_islets_infusions.infusion_date' : 'infusion_list_date',
      'pancreas_islets_infusions.donor_id' : 'donor_id',
      'pancreas_islets_infusions.final_infusion' : 'final-infusion'
    };
    return result;
  }

}
