
import { Getter, State } from "vuex-class";
import DateInput from "@/components/shared/DateInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import CardSection from "@/components/shared/CardSection.vue";
import SubSection from "@/components/shared/SubSection.vue";
import { RecipientJourney } from "@/store/recipientJourney/types";
import { Recipient } from "@/store/recipients/types";
import { PancreasIsletsDetails } from "@/store/organSpecificDetails/types";
import { SaveableSection, SaveProvider, SaveResult } from "@/types";
import InfusionSection, {
  InfusionForm
} from "@/components/organs/pancreas/InfusionSection.vue";

export interface PancreasIsletsSpecificPageState {
  comments?: string;
  infusion?: InfusionForm;
}

@Component({
  components: {
    DateInput,
    TextAreaInput,
    CardSection,
    SubSection,
    InfusionSection
  }
})
export default class PancreasIsletsSpecificDetails extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.pancreasIsletsDetails) editState!: PancreasIsletsSpecificPageState;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter("isTransplantDetailsApplicable", { namespace: "journeyState" }) isTransplantDetailsApplicable!: boolean;

  /**
   * Return true if pancreas islets details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Pancreas Islets Details card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @listens savePancreasIsletsSpecificDetails#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit("loaded", "pancreasIsletsDetails");
  }

    // load donor details on mount
  public mounted() {
    this.$store.dispatch('journeyState/getTransplant', { journeyId: this.journeyId, recipientId: this.recipientId });
  }

  /**
   * Populates the Pancreas Islets Specific Details form state with data from
   */
  public initializeForm(): void {
    // Initialize form edit state
    this.$store.commit("pageState/set", {
      pageKey: "pancreasIsletsDetails",
      value: {
        comments: (this.journey || {}).comments,
        infusion: { }
      }
    });
    // Begin fetching the recipient's Infusion
    this.$store.dispatch("recipients/get", this.recipientId);
    this.$store.commit('organSpecificDetails/clearPancreasInfusions');

    //Begin fetching the recipient's Pancreas Infusion
    this.$store.dispatch(
      "organSpecificDetails/loadPancreasInfusions",
      { 
        recipientId: this.recipientId,
        journeyId: this.journeyId
      }
    );
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Pancreas Islets Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.savePancreasIsletsSpecificDetails as unknown as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // If successful, reload the current recipient
      this.$store.dispatch('recipients/get', this.recipientId);
      // Register success result
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Show error notification
      saveProvider.registerSaveResult(error);
      // Emit event to handle errors
      this.$emit('handleErrors', error);
    });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Pancreas Islets Specific Details
   *
   * If the edit state doesn't exist return {}
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState) {
      return {};
    } else {
      return {
        comments: this.editState.comments
      };
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    const gci = (this.$refs
      .savePancreasIsletsSpecificDetails as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any): void {
    this.$emit("handleErrors", errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit("saving", formReference);
  }

  // Emit event to parent only for clear validations
  private clear() {
    this.$emit("clear");
  }
  
  // Emit event to parent to reload transplant details
  private reloadTransplantDetails() {
    this.$emit('reloadTransplantDetails');
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): { [key: string]: string } {
    // Infusion Section
    const result = {};
    const pancreasInfusionInformation = this.$refs
      .infusionSection as InfusionSection;
    if (pancreasInfusionInformation) {
      Object.assign(result, { ...pancreasInfusionInformation.idLookup() });
    }
    return result;
  }
}
