
import { Getter, State } from 'vuex-class';
import { recipients } from '@/store/recipients';
import { VirologyType } from '@/store/labs/types';
import { Recipient } from '@/store/recipients/types';
import PageTop from '@/components/shared/PageTop.vue';
import { Component, Vue } from 'vue-property-decorator';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import Demographics from '@/components/recipients/Demographics.vue';
import { SaveProvider, SaveResult } from '@/types';
import OrganReferrals from '@/components/recipients/OrganReferrals.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import ContactInformation from '@/components/recipients/ContactInformation.vue';
import GeneralClinicalInformation from '@/components/recipients/GeneralClinicalInformation.vue';
import VirologyResults from '@/components/virology/VirologyResults.vue';
import SideNavRecipientProfile from '@/components/recipients/side-nav/SideNavRecipientProfile.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import OrganReferral from '@/components/recipients/OrganReferrals.vue';
import PotentialDuplicateModal from "@/components/recipients/PotentialDuplicateModal.vue";
import RecipientDeath from "@/components/recipients/RecipientDeath.vue";
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingRecipientsPage from '@/components/shared/LoadingRecipientsPage.vue';

@Component({
  components: {
    PageTop,
    SaveToolbar,
    Demographics,
    OrganReferrals,
    RecipientDeath,
    VirologyResults,
    RecipientSummary,
    ContactInformation,
    SideNavRecipientProfile,
    GeneralClinicalInformation,
    RecipientStickySummary,
    PotentialDuplicateModal,
    LoadingSideNav,
    LoadingRecipientsPage,
  },
})
export default class EditRecipient extends Vue implements SaveProvider {
  // State
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;

  // Getters
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('clientId', { namespace: 'recipients' }) private clientId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  private dispatchEventsComplete = false;
  private sectionsLoaded = new Set();
  private allSectionsLoaded = false;

  /**
   * Returns enum value for VirologyType.Recipient
   *
   * @returns {number} enum value for Recipient
   */
  get virologyType(): number {
    return VirologyType.Recipient;
  }

  // Vue lifecycle hooks
  public mounted(): void {
    const recipientIdentifier = this.clientId;
    Promise.all([
      this.$store.commit('setPageTitle', `Recipients / ${this.recipientDisplayName}`),
      this.$store.dispatch('validations/loadEdit', { view: 'recipients', action: 'edit_validations', clientId: recipientIdentifier })
    ]).finally(() => {
      this.dispatchEventsComplete = true;
    });
  }

  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }

  public loaded(ref: string): void {
    if (!ref) return;
    // Create a set of all the sections to load filtering out sections we don't care about
    const sectionsToLoad = new Set(Object.keys(this.$refs).filter((ref: string) => !ref.match(/validations|saveRecipient|potentialDuplicateModal/)));
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === sectionsToLoad.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }

  public handleContinueDemographics(): void {
    (this.$refs.demographics as Demographics).savePatch(true);
  }
  public handleContinueContactInfo(): void {
    (this.$refs.contactInfo as ContactInformation).savePatch(true);
  }
  public handleContinueGeneralClinicalInformation(): void {
    (this.$refs.generalClinical as GeneralClinicalInformation).savePatch(true);
  }

  public handleWarnings(errors: SaveResult, target: string): void {
    (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(errors, target);
  }

  // Handle saving triggered by local save button
  public performSave(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Ref for each component required in the patch
    const demographics = this.$refs.demographics as Demographics;
    const contactInfo = this.$refs.contactInfo as ContactInformation;
    const generalClinical = this.$refs.generalClinical as GeneralClinicalInformation;
    const virology = this.$refs.virology as VirologyResults;
    const death = this.$refs.death as RecipientDeath;
    // Extract demographics and general clinical (urgent and diagnostics)
    const recipientPatch: Recipient = {
      ...generalClinical.extractPatch(),
      ...demographics.extractPatch(potential_duplicate_profile_confirmed),
      ...death.extractPatch()
    };
    // Extract contact info and merge with patient profile
    recipientPatch.patient_profile = {
      ...recipientPatch.patient_profile,
      ...contactInfo.extractPatch().patient_profile,
    };
    // Extract and add virology if exists
    if (!virology.isEmpty) {
      recipientPatch.virology_labs = [virology.extractPatch()];
    }
    // Extract and add measurement if exists
    if (!generalClinical.isEmpty) {
      recipientPatch.measurements = [generalClinical.extractMeasurementPatch()];
    }
    // Clear any save toolbar messages
    demographics.resetSaveToolbar();
    contactInfo.resetSaveToolbar();
    generalClinical.resetSaveToolbar();
    death.resetSaveToolbar();

    // Clear previous errors
    (this.$refs.validations as any).reset();

    // Attempt to save the recipient
    this.$store.dispatch('recipients/saveRecipientPatch', recipientPatch).then((success: SaveResult) => {
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      this.registerSaveResult(error);
    });
  }

  // Handle result of save
  public registerSaveResult(result: SaveResult): void {
    /**
     * If successful, update the root record(s). Depending on what the page is intended to save, this could be one
     * record (e.g. EditRecipient updates the currently selected recipient), zero records, or multiple records.
     */
    if (result.success) {
      this.$store.commit('recipients/set', result.responseData.recipient);
    } else {
      // Handle errors
      this.handleErrors(result,true);
    }
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    // Show appropriate saving notification
    saveToolbar.stopSaving(result);
  }

  // Handle save events generated by descendent components
  private handleSectionSave(sectionSaved: string): void {
    this.resetValidationErrors();
    this.resetSaveToolbar();
  }

  private resetValidationErrors(): void {
    (this.$refs.validations as any).reset();
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult, mainSave?: boolean): void {
    mainSave = mainSave ? true : false;

    const idLookup: {[key: string]: string} = {
      ...(this.$refs.demographics as Demographics).idLookup,
      ...(this.$refs.contactInfo as ContactInformation).idLookup,
      ...(this.$refs.generalClinical as GeneralClinicalInformation).idLookup,
      ...(this.$refs.virology as VirologyResults).idLookup(),
      ...(this.$refs.organReferrals as OrganReferral).idLookup(),
      ...(this.$refs.death as RecipientDeath).idLookup
    };
    let aggregateErrors: {[key: string]: []} = {};
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((item) => {
      aggregateErrors = { ...item.validationErrors };
    });
    const formErrors: {[key: string]: []} = {};
    for (const key of Object.keys(aggregateErrors)) {
      formErrors[idLookup[key]] = aggregateErrors[key];
    }
    (this.$refs.validations as any).setErrors(formErrors);

    if(mainSave) {
      errors.forEach((err: SaveResult) => {
        (this.$refs.potentialDuplicateModal as PotentialDuplicateModal).checkingDuplicateWarning(err, 'savePatch');
      });
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveRecipient as SaveToolbar;
    saveToolbar.reset();
  }
}
