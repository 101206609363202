import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { ObjectId } from '@/store/types';

export interface FeaturesState {
  features?: FeatureDetails;
}

export interface FeatureDetails {
  allocation_service?: string[],
  api?: ApiFeatures[];
}

// deprecated ?
export enum ApiFeatures {
  ctr_ipos_heart = "ctr_ipos_heart",
  enable_user_select = "enable_user_select",
  oneid = "oneid",
  bypass_fusionauth = "bypass_fusionauth"
}

// Configure VXM display feature for 2022-016 Change Request
export const VXM_VERBOSE_DISPLAY = true;

// Configure whether or not to show Donor Data History section
// NOTE: hidden for go-live 'release 0', to be shown in 'release 1' (see B#15296)
export const DONOR_DATA_HISTORY_ENABLED = false;
