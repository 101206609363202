
import { parsePhoneUi } from '@/utils';
import { Getter, State } from 'vuex-class';
import { CountryValue } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import CountryInput from '@/components/shared/CountryInput.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient, RecipientAddress, RecipientValidations } from '@/store/recipients/types';

// Declare all v-model fields used in the form
interface ContactInformationForm {
  permanent?: FormAddress;
  ontario?: FormAddress;
  contactDetails?: {
    mobilePhone?: string;
    homePhone?: string;
    pager?: string;
    email?: string;
  };
  internal: {
    urgent?: boolean;
  };
}
interface DemographicsForm {
  internal: {
    urgent?: boolean;
  };
}
interface FormAddress {
  _id?: { $oid: string };
  countryDropdown?: string;
  countryOther?: string;
  province?: string|null;
  state?: string|null;
  street?: string;
  city?: string;
  postalCode?: string;
}

const PROVINCE_ON = 'ON';
const ADDRESS_PERMANENT = 'permanent';
const ADDRESS_LOCAL = 'local';

@Component({
  components: {
    TextInput,
    SubSection,
    CardSection,
    SaveToolbar,
    SelectInput,
    CountryInput,
  }
})
export default class ContactInformation extends Vue implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.contactInfo) editState!: ContactInformationForm;
  @State(state => state.pageState.currentPage.demographics) editState_demographics!: DemographicsForm;
  @State(state => state.recipients.selectedRecipient.validations) validations!: RecipientValidations;

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = ['country'];

  // Getters
  @Getter('show', { namespace: 'recipients' }) private recipient: any;
  @Getter('country', { namespace: 'lookups' }) private countryLookup: any;
  @Getter('us_state', { namespace: 'lookups' }) private usStateLookup: any;
  @Getter('province', { namespace: 'lookups' }) private provinceLookup: any;
  @Getter('currentPage', { namespace: 'pageState' }) private currentPage!: any;
  @Getter('ontarioAddress', { namespace: 'recipients' }) private ontario?: RecipientAddress;
  @Getter('permanentAddress', { namespace: 'recipients' }) private permanent?: RecipientAddress;
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;
  @Getter('getTelephoneMask', { namespace: 'utilities' }) getTelephoneMask!: string;


  // Initialize the form
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'contactInfo',
      value: this.buildContactInformationForm(this.recipient)
    });
  }

  // Triggered when all the lookups have been loaded
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'contactInfo');
  }

  get getPostalLabelText(): string {
    const countryCode = this.editState.permanent ? this.editState.permanent.countryDropdown : null;
    if (countryCode == CountryValue.USA) {
      return "Zip Code";
    }
    return 'Postal Code';
  }


  // Translate from relevant parts of the recipient data structure to the form layout
  public buildContactInformationForm(recipient: Recipient): ContactInformationForm {
    // Ontario address values MUST be 'CAN' and 'ON' or the API will reject it
    const result: ContactInformationForm = {
      permanent: this.buildAddressForm(this.permanent, ADDRESS_PERMANENT),
      ontario: this.buildAddressForm(this.ontario, ADDRESS_LOCAL),
      contactDetails: {
        mobilePhone: parsePhoneUi(recipient.patient_profile!.contact_details!.phone_mobile),
        homePhone: parsePhoneUi(recipient.patient_profile!.contact_details!.phone_primary),
        pager: parsePhoneUi(recipient.patient_profile!.contact_details!.phone_pager),
        email: recipient.patient_profile!.contact_details!.email
      },
      internal: {
        urgent: recipient.urgent
      }
    };
    return result;
  }

  // Clear state and province on country change
  public clearProvince() {
    if (this.editState.permanent) {
      this.editState.permanent.state = null;
      this.editState.permanent.province = null;
    }
  }

  // Translate Recipient address data to our form structure
  public buildAddressForm(address: RecipientAddress|undefined, addressType: string): FormAddress|undefined {
    // Initialize empty FormAddress
    const sanitizedAddress: FormAddress = {
      countryDropdown: this.defaultLookup('country'),
      province: this.defaultLookup('province'),
    };
    // Copy values from the address into our sanitizedAddress if they have one
    if (address) {
      sanitizedAddress._id = address._id;
      sanitizedAddress.countryDropdown = address.country_code || sanitizedAddress.countryDropdown;
      sanitizedAddress.countryOther = address.country_other || undefined;
      sanitizedAddress.postalCode = address.postal_code;
      sanitizedAddress.state = address.province_code || sanitizedAddress.province;
      sanitizedAddress.province = address.province_code || undefined;
      sanitizedAddress.street = address.street;
      sanitizedAddress.city = address.city;
    }
    // Special case for ontario, the address should default to CAN/ON,
    if (addressType === ADDRESS_LOCAL) {
      if (!sanitizedAddress.countryDropdown) {
        sanitizedAddress.countryDropdown = CountryValue.Canada;
      }
      if (!sanitizedAddress.province) {
        sanitizedAddress.province = PROVINCE_ON;
      }
    }
    return sanitizedAddress;
  }

  // Translate form data into the Recipient data structure
  public extractAddressPatch(address?: FormAddress, addressType?: string): RecipientAddress|undefined {
    if (!address || !addressType) {
      return undefined;
    }
    const countryCode = address.countryDropdown;
    let provinceCode: string|null = null;
    let countryOther: string|null = null;
    if (countryCode == CountryValue.Canada) {
      provinceCode = address.province || null;
      countryOther = null;
    } else if (countryCode == CountryValue.USA) {
      provinceCode = address.state || null;
      countryOther = null;
    } else if (countryCode == CountryValue.Other) {
      provinceCode = null;
      countryOther = address.countryOther || null;
    }
    return {
      _id: address._id,
      type: addressType,
      city: address.city,
      street: address.street,
      province_code: provinceCode,
      country_code: address.countryDropdown,
      country_other: countryOther,
      postal_code: address.postalCode,
    };
  }

  // Translate the form structure into the Recipient data structure
  public extractPatch(potential_duplicate_profile_confirmed?: boolean): Recipient {
    const updatedProfile = {
      addresses: [] as RecipientAddress[],
      contact_details: {
        email: this.editState.contactDetails!.email,
        phone_alternate: null,
        phone_mobile: this.editState.contactDetails!.mobilePhone,
        phone_pager: this.editState.contactDetails!.pager,
        phone_primary: this.editState.contactDetails!.homePhone
      }
    };
    const ontarioAddress = this.extractAddressPatch(this.editState.ontario, ADDRESS_LOCAL);
    const permanentAddress = this.extractAddressPatch(this.editState.permanent, ADDRESS_PERMANENT);
    if (ontarioAddress) {
      // Only include the local address if we have some information
      if (ontarioAddress.street || ontarioAddress.city || ontarioAddress.postal_code) {
        updatedProfile.addresses.push(ontarioAddress);
      }
    }
    if (permanentAddress) {
      updatedProfile.addresses.push(permanentAddress);
    }
    return {
      _id: this.recipient._id,
      patient_profile: updatedProfile,
      potential_duplicate_profile_confirmed: potential_duplicate_profile_confirmed || false
    };
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveContactInfo as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // Handle saving triggered by local save button
  public savePatch(potential_duplicate_profile_confirmed?: boolean): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveContactInfo as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('save', 'contactInfo');
    // Generate payload based on current edit state
    const recipientPatch = this.extractPatch(potential_duplicate_profile_confirmed);
    // Dispatch save action and register the response
    this.$store.dispatch('recipients/saveRecipientPatch', recipientPatch).then((success: SaveResult) => {
      // If successful, update the current recipient and show success notification
      this.$store.commit('recipients/set', success.responseData.recipient);
      saveProvider.registerSaveResult(success);
      this.initializeForm();
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      this.$emit('handleWarnings', error, 'saveContactInfo');
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  public idLookup: {[key: string]: string} = {
    'patient_profile.addresses[permanent].country_code' : 'contactinfo-permanent-country',
    'patient_profile.addresses[permanent].country_other': 'contactinfo-permanent-othercountry',
    'patient_profile.addresses[permanent].province_code': 'province-state',
    'patient_profile.addresses[permanent].street'       : 'contactinfo-permanent-street',
    'patient_profile.addresses[permanent].city'         : 'contactinfo-permanent-city',
    'patient_profile.addresses[permanent].postal_code'  : 'contactinfo-permanent-postalcode',
    'patient_profile.addresses[local].street'           : 'contactinfo-ontario-street',
    'patient_profile.addresses[local].city'             : 'contactinfo-ontario-city',
    'patient_profile.addresses[local].postal_code'      : 'contactinfo-ontario-postalcode',
    'patient_profile.contact_details.phone_primary'     : 'contactinfo-details-homephone',
    'patient_profile.contact_details.phone_mobile'      : 'contactinfo-details-mobilephone',
    'patient_profile.contact_details.phone_pager'       : 'contactinfo-details-pager',
    'patient_profile.contact_details.email'             : 'contactinfo-details-email',
  }
}
