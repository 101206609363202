import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { FeatureDetails, FeaturesState, ApiFeatures } from '@/store/features/types';

export const getters: GetterTree<FeaturesState, RootState> = {
  show(state): FeatureDetails|undefined {
    return state.features || {};
  },
  ctrIposHeart(state): boolean {
    const allocation_service = state.features?.allocation_service || [];
    return allocation_service.includes("ctr_ipos_heart");
  },
  showUserSelect(state): boolean {
    const api = state.features && state.features.api ? state.features.api : [];
    return api.includes(ApiFeatures.enable_user_select);
  },
  oneid(state): boolean {
    const api = state.features && state.features.api ? state.features.api : [];
    return api.includes(ApiFeatures.oneid);
  },
  bypassFusionAuth(state): boolean {
    const api = state.features && state.features.api ? state.features.api : [];
    return api.includes(ApiFeatures.bypass_fusionauth);
  },
};
