
import { VXM_VERBOSE_DISPLAY } from "@/store/features/types";
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class HlaVirtualCrossmatchResult extends Vue {
  @Prop({ default: null }) result!: string|null;

  // Use VXM display features specified by 2022-016 Change Request?
  get isVerbose(): boolean {
    return VXM_VERBOSE_DISPLAY || false;
  }
}
