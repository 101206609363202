import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { DeathRecipient, RecipientSignificantEvent } from '@/store/recipients/types';
import { KidneyDetails, LiverDetails, LungDetails, HeartDetails, SmallBowelDetails, PancreasWholeDetails, PancreasIsletsDetails, VcaDetails, PancreasIsletsInfusion, AlcoholicLiverDiseaseProgram } from '@/store/organSpecificDetails/types';
import { ObjectId } from '@/store/types';
import { WaitDaysAdjustmentReasons, OrganCodeValue } from '@/store/lookups/types';
import { DonorAcceptabilityCriteria } from '../hospitals/types';

export interface JourneyState {
  selectedJourney?: RecipientJourney;
  selectedReferralDecision?: ReferralDecision;
  selectedConsultationDecision?: ConsultationDecision;
  selectedAssessmentDecision?: AssessmentDecision;
  journeyDurations?: JourneyDurations;
  waitlistDecisions?: WaitlistDecision[];
  selectedWaitlistDecision?: WaitlistDecision;
  postTransplantFollowUps?: PostTransplantFollowUp[];
  selectedPostTransplantFollowUp?: PostTransplantFollowUp;
  selectedAldProgram?: AlcoholicLiverDiseaseProgram;
  waitTimeOverrideEvents?: WaitDaysAdjustmentEvent[];
  selectedWaitTimeOverrideEvent?: WaitDaysAdjustmentEvent;
  transplantDetails?: RecipientTransplantAttributes;
  validationErrors?: {[key: string]: string};
}

export interface RecipientJourney {
  _id?: ObjectId;
  organ?: string;
  urgent?: boolean;
  organ_code?: number;
  organ_specific_details?: LiverDetails|SmallBowelDetails|LungDetails|HeartDetails|KidneyDetails|PancreasWholeDetails|PancreasIsletsDetails|VcaDetails;
  failed_organ?: RecipientFailedOrgan;
  donor_acceptability?: RecipientDonorAcceptability;
  recipient_programs?: RecipientPrograms;
  transplant_program?: RecipientTransplantProgram;
  related_journeys_code?: string;
  related_journeys_organ?: string;
  related_journeys?: string[]|ObjectId[];
  donor_acceptability_from_journey_id?: ObjectId;
  completed?: boolean;
  start_date?: string;
  completed_date?: string;
  completed_reason_code?: string;
  completed_reason?: string;
  waitlist_removal_reason_code?: number;
  waitlist_removal_reason?: string;
  waitlist_removal_comment?: string;
  stage?: string;
  state?: string;
  stoplight?: string;
  stage_attributes?: RecipientStageAttributes;
  pediatric?: boolean; // workaround: assume API will send this information as a value on journey
  created_at?: string;
  oop_info?: JourneyOopInfo;
  oop_journey?: boolean;
  mrn?: string|null;
  medical_status?: string;
  program_abbreviation?: string|null;
  registration_date?: string|null;
  referral_date?: string|null;
  consultation_state?: string;
  transfer_statuses?: RecipientJourneyTransferStatus[];
  comments?: string;
  // Non-model field used to indirectly change stage_attributes.referral.referral_number
  referred_with_referral_number?: number|null;
  ui_cluster_id?: number|null;
}

export interface RecipientFailedOrgan {
  cause_of_failure_code?: number;
  cause_of_failure?: string;
  failure_date?: string;
  primary_diagnosis_code?: number|null;
  primary_diagnosis?: string;
  primary_diagnosis_date?: string|null;
  secondary_diagnosis_code?: number|null;
  secondary_diagnosis?: string;
  secondary_diagnosis_date?: string|null;
  former_cause_of_failure_code?: number;
  former_cause_of_failure?: string;
  comments?: string;
}

export interface RecipientDonorAcceptability {
  a2_or_a2b_donor?: boolean|null;
  abo_incompatible?: boolean|null;
  ecd_donor?: boolean|null;
  hcv_ab_positive?: boolean|null;
  hcv_nat_positive?: boolean|null;
  hep_ab_core_positive?: boolean|null;
  min_weight?: number;
  max_weight?: number;
  min_height?: number;
  max_height?: number;
  min_age?: number;
  max_age?: number;
  comments?: string;
  deceased_donor?: boolean|null;
  living_donor?: boolean|null;
  display_in_ui?: DonorAcceptabilityCriteria;
}

export interface RecipientPrograms {
  kidney_paired_donation?: boolean;
  national_organ_req_id?: number;
  hsp?: boolean;
  hsx_eligible?: boolean;
  list_exchange?: boolean;
  list_exchange_date?: string|null;
  previous_living_donor?: boolean;
  previous_living_donation_date?: string|null;
  financial_assistance?: RecipientFinancialAssistance;
}

export interface RecipientStageAttributes {
  referral?: RecipientReferralAttributes;
  assessment?: RecipientAssessmentAttributes;
  waitlist?: RecipientWaitlistAttributes;
  transplant?: RecipientTransplantAttributes;
  post_transplant?: RecipientPostTransplantAttributes;
}

export interface RecipientFinancialAssistance {
  program_type?: string;
  status?: string;
  date?: string;
  comments?: string;
}

export interface RecipientReferralAttributes {
  state?: string;
  factors?: RecipientReferralFactors;
  coordinator_id?: number;
  hospital_id?: number;
  inpatient?: string;
  mrn?: string|null;
  received_date?: string|null;
  expiry_date?: string;
  on_dialysis?: string;
  dialysis_not_applicable?: string;
  alcoholic_liver_disease_type_code?: string;
  alcoholic_liver_disease_type?: string;
  referrer?: RecipientReferrer;
  comments?: string;
  migration_comments?: string;
  referral_number?: number;
}

export interface RecipientReferralFactors {
  decision_code?: number|null;
  decision_date?: string|null;
  decision_coordinator_id?: ObjectId;
  decision_reason_code?: number|null;
  decision_comments?: string|null;
  // Unused: decision, decision_reason, decision_other_reason
}

export interface RecipientReferrer {
  professional?: RecipientReferrerProfessional;
  organization?: RecipientReferrerOrganization;
}

export interface RecipientReferrerProfessional {
  type_code?: number;
  type?: string;
  first_name?: string;
  last_name?: string;
}

export interface RecipientReferrerOrganization {
  type_code?: number;
  type?: string;
  name?: string;
  street?: string;
  city?: string;
  province?: string|null;
  country?: string|null;
  other_country?: string|null;
  postal_code?: string;
}

export interface RecipientAssessmentAttributes {
  state?: string;
  factors?: RecipientAssessmentFactors;
  alcoholic_liver_disease_type_code?: number;
  alcoholic_liver_disease_type?: string;
  migration_comments?: string;
  consultation_state?: string;
}

export interface RecipientAssessmentFactors {
  consultation_decision_code?: number|null;
  consultation_decision_date?: string|null;
  consultation_decision_delay_end_date?: string|null;
  consultation_decision_delay_start_date?: string|null;
  consultation_decision_coordinator_id?: string|null;
  consultation_decision_primary_physician_id?: string|null;
  consultation_decision_reason_code?: number|null;
  consultation_decision_comments?: string;
  assessment_decision_code?: number|null;
  assessment_decision_date?: string|null;
  assessment_decision_coordinator_id?: string|null;
  assessment_decision_reason_code?: number|null;
  assessment_decision_reason_other?: string|null;
  assessment_absolute_contraindication_code?: string[]|null;
  assessment_relative_contraindication_code?: string[]|null;
  assessment_decision_comments?: string;
}

export interface RecipientWaitlistAttributes {
  _id?: ObjectId;
  waitlist_removal_comment?: string;
  waitlist_removal_date?: string|null;
  waitlist_removal_reason_code?: number|null;
  waitlist_removal_reason?: string|null;
  waitlist_removal_reason_other?: string|null;
  factors?: RecipientWaitlistFactors;
  state?: string;
}

export interface RecipientTransplantAttributes {
  // transplant attributes model properties loaded from database
  donor_id?: ObjectId|null;
  donor_client_id?: number;
  factors?: TransplantFactors;
  // read-only properties generated dynamically by web app API
  deceased_donor_id?: string;
  living_donor_id?: string;
  allocated_donor_id?: string;
  allocated_donor_client_id?: string;
  transplant_details_applicable?: boolean;
  // flags UI can send to API in transplant update request params
  copy_to_cluster?: boolean;
}

export interface TransplantFactors {
  transplant_date?: string|null;
}
export interface HeartTransplantAttributes extends RecipientTransplantAttributes {
  transplant_start_date?: string|null;
  end_cold_storage_date?: string|null;
  x_clamp_removal_date?: string|null;
  cold_isch_time?: number;
  rewarm_time?: number;
  total_isch_time?: number;
}
export interface LungTransplantAttributes extends RecipientTransplantAttributes {
  organ_specification_code?: number;
  transplant_start_date?: string|null;
  end_cold_storage_date_left?: string|null;
  end_cold_storage_date_right?: string|null;
  clamp_removal_date_left?: string|null;
  clamp_removal_date_right?: string|null;
  rewarm_time_left?: number;
  rewarm_time_right?: number;
  cold_isch_time_left?: number;
  cold_isch_time_right?: number;
  total_isch_time_left?: number;
  total_isch_time_right?: number;
}
export interface LiverTransplantAttributes extends RecipientTransplantAttributes {
  organ_specification_code?: number;
  transplant_start_date?: string|null;
  end_cold_storage_date?: string|null;
  pv_clamp_off_date?: string|null;
  ha_clamp_off_date?: string|null;
  portal_vein_cross_clamp_date?: string|null;
  removed_from_normothermic_perf_pump_date?: string|null;
  vessels_used?: boolean;
  list_of_vessels_used?: string;
  rewarm_time?: number;
  cold_isch_time?: number;
}
export interface KidneyTransplantAttributes extends RecipientTransplantAttributes {
  organ_specification_code?: number;
  transplant_start_date?: string|null;
  end_cold_storage_date_left_kid?: string|null;
  end_cold_storage_date_right_kid?: string|null;
  clamp_removal_date_left_kid?: string|null;
  clamp_removal_date_right_kid?: string|null;
  vessels_used?: boolean;
  list_of_vessels_used?: string;
  rewarm_time_left_kid?: number;
  rewarm_time_right_kid?: number;
  cold_isch_time_left_kid?: number;
  cold_isch_time_right_kid?: number;
}
export interface PancreasTransplantAttributes extends RecipientTransplantAttributes {
  transplant_start_date?: string|null;
  end_cold_storage_date?: string|null;
  vascular_clamp_release_date?: string|null;
  clamp_removal_date?: string|null;
  vessels_used?: boolean;
  list_of_vessels_used?: string;
  rewarm_time?: number;
  cold_isch_time?: number;
}

export interface PancreasIsletsTransplantAttributes extends RecipientTransplantAttributes {}

export interface SmallBowelTransplantAttributes extends RecipientTransplantAttributes {
  transplant_start_date?: string|null;
  end_cold_storage_date?: string|null;
  portal_vein_cross_clamp_date?: string|null;
  portal_vein_clamp_removal_date?: string|null;
  hepatic_artery_clamp_removal_date?: string|null;
  rewarm_time?: number;
  cold_isch_time?: number;
}

export interface VcaTransplantAttributes extends RecipientTransplantAttributes {
  cold_isch_time?: number|null;
}

export interface RecipientPostTransplantAttributes {
  follow_ups: PostTransplantFollowUp;
}

export interface AllocatedDonorDetails {
  code: string;
  value: string;
  donor_client_id: string;
}

export interface PostTransplantFollowUp {
  _id?: ObjectId;
  date_of_entry?: string|null;
  recipient_status_code?: number;
  source_of_followup?: number;
  source_of_followup_other?: string;
  follow_up_date?: string|null;
  comments?: string;
  graft_failure_date?: string|null;
  graft_failure_cause?: number;
  graft_failure_cause_other?: string;
  lost_to_followup_reason?: string;
  graft_functioning_at_death?: string;
}

export interface KidneyPostTransplantFollowUp extends PostTransplantFollowUp {
  serum_creatinine?: number;
  date_serum_creatinine?: string|null;
  serum_creatinine_unknown?: boolean;
  returned_to_chronic_dialysis?: string;
  date_returned_to_chronic_dialysis: string|null;
}

export interface RecipientWaitlistFactors {
  _id?: ObjectId;
  added_date?: string;
  wait_days_adjustment?: number|null;
  wait_days_adjustment_date?: string|null;
  wait_days_adjustment_comment?: string;
  wait_days_adjustment_reason_code?: number|null;
  wait_days_adjustment_other_reason?: string|null;
  listing_date?: string|null;
  dialysis_start_date?: string|null;
  medical_status_code?: string|null;
  medical_status_date?: string|null;
  medical_status_secondary_code?: string|null;
  on_hold_medical?: boolean;
  on_hold_medical_date?: string|null;
  on_hold_medical_reason_code?: number|null;
  on_hold_medical_reason?: string|null;
  on_hold_medical_other_reason?: string|null;
  on_hold_medical_reason_comment?: string|null;
  on_hold_serum_hla_antibody?: boolean;
  on_hold_serum_hla_antibody_date?: string;
  on_hold_medical_change_type?: string|null;
  suspended_liver_hcc?: boolean;
  suspended_liver_hcc_date?: string;
  suspended_liver_sodium_meld?: boolean;
  suspended_liver_sodium_meld_date?: string;
  suspended_medical?: boolean;
  suspended_medical_date?: string;
  suspended_serum_hla_antibody?: boolean;
  suspended_serum_hla_antibody_date?: string;
  on_hold_initial_waitlisted?: boolean;
  on_hold_medical_expiry_date?: string|null;
  on_hold_medical_extension_date?: string|null;
  suspended_heart?: boolean;
  suspended_heart_date?: string|null;
  on_hold_incomplete_data?: boolean;
  transplant_in_progress?: any;
  transplant_in_progress_date?: string|null;
  living_donor_transplant_in_progress?: ObjectId|null;
  living_donor_transplant_in_progress_date?: string|null;
  on_hold_incomplete_cluster?: boolean;
  on_hold_incomplete_cluster_date?: string;
  on_hold_partial_cluster_transplant?: boolean;
  on_hold_partial_cluster_transplant_date?: string;
  on_hold_cluster?: boolean;
  on_hold_cluster_date?: string;
  suspended_cluster?: boolean;
  suspended_cluster_date?: string;
  liver_hcc_in_criteria_date?: string|null;
}

export interface RecipientTransplantProgram {
  transplant_hospital_id?: { $oid?: string } | null;
  transplant_coordinator_id?: { $oid?: string } | null;
  transplant_hospital_mrn?: string|null;
  transplant_hospital_abbreviation?: string|null;
}

export interface RecipientJourneyTransferStatus {
  _id?: ObjectId;
  type?: string;
  status?: string;
  status_changed_date?: string
  comments?: string;
  direction?: string;
  other_program_journey_id?: ObjectId;
  hospital_id?: ObjectId;
  hospital_abbreviation?: string;
  hospital_short_name?: string;
  program_identifier?: string;
  hospital_mrn?: string;
  oop_hospital?: boolean;
  cooordinator_id?: ObjectId;
}

export enum TransferDirection {
  To = 'to',
  From = 'from',
}

export enum TransferType {
  Active = 'active',
  Cluster = 'cluster',
  VAD = 'vad',
  PostTransplant = 'post-transplant',
}

export const TRANSFER_TYPES_CONSIDERED_ACTIVE_TRANSFER = [
  TransferType.Active,
  TransferType.Cluster,
];

export interface JourneyOopInfo {
  oop_hospital?: string|null;
  oop_hospital_patient_id?: number|null;
  oop_province?: string|null;
  oop_country?: string|null;
  oop_country_other?: string|null;
  oop_organ_other?: string;
  oop_multi_organ?: string;
}

export interface PostTransplantTransferPayload {
  hospital_id: string|null;
  coordinator_id: string|null;
}

export interface JourneyOption {
  code: string;
  value: string;
  journey: RecipientJourney;
}

export interface ReferredWithOption {
  code: number;
  value: string;
  journeys: RecipientJourney[];
}

export const journeyState: JourneyState = {
  selectedJourney: undefined,
};

export interface ReferralDecision {
  _id: ObjectId;
  referralDecisionDate?: string;
  recipientCoordinator?: string;
  referralDecisionCode?: number;
  referralDecisionReasonCode?: number;
  referralComments: string;
  days?: number;
}

export interface AssessmentDecision {
  _id: ObjectId;
  assessmentDate?: string|null;
  recipientCoordinator?: string;
  assessmentDecisionCode?: number;
  assessmentDecisionReasonCode?: number;
  reasonFurtherTreatmentOther?: string;
  // Fix interface when we have multi-select input
  absoluteContraindicationCode?: any[];
  relativeContraindicationCode?: any[];
  assessmentComments?: string;
  days?: number;
}

export interface ConsultationDecision {
  _id: ObjectId;
  consultationDate?: string|null;
  recipientCoordinator?: string;
  consultationDecisionCode?: number;
  consultationDecisionReasonCode?: number;
  consultationDelayEndDate?: string;
  consultationDelayStartDate?: string;
  primaryPhysician?: string;
  consultationComments?: string;
  days?: number;
}

export interface WaitlistDecision extends RecipientSignificantEvent {
  journey_id: ObjectId;
  stage: string;
  stage_factor_code: number;
  to: any;
  created_by: string;
  updated_at: string;
  created_at: string;
  details: WaitlistDecisionDetails;
  changes?: WaitlistDecisionChange[];
  end_date?: string|null;
  days?: number;
}

export interface WaitlistFactorEvent extends WaitlistDecision {
  extra?: string|null;
}

export interface WaitlistClusterEvent extends WaitlistDecision {
  cluster_code: number;
  extra?: string[]|null;
}

export interface WaitDaysAdjustmentEvent extends RecipientSignificantEvent {
  journey_id: ObjectId;
  stage: string;
  stage_factor_code: number;
  to: number;
  details: WaitDaysAdjustmentEventDetails;
}

export interface WaitDaysAdjustmentEventDetails {
  reason_code: number;
  other_reason: string;
  reason_comment: string;
}

export interface WaitlistDecisionDetails {
  _id: ObjectId;
  expiry_date: string|null;
  extension_date: string|null;
  reason_code: number|null;
  reason: string|null;
  other_reason: string|null;
  assessment_date: string|null;
  reason_comment: string|null;
}

export interface WaitlistDecisionChange extends RecipientSignificantEvent {
  journey_id: ObjectId;
  stage: string;
  stage_factor_code: number;
  to: any;
  created_by: string;
  updated_at: string;
  created_at: string;
  details: WaitlistDecisionDetails;
  decision?: string;
  end_date?: string|null;
  days?: number;
  change_type?: string;
}

export interface JourneyEvent extends RecipientSignificantEvent {
  journey_id: ObjectId;
  completed_reason: string|null; // Other
  completed_reason_code: number;
  to: string;
}

export enum JourneyEventTo {
  Completed = 'completed',
}

export interface SignificantEventWaitlistRemoval extends RecipientSignificantEvent {
  journey_id: ObjectId;
  reason_code: number;
  reason_other: string|null;
}

export interface JourneyDurations {
  referral: {
    total_days: number|null;
    delay_days: number|null;
  };
  assessment: {
    consultation_days: number|null;
    consultation_delay_days: number|null;
    assessment_days: number|null;
    assessment_delay_days: number|null;
  };
  waitlist: {
    wait_days: number|null;
    hold_days: number|null;
    suspension_days: number|null;
    wait_days_date: string|null;
    ctr_wait_days: number|null,
    ctr_wait_days_date: string|null;
  };
}

export class JourneyStatusConfig {
  stage?: string;
  state?: string;
  completed?: boolean;
  status?: string;
  statusDisplayValue?: string|string[];
  stageDisplayValue?: string;
}

export enum JourneyStage {
  Referral = 'referral',
  Assessment = 'assessment',
  Consultation = 'consultation',
  Waitlist = 'waitlist',
  Transplant = 'transplant',
  PostTransplant = 'post-transplant',
}


export enum JourneyStatus {
  Received = 'received',
  Incomplete = 'incomplete',
  Deferred = 'deferred',
  Delayed = 'Delayed',
  TreatmentRequired = 'Treatment required',
  ToBeListed = 'To be listed',
  TransplantInProgress = 'Transplant in progress',
  Transplanted = 'Transplanted',
  MedicalHold = 'Medical Hold',
  SerumHold = 'Serum Hold',
  MedicalSuspension = 'Medical Suspension',
  NaMELDSuspension = 'NaMELD Suspension',
  HCCSuspension = 'HCC Suspension',
  HeartSuspension = 'Heart Suspension',
  ClusterHold = 'Cluster Hold',
  ClusterSuspension = 'Cluster Suspension',
  IncompleteClusterHold = 'Incomplete Cluster Hold',
  PartialClusterTransplantHold = 'Partial Cluster Transplant Hold',
  Cancelled = 'Cancelled',
  Superceded = 'superceded',
}

export enum AssessmentState {
  RecipientToBeListed = 'eligible',
  Consulting = 'consulting',
  Delayed = 'delayed',
  Completed = 'completed',
  TreatmentRequired = 'treatment_required',
  LivingDonor = 'living_donor',
  Cancelled = 'cancelled',
  Pending = 'pending'
}

export enum ReferralDecisionState {
  Referral_Accepted = 'accepted',
}

export enum WaitlistStatusValue {
  Active = 'Active',
  OnHoldInitialWaitlisted = 'On Hold: Initial Waitlisted',
  OnHoldIncompleteData = 'On Hold: Incomplete Data (Urgent)',
  OnHoldSerumHlaAntibody = 'On Hold: HLA Antibody Serum',
  OnHoldMedical = 'On Hold: Medical',
  SuspendedMedical = 'Suspended: Medical',
  SuspendedLiverSodumMeld = 'Suspended: Liver Sodium MELD',
  SuspendedLiverHcc = 'Suspended: Liver HCC',
  SuspendedHeart = 'Suspended Heart',
  OnHoldIncompleteCluster = 'On Hold: Incomplete Cluster',
  OnHoldCluster = 'On Hold: Cluster',
  SuspendedCluster = 'Suspended: Cluster',
  PartialClusterTransplantHold = 'On Hold: Partial Cluster Transplant',
}

export enum WaitlistActiveStatusValue {
  TransplantInProgress = 'Transplant in Progress',
  LivingDonorTransplantInProgress = 'Transplant in Progress',
}

export enum PostTransplantState {
  Superceded = 'Superceded',
}

export enum WaitlistInactiveStatusValue {
  OnHoldInitialWaitlisted = 'Medical Hold',
  OnHoldIncompleteData = 'Incomplete Data Hold',
  OnHoldSerumHlaAntibody = 'Serum Hold',
  OnHoldMedical = 'Medical Hold',
  SuspendedMedical = 'Medical Suspension',
  SuspendedLiverSodumMeld = 'NaMELD Suspension',
  SuspendedLiverHcc = 'HCC Suspension',
  SuspendedHeart = 'Heart Suspension',
  OnHoldIncompleteCluster = 'Incomplete Cluster Hold',
  OnHoldCluster = 'Cluster Hold',
  SuspendedCluster = 'Cluster Suspension',
  OnHoldPartialCluster = 'Partial Cluster Transplant Hold'
}

export enum VADIndicationValue {
  Destination_Therapy = 3
}

export enum AssessmentCancellationReasons {
  ConsultationCancelled = 201
}

// Highly-Sensitized Patient (HSP) program applies to Kidney
export const HSP_PROGRAM_INDICATOR_ORGANS = [
  OrganCodeValue.Kidney
];

// High Status Heart recipients (HSH) program applies to Heart
// NOTE: only applicable if IPOS heart feature is enabled
export const HSH_PROGRAM_INDICATOR_ORGANS = [
  OrganCodeValue.Heart
];

export enum IposProgram {
  HSP = 'hsp',
  HSH = 'hsh',
}
