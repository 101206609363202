
import { Getter, State } from 'vuex-class';
import { Recipient } from '@/store/recipients/types';
import TextInput from '@/components/shared/TextInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import CardSection from '@/components/shared/CardSection.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { HeartMeasurement, HeartDetails } from '@/store/organSpecificDetails/types';
import HeartMeasurements, { HeartMeasurementForm } from '@/components/organs/heart/HeartMeasurements.vue';
import HemodynamicInformation, { HemodynamicForm } from '@/components/organs/heart/HemodynamicInformation.vue';
import VadSpecificDetails, { VadSpecificForm } from '@/components/organs/heart/VadSpecificDetails.vue';

export interface HeartSpecificPageState {
  generalInfo: HeartSpecificDetailsForm;
  heartMeasurement: HeartMeasurementForm;
  hemodynamicInfo: HemodynamicForm;
  vadInfo: VadSpecificForm;
}

interface HeartSpecificDetailsForm {
  ecmo?: boolean;
  iabp?: boolean;
  impella?: boolean;
  other?: string;
  anticoagulantMedications?: string;
  congenitalHeartDisease?: boolean;
  surgicalComments?: string;
  medicalComments?: string;
  comments?: string;
}

@Component({
  components: {
    TextInput,
    SubSection,
    CardSection,
    TextAreaInput,
    CheckboxInput,
    HeartMeasurements,
    HemodynamicInformation,
    VadSpecificDetails
  }
})
export default class HeartSpecificDetails extends Vue implements SaveableSection {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.heartDetails) editState!: HeartSpecificPageState;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  public lookupsToLoad = ['inotrope_types','vad_type','vad_indication','vad_side','vad_device','vad_discontinued_reason'];

  public hospitalsToLoad: string[] = ['all'];

  /**
   * Return true if other heart details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Heart Specific Details card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @listens saveHeartSpecificDetails#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit('loaded', 'saveHeartSpecificDetails');
  }

  /**
   * Populates the Heart Specific Details form state with data from
   * the selected Journey.  Also triggers Heart Measurements and
   * Hemodynamic Information loading.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'heartDetails',
      value: {
        generalInfo: this.extractHeartSpecificDetails(this.journey),
        heartMeasurement: {},
        hemodynamicInfo: {},
        vadInfo:{},
      }
    });
    // Begin fetching the recipient's Heart Measurements
    this.$store.dispatch('organSpecificDetails/loadHeartMeasurements', this.recipientId);
    // Begin fetching the recipient's Hemodynamic Information
    this.$store.dispatch('organSpecificDetails/loadHemodynamicInfo', this.recipientId);
    //Begin fetching the recipient's Vad Information
    this.$store.dispatch('organSpecificDetails/loadVadInfo', this.recipientId);
  }

  /**
   * Generates Heart Specific Details form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {HeartSpecificDetailsForm} Heart Specific Details form state
   */
  public extractHeartSpecificDetails(journey: RecipientJourney): HeartSpecificDetailsForm {
    if (!journey) {
      return {};
    }
    const organSpecificDetails = journey.organ_specific_details as HeartDetails;
    return {
      ecmo: (organSpecificDetails.extra_corporeal_membraneous_oxygenator === 'Y' ? true : false),
      iabp: (organSpecificDetails.intra_aortal_balloon_pump === 'Y' ? true : false),
      impella: (organSpecificDetails.impella_heart_pump === 'Y' ? true : false),
      other: organSpecificDetails.mechanical_circulatory_support_other,
      anticoagulantMedications: organSpecificDetails.anticoagulant_medications,
      congenitalHeartDisease: organSpecificDetails.congenital_heart_disease,
      surgicalComments: organSpecificDetails.surgical_comments,
      medicalComments: organSpecificDetails.medical_comments,
      comments: journey.comments
    };
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Heart Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveHeartSpecificDetails as unknown as SaveProvider;
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveJourney', payload).then((success: SaveResult) => {
      // If successful, reload the current recipient
      this.$store.dispatch('recipients/get', this.recipientId);
      // Register success result
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Show error notification
      saveProvider.registerSaveResult(error);
      // Emit event to handle errors
      this.$emit('handleErrors', error);
    });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Heart Specific Details
   *
   * If the edit state doesn't exist return null
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.generalInfo) {
      return {};
    } else {
      return this.extractHeartSpecificDetailsPatch(this.editState.generalInfo);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = this.$refs.saveHeartSpecificDetails as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    // Mechanical Circulatory Support
    const result = {
      'extra_corporeal_membraneous_oxygenator': 'hsd-ecmo',
      'intra_aortal_balloon_pump'             : 'hsd-iabp',
      'impella_heart_pump'                    : 'hsd-impella',
      'mechanical_circulatory_support_other'  : 'hsd-other',
      'anticoagulant_medications'             : 'hsd-anticoagulant',
      'congenital_heart_disease'              : 'hsd-congenital',
      'surgical_comments'                     : 'hsd-surgical-comments',
      'medical_comments'                      : 'hsd-medical-comments',
      'comments'                              : 'hsd-comments'
    };
    
    // Heart Measurements
    const heartMeasurement = this.$refs.heartMeasurement as HeartMeasurements;
    if (heartMeasurement) {
      Object.assign(result, { ...heartMeasurement.idLookup });
    }
    
    // Hemodynamic Information
    const hemodynamicInformation = this.$refs.hemodynamicInformation as HemodynamicInformation;
    if (hemodynamicInformation) {
      Object.assign(result, { ...hemodynamicInformation.idLookup });
    }

    // Vad Information
    const vadInformation = this.$refs.VadSpecificDetails as VadSpecificDetails;
    if (vadInformation) {
      Object.assign(result, { ...vadInformation.idLookup() });
    }

    return result;
  }

  /**
   * PRIVATE
   */

  /**
   * Gets changes from the edit state as a patch for the Heart Specific Details
   *
   * @returns {RecipientJourney} patch object containing field changes
   */
  private extractHeartSpecificDetailsPatch(heartSpecificDetails: HeartSpecificDetailsForm): RecipientJourney {
    return {
      comments: heartSpecificDetails.comments,
      organ_specific_details: {
        extra_corporeal_membraneous_oxygenator: (heartSpecificDetails.ecmo ? 'Y' : 'N'),
        intra_aortal_balloon_pump: (heartSpecificDetails.iabp ? 'Y' : 'N'),
        impella_heart_pump: (heartSpecificDetails.impella ? 'Y' : 'N'),
        mechanical_circulatory_support_other: heartSpecificDetails.other,
        anticoagulant_medications: heartSpecificDetails.anticoagulantMedications,
        congenital_heart_disease: heartSpecificDetails.congenitalHeartDisease ? true : false,
        surgical_comments: heartSpecificDetails.surgicalComments,
        medical_comments: heartSpecificDetails.medicalComments,
      }
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any): void {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // Emit event to parent only for clear validations
  private clear() {
    this.$emit('clear');
  }

}
