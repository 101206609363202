import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { AdministrationState, CtrLogItem } from '@/store/administration/types';

export const getters: GetterTree<AdministrationState, RootState> = {
  applicationNamePrefix(state: AdministrationState): string {
    if (!state || !state.environment || !state.environment.application_name_prefix) return '';

    return state.environment.application_name_prefix.trim();
  },
  getHelpUrl(state: AdministrationState, getters): string|undefined {
    return state.environment ? state.environment.help_url : undefined;
  },
  getCtrCount(state: AdministrationState, getters): number {
    return state?.ctrIntegrationLog?.count || 0;
  },
  getCtrLog(state: AdministrationState, getters): CtrLogItem[] {
    return state?.ctrIntegrationLog?.entries || [];
  },
  isLanguageSelectEnabled(state: AdministrationState): boolean {
    if (!state || !state.environment) return false;

    return state.environment.language_select || false;
  },
};
