
import { Getter, State } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import TextInput from "@/components/shared/TextInput.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import CardSection from "@/components/shared/CardSection.vue";
import { RecipientJourney } from "@/store/recipientJourney/types";
import SelectInput from "@/components/shared/SelectInput.vue";
import SubSection from "@/components/shared/SubSection.vue";
import NumberInput from "@/components/shared/NumberInput.vue";
import { Recipient } from "@/store/recipients/types";
import { VcaDetails } from "@/store/organSpecificDetails/types";
import { SaveProvider, SaveResult } from "@/types";
import { VcaLimbTypes, VcaSkinPigmentations, VcaTypes } from '@/store/lookups/types';

export interface VcaSpecificPageState {
  generalInfo: VcaSpecificDetailsForm;
  comments: string;
}

interface VcaSpecificDetailsForm {
  type?: string;
  limbType?: string|null;
  graftSize?: number;
  skinPigmentation?: string;
}

@Component({
  components: {
    TextInput,
    TextAreaInput,
    CardSection,
    SelectInput,
    SubSection,
    NumberInput
  }
})
export default class VcaSpecificDetails extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.vcaDetails) editState!: VcaSpecificPageState;
  @State(state => state.lookups.vca_type) vcaTypes!: VcaTypes;
  @State(state => state.lookups.vca_limb_type) vcaLimbTypes!: VcaLimbTypes;
  @State(state => state.lookups.vca_skin_pigmentation) vcaSkinPigmentations!: VcaSkinPigmentations;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter("clientId", { namespace: "recipients" }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;

  public lookupsToLoad = ['vca_type','vca_limb_type','vca_skin_pigmentation'];

  /**
   * Return true if VCA specific details section can be edited
   * 
   * cannot be edited if new journey
   * cannot be edited if journey is completed
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * The Vca Details card section emits a loaded event when
   * it finishes loading lookup tables (if any).
   *
   * @listens saveVcaSpecificDetails#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.initializeForm();
    this.$emit("loaded", "saveVcaSpecificDetails");
  }

  /**
   * Populates the Vca Specific Details form state with data from
   */
  public initializeForm(): void {
    // Initialize form edit state
    this.$store.commit("pageState/set", {
      pageKey: "vcaDetails",
      value: {
        comments: (this.journey || {}).comments,
        generalInfo: this.extractVcaSpecificDetails(this.journey)
      }
    });
  }

  /**
   * Generates Vca Specific Details form state based on the selected journey
   *
   * @param journey current selected jounrey
   * @returns {VcaSpecificDetailsForm}Vca Specific Details form state
   */
  public extractVcaSpecificDetails(journey: RecipientJourney): VcaSpecificDetailsForm {
    if (!journey) {
      return {};
    }
    const organSpecificDetails = journey.organ_specific_details as VcaDetails;
    const result= {
      type: organSpecificDetails.vca_type_code,
      limbType: organSpecificDetails.limb_type_code,
      graftSize: organSpecificDetails.graft_size,
      skinPigmentation: organSpecificDetails.skin_pigmentation_code,
    };
    return result;
  }

  /**
   * Saves the form edit state.
   *
   * Prepares an update payload for Vca Specific Details,
   * dispatches a save action, and registers the save result.
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = (this.$refs
      .saveVcaSpecificDetails as unknown) as SaveProvider;
    // Report to parent that saving has began
    this.$emit("save", "saveVcaSpecificDetails");
    // Generate payload based on current edit state
    const payload = {
      recipientId: this.recipientId,
      journeyId: this.journeyId,
      journey: this.extractPatch()
    };
    // Dispatch save action and register the response
    this.$store.dispatch("journeyState/saveJourney", payload).then((success: SaveResult) => {
         // If successful, reload the current recipient
        this.$store.dispatch('recipients/get', this.recipientId);
        // If successful, update the current recipient and show success notification
        this.$store.commit("journeyState/setJourney",success.responseData.journey);
        saveProvider.registerSaveResult(success);
      })
      .catch((error: SaveResult) => {
        // Show error notification
        saveProvider.registerSaveResult(error);
        // Emit event to handle errors
        this.$emit("handleErrors", error);
      });
  }

  /**
   * Gets changes from the editState as a patch for the journey's Vca Specific Details
   *
   * If the edit state doesn't exist return {}
   *
   * @returns {any} object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.generalInfo) {
      return {};
    } else {
      return this.extractVcaSpecificDetailsPatch(this.editState.generalInfo);
    }
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const gci = (this.$refs.saveVcaSpecificDetails as unknown) as SaveProvider;
    // Reset the save provider's save toolbar
    gci.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): { [key: string]: string } {
    // General Vca Details
    return {
      "organ_specific_details.vca_type_code"          : "vca-type",
      "organ_specific_details.limb_type_code"         : "vca-limb-type",
      "organ_specific_details.graft_size"             : "vca-graft-size",
      "organ_specific_details.skin_pigmentation_code" : "vca-skin-pigmentation",
      "comments"                                      : "vca-comments"
    };
  }

  /**
   * PRIVATE
   */

  /**
   * Gets changes from the edit state as a patch for the Vca Specific Details
   *
   * @returns {RecipientJourney} patch object containing field changes
   */
  private extractVcaSpecificDetailsPatch(vcaSpecificDetails: VcaSpecificDetailsForm): RecipientJourney {
    return {
      comments: this.editState.comments,
      organ_specific_details: {
        vca_type_code: vcaSpecificDetails.type,
        limb_type_code: vcaSpecificDetails.limbType ? vcaSpecificDetails.limbType : null,
        graft_size: vcaSpecificDetails.graftSize,
        skin_pigmentation_code: vcaSpecificDetails.skinPigmentation
      }
    };
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit("handleErrors", errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit("saving", formReference);
  }
}
