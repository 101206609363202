
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import TimeInput from '@/components/shared/TimeInput.vue';
import TextAreaInput from '@/components/shared/TextAreaInput.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import BooleanRadioInput from '@/components/shared/BooleanRadioInput.vue';
import CheckboxInput from '@/components/shared/CheckboxInput.vue';
import NumberInput from '@/components/shared/NumberInput.vue';
import CardSection from '@/components/shared/CardSection.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Component, Prop, Vue } from "vue-property-decorator";
import { Hospital } from '@/store/hospitals/types';
import { ObjectId, GenericCodeValue } from '@/store/types';
import { LivingDonor, LivingDonorOrgan, LivingDonorJourney, LivingDonorIntendedRecipient } from '@/store/livingDonors/types';
import { Organ, OrganCodeValue, OrganSpecification, OrganOfferSpecificationCodeValue } from '@/store/lookups/types';
import { hospitals } from '@/store/hospitals';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import ModalSection from '@/components/shared/ModalSection.vue';

export interface RecoveryInformationPageState {
  referredOrgan?: number;
  referredOrganName?: string;
  organSpecification?: string;
  surgeon1?: string;
  surgeon2?: string;
  transplantProgram?: string;
  hospitalName?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  comments?: string;
  completed?: boolean;
  crossClampDate?: string;
  crossClampTime?: string;
  flushDate?: string;
  flushTime?: string;
  organShipped?: boolean;
  transplantDate?: string;
  shippedOutOfProvince?: boolean;
  perfusionPump?: string;
  perfusionDate?: string;
  perfusionTime?: string;
  organSuccessfullyTransplanted?: boolean;
  graftWeightGrams?: string;
  abnormalitiesOrConcerns?: string;
}

const ORGAN_CODES_FOR_SPECIFICATION = [
  OrganOfferSpecificationCodeValue.RightKidney,
  OrganOfferSpecificationCodeValue.LeftKidney,
  OrganOfferSpecificationCodeValue.RightLiver,
  OrganOfferSpecificationCodeValue.LeftLiver,
  OrganOfferSpecificationCodeValue.RightLobeLung,
  OrganOfferSpecificationCodeValue.LeftLobeLung
];

@Component({
  components: {
    TextInput,
    DateInput,
    TimeInput,
    TextAreaInput,
    SelectInput,
    CheckboxInput,
    BooleanRadioInput,
    NumberInput,
    SubSection,
    CardSection,
    ModalSection
  }
})
export default class RecoveryInformation extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.pageState.currentPage.recoveryInformation) editState!: RecoveryInformationPageState;
  @State(state => state.hospitals.transplantProgram) private hospitals!: Hospital[];
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) selectedRecipient!: Recipient;

  // Getters
  @Getter('show', { namespace: 'livingDonors' }) private livingDonor!: LivingDonor;
  @Getter('clientId', { namespace: 'livingDonors' }) private livingDonorId!: string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('ontarioTransplantOptions', { namespace: 'hospitals' }) ontarioTransplantOptions!: GenericCodeValue[];
  @Getter('selectedLivingDonorJourney', { namespace: 'livingDonors' }) selectedLivingDonorJourney!: LivingDonorJourney|null;
  @Getter('selectedLivingDonorJourneyOrganCode', { namespace: 'livingDonors' }) selectedJourneyOrganCode!: number|undefined;
  @Getter('organName', { namespace: 'lookups' }) organNameLookup!: (organCode?: number) => string;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => { code: number; value: string }[];
  @Getter('getHospitalById', { namespace: 'hospitals' }) private getHospitalById!: (hospitalId?: string|null) => Hospital|null;
  @Getter('hasIntendedRecipients', { namespace: 'livingDonors' }) private hasIntendedRecipients!: boolean;
  @Getter('getIntendedRecipient', { namespace: 'livingDonors' }) private getIntendedRecipient!: LivingDonorIntendedRecipient|null;
  @Getter('isLivingDonorTransplantComplete', { namespace: 'livingDonors' }) private isLivingDonorTransplantComplete!: boolean;
  @Getter('showTransplantCompleteButton', { namespace: 'livingDonors' }) private showTransplantCompleteButton!: boolean;
  @Getter('destinationRecipientAllocatedFromThisLivingDonor', { namespace: 'livingDonors' }) private destinationRecipientAllocatedFromThisLivingDonor!: boolean;

  @Prop({ default: false }) newLivingDonor!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Lookup tables to be loaded by the CardSection component
  public lookupsToLoad = [];
  private isLoadingSelectedRecipient = false;
  private transplantCompleteError: string|null = null;

  get getIntendedRecipientHasLiver(): boolean {
    const recipient = this.getIntendedRecipient;
    return recipient && recipient.journey?.organ_code ? this.selectedJourneyOrganCode === OrganCodeValue.Liver : false;
  }

  /**
   * Returns an array of options for Organ Specification
   *
   * Fetches the organ specification subtable from the appropriate organ lookup table
   *
   * @returns {OrganSpecification[]} options for organ specification
   */
  get organSpecificationLookup(): OrganSpecification[] {
    if (!this.organLookup || !this.selectedJourneyOrganCode) {
      return [];
    }
    const organCode = this.selectedJourneyOrganCode;

    if (!this.selectedJourneyOrganCode) return [];
    // Retrieve information based on organCode
    const organLookupEntry = this.organLookup.find((organ: Organ) => {
      return organ.code.toString() === organCode.toString();
    });
    if (!organLookupEntry || !organLookupEntry.sub_tables) {
      return [];
    }
    // Fetch appropriate options sub table
    const organSpecifications: OrganSpecification[] = organLookupEntry?.sub_tables?.organ_specifications || [];
    const offerOrganSpec: OrganSpecification[] = organSpecifications.filter((organSpec: OrganSpecification) => {
      return ORGAN_CODES_FOR_SPECIFICATION.includes(organSpec.code);
    });
    return offerOrganSpec;
  }

  /**
   * Return organs available for Living Donor (Liver/Kidney/Lung)
   *
   * @returns {Organ[]} filtered list of Organs
   */
  get livingDonorOrgans(): Organ[] {
    return this.organLookup.filter((organ: Organ) => {
      return [OrganCodeValue.Liver, OrganCodeValue.Kidney, OrganCodeValue.Lung].includes(organ.code);
    });
  }

  get getOrganOptions(): { code: number; value: string }[] {
    return this.organOptions('single');
  }

  // Should we disable the Transplant Complete button?
  get disableTransplantCompleteButton(): boolean {
    // NOTE: if there is no active intended recipient, return 'true' to disable transplant complete button
    const recipientJourney = this.getIntendedRecipient && this.getIntendedRecipient.journey || null;
    if (!recipientJourney) return true;

    // NOTE: if active intended recipient does not have LD TIP status, return 'false' to enable transplant complete button
    const hasLivingDonorTransplantInProgress = !!recipientJourney.stage_attributes?.waitlist?.factors.living_donor_transplant_in_progress;
    if (!hasLivingDonorTransplantInProgress) return false;

    return !this.destinationRecipientAllocatedFromThisLivingDonor;
  }

  // Re-initialize this sub-section
  public reinitialize(): void {
    this.initializeForm();
  }

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  public mounted(): void {
    Promise.all([
      this.$store.dispatch('hospitals/load'),
      this.$store.dispatch('validations/loadRecoveryInfoEditValidations', { livingDonorId: this.livingDonorId, livingDonorJourneyId: this.selectedLivingDonorJourney?._id?.$oid })
    ]).finally(() => {
      this.initializeForm();
    });
  }

  /**
   * Emits a loaded event after all subcomponents have finished loading.
   *
   * @listens recoveryInformation#loaded
   * @emits loaded
   */
  public loaded(): void {
    this.$emit('loaded', 'recoveryInformation');
  }

  /**
   * Populates the Recovery Information form state with data from the selected Deceased Donor.
   */
  public initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'recoveryInformation',
      value: this.buildRecoveryInformationPageState(this.selectedLivingDonorJourney)
    });
  }

  /**
   * Generates Recovery Information form state based on the Living Donor document
   *
   * @param selectedJourney living donor journey provided by API
   * @returns {RecoveryInformationPageState} page state for Recovery Information
   */
  public buildRecoveryInformationPageState(selectedJourney?: any): RecoveryInformationPageState {
    if (!selectedJourney) { return {}; }
        
    const recovery_info = selectedJourney.recovery_info || {};

    const organ_shipped_info = recovery_info ? recovery_info.organ_shipped_info : undefined;

    const hospital = selectedJourney?.transplant_program ? this.getHospitalById(selectedJourney?.transplant_program?.transplant_hospital_id?.$oid) : undefined;
    const hospitalName = hospital?.hospital_name_info.short_name;

    // Return parameters extracted from data document based on structure of form state interface
    const result: RecoveryInformationPageState = {
      referredOrganName: this.organNameLookup(this.selectedJourneyOrganCode),
      organSpecification: recovery_info.organ_specification_code,
      surgeon1: recovery_info.surgeon1,
      surgeon2: recovery_info.surgeon2,
      transplantProgram: recovery_info.transplant_hospital_id,
      hospitalName: hospitalName,
      startDate: this.parseDateUiFromDateTime(recovery_info.start_date),
      startTime: this.parseTimeUiFromDateTime(recovery_info.start_date),
      endDate: this.parseDateUiFromDateTime(recovery_info.end_date),
      endTime: this.parseTimeUiFromDateTime(recovery_info.end_date),
      comments: recovery_info.comments,
      completed: recovery_info.completed !== null ? recovery_info.completed : true, // From Requirements: Default: YES
      crossClampDate: this.parseDateUiFromDateTime(recovery_info.cross_clamp_date), // separate fields for left/right on TOTAL
      crossClampTime: this.parseTimeUiFromDateTime(recovery_info.cross_clamp_date), // separate fields for left/right on TOTAL
      flushDate: this.parseDateUiFromDateTime(recovery_info.flush_date),
      flushTime: this.parseTimeUiFromDateTime(recovery_info.flush_date),
      organShipped: recovery_info.organ_shipped || null,
      transplantDate: this.parseDateUi(recovery_info.transplant_date),
      graftWeightGrams: recovery_info.liver_graft_weight,
      abnormalitiesOrConcerns: recovery_info.liver_abnormalities_concerns,
    };

    if (organ_shipped_info) {
      result.shippedOutOfProvince = organ_shipped_info.oop || false;
      result.perfusionPump = organ_shipped_info.perfusion_pump;
      result.perfusionDate = this.parseDateUiFromDateTime(organ_shipped_info.perfusion_date);
      result.perfusionTime = this.parseTimeUiFromDateTime(organ_shipped_info.perfusion_date);
      result.organSuccessfullyTransplanted = organ_shipped_info.organ_successfully_transplanted;
    }

    return result;
  }

  // When organShipped is clicked then when it's true set defaults and when its false clear out section
  public changeShippedSection(): void {
    if (!this.editState.organShipped) {
      // Reqiurements: Default == No 
      Vue.set(this.editState, 'shippedOutOfProvince', false);
    } else {
      // Clear out all shipped section info when shipped is ticked out
      Vue.set(this.editState, 'shippedOutOfProvince', null);
      Vue.set(this.editState, 'perfusionPump', null);
      Vue.set(this.editState, 'perfusionDate', null);
      Vue.set(this.editState, 'perfusionTime', null);
      Vue.set(this.editState, 'organSuccessfullyTransplanted', null);
    }
    
  }

  public extractPatch(): any {    
    const organ_shipped_info = {
      oop: this.editState.shippedOutOfProvince,
      perfusion_pump: this.editState.perfusionPump,
      perfusion_date: this.sanitizeDateTimeApi(this.editState.perfusionDate, this.editState.perfusionTime),
      organ_successfully_transplanted: this.editState.organSuccessfullyTransplanted,
    };

    return {
      organ_specification_code: this.editState.organSpecification || null,
      surgeon1: this.editState.surgeon1 || null,
      surgeon2: this.editState.surgeon2 || null,
      start_date: this.sanitizeDateTimeApi(this.editState.startDate, this.editState.startTime),
      end_date: this.sanitizeDateTimeApi(this.editState.endDate, this.editState.endTime),
      comments: this.editState.comments || null,
      completed: this.editState.completed,
      cross_clamp_date: this.sanitizeDateTimeApi(this.editState.crossClampDate, this.editState.crossClampTime) || null,
      flush_date: this.sanitizeDateTimeApi(this.editState.flushDate, this.editState.flushTime),
      organ_shipped: this.editState.organShipped || null,
      organ_shipped_info: organ_shipped_info,
      liver_graft_weight: this.editState.graftWeightGrams || null,
      liver_abnormalities_concerns: this.editState.abnormalitiesOrConcerns || null,
    };
  }

  // Toggle a modal based on a ref
  private toggleModal(ref: string): void {
    const targetModal = this.$refs[ref] as ModalSection;
    targetModal.toggleModal();
  }

  private confirmTransplantComplete(): void {
    this.transplantCompleteError = null;
    // show modal
    this.toggleModal('recoveryInformationConfirmTransplantCompleteModal');
  }

  // Handle saving triggered by local save button
  private savePatchTransplantComplete(): void {
    // hide modal
    this.toggleModal('recoveryInformationConfirmTransplantCompleteModal');
    // Refer to the save provider that handles this form area
    const journeyId = this.selectedLivingDonorJourney?._id?.$oid;
    const payload = {
      living_donor_id: this.livingDonorId,
      living_donor_journey_id: journeyId,
    };
    // Dispatch save action and register the response
    this.$store
      .dispatch('livingDonors/saveRecoveryInfoTransplantComplete', {
        livingDonorId: this.livingDonorId,
        livingDonorJourneyId: journeyId,
        transplantCompletePatch: payload,
      }).then((success: SaveResult) => {
        // If successful, update the current livingDonor
        const recoveryInfo = success.responseData.recovery_info;
        this.$store.commit('livingDonors/setRecoveryInfo', { journeyId, recoveryInfo });
        this.$emit('reload');
      }).catch((error: SaveResult) => {
        // Emit event to handle errors
        this.transplantCompleteError = error && error.errorMessages ? error.errorMessages.join(', ') : null;
      });
  }

  // Handle saving triggered by local save button
  private validateSavePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveRecoveryInformation as unknown as SaveProvider;
    const journeyId = this.selectedLivingDonorJourney?._id?.$oid;
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractPatch();
    // Clear previous errors
    this.clear();
    // Dispatch save action and register the response
    this.$store
      .dispatch('livingDonors/validateRecoveryInfo', {
        livingDonorId: this.livingDonorId,
        livingDonorJourneyId: journeyId,
        recoveryInfoPatch: livingDonorPatch
      }).then((success: SaveResult) => {
        Promise.all([
          saveProvider.resetSaveToolbar(), // Reset the save provider's save toolbar
          this.toggleModal('recoveryInformationConfirmSaveModal') // show modal
        ]);
      }).catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      });
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }

  // Handle saving triggered by local save button
  private saveValidatedPatch(): void {
    this.toggleModal('recoveryInformationConfirmSaveModal'); // close modal
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveRecoveryInformation as unknown as SaveProvider;
    const journeyId = this.selectedLivingDonorJourney?._id?.$oid;
    // Report to parent that saving has began
    this.$emit('save', 'recoveryInformation');
    // Generate payload based on current edit state
    const livingDonorPatch = this.extractPatch();
    // Dispatch save action and register the response
    this.$store
      .dispatch('livingDonors/saveRecoveryInfo', {
        livingDonorId: this.livingDonorId,
        livingDonorJourneyId: journeyId,
        recoveryInfoPatch: livingDonorPatch
      }).then((success: SaveResult) => {
        // If successful, update the current livingDonor and show success notification
        const recoveryInfo = success.responseData.recovery_info;
        this.$store.commit('livingDonors/setRecoveryInfo', { journeyId, recoveryInfo });
        saveProvider.registerSaveResult(success);
        // If successful, reload living donor and show success notification
        this.$emit('reload');
      }).catch((error: SaveResult) => {
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    // Refer to the save provider that handle the areas present on this form component
    const saveProvider = this.$refs.saveRecoveryInformation as unknown as SaveProvider;
    // Reset the save provider's save toolbar
    saveProvider.resetSaveToolbar();
  }

  // API response keys on the left, id for our UI on the right
  get idLookup(): {[key: string]: string} {
    return {
      'organ_specification_code' : 'recovery-information-organ_specification',
      'surgeon1' : 'recovery-information-surgeon_1',
      'surgeon2' : 'recovery-information-surgeon_2',
      'transplant_hospital_id' : 'recovery-information-transplant_program',
      'start_date' : 'recovery-information-start_date',
      'end_date' : 'recovery-information-end_date',
      'comments' : 'recovery-information-comments',
      'completed' : 'recovery-information-completed',
      'cross_clamp_date' : 'recovery-information-cross_clamp_date',
      'flush_date' : 'recovery-information-flush_date',
      'organ_shipped' : 'recovery-information-organ_shipped',
      'organ_shipped_info.oop' : 'recovery-information-shipped_out_of_province',
      'organ_shipped_info.perfusion_pump' : 'recovery-information-perfusion_pump',
      'organ_shipped_info.perfusion_date' : 'recovery-information-perfusion_date',
      'organ_shipped_info.organ_successfully_transplanted' : 'recovery-information-organ_successfully_transplanted',
      'liver_graft_weight' : 'recovery-information-liver_graft_weight',
      'liver_abnormalities_concerns' : 'recovery-information-liver_abnormalities_concerns',
    };
  }
 
}
