
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TransplantMixin } from "@/mixins/transplant-mixin";
import { Getter, State } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import SubSection from "@/components/shared/SubSection.vue";
import { HeartTransplantAttributes, RecipientJourney, TransplantFactors } from "@/store/recipientJourney/types";
import DateInput from "@/components/shared/DateInput.vue";
import { RecipientTransplantAttributes } from "@/store/recipientJourney/types";
import TimeInput from "@/components/shared/TimeInput.vue";
import NumberInput from '@/components/shared/NumberInput.vue';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { Recipient } from '@/store/recipients/types';
import { TransplantSectionPageState } from '@/mixins/transplant-mixin';
import TextInput from "@/components/shared/TextInput.vue";

export interface HeartTransplantDetailsPageState {
  transplantStartDate?: string|null;
  transplantStartTime?: string|null;
  removedFromColdDate?: string|null;
  removedFromColdTime?: string|null;
  clampRemovalDate?: string|null;
  clampRemovalTime?: string|null;
  coldIschemicTime?: string;
  rewarmTime?: string;
  totalIschtime?: string;
}

@Component({
  components: {
    SubSection,
    DateInput,
    TimeInput,
    NumberInput,
    TextInput,
  }
})
export default class HeartTransplantDetails extends mixins(DateUtilsMixin, TransplantMixin) implements SaveableSection {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('convertTimeInSeconds', { namespace: 'utilities' }) convertTimeInSeconds!: (time?: number) => string;
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;

  // Props
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private loaded(): void {
    this.$emit('loaded', 'heart-transplant-details');
  }

  /**
   * Return true if we can edit the transplant details
   *
   * @returns {boolean} true if we can edit
   */
  get canEdit(): boolean{
    if (this.newJourney || this.journey.completed) {
      return false;
    }
    return true;
  }

  /**
   * Build state for the HeartTransplantDetails form area
   *
   * @param HeartTransplantAttributes from RecipientTransplantAttributes
   * @returns {HeartTransplantDetailsPageState} form state for HeartTransplant
   */
  public buildTransplantDetailsState(transplantDetails?: RecipientTransplantAttributes): HeartTransplantDetailsPageState {
    const heartTransplantAttributes = transplantDetails as HeartTransplantAttributes || {};
    const result: HeartTransplantDetailsPageState = {
      transplantStartDate: this.parseDateUiFromDateTime(heartTransplantAttributes.transplant_start_date || undefined),
      transplantStartTime: this.parseTimeUiFromDateTime(heartTransplantAttributes.transplant_start_date || undefined),
      removedFromColdDate: this.parseDateUiFromDateTime(heartTransplantAttributes.end_cold_storage_date || undefined),
      removedFromColdTime: this.parseTimeUiFromDateTime(heartTransplantAttributes.end_cold_storage_date || undefined),
      clampRemovalDate: this.parseDateUiFromDateTime(heartTransplantAttributes.x_clamp_removal_date || undefined),
      clampRemovalTime: this.parseTimeUiFromDateTime(heartTransplantAttributes.x_clamp_removal_date || undefined),
      coldIschemicTime: this.convertTimeInSeconds(heartTransplantAttributes.cold_isch_time || 0),
      rewarmTime: this.convertTimeInSeconds(heartTransplantAttributes.rewarm_time || 0),
      totalIschtime: this.convertTimeInSeconds(heartTransplantAttributes.total_isch_time || 0)
    };
    return result;
  }

  /**
   * Gets a patch object representing form edit state changes for this form
   *
   * Delegates the logic of building the patch to a local private method
   *
   * @returns {any} patch object containing field changes
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.transplantDetails || !this.editState.transplantDetails.organTransplantDetails) {
      return {};
    } else {
      return this.extractHeartTransplantDetailsPatch(this.editState.transplantDetails.organTransplantDetails);
    }
  }

  // String for confirmation dialog for partial cluster transplant hold
  get confirmationText(): string {
    if (!this.isConfirmationRequired()) return '';

    return this.$t('confirm_partial_cluster_transplant_hold').toString();
  }

  /**
   * Saves the form edit state.
   *
   * Prepares a payload for Transplant Atributes, dispatches a save action, and registers the save result.
   * @emits clear clear validation errors because saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('clear');
    // Generate payload based on current edit state
    const transplantAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: transplantAttributesPatch,
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/saveTransplantDetails', payload).then((success: SaveResult) => {
      // Report to parent that saving has completed
      this.$emit('saved', 'heartTransplantDetails');
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.$emit('handleErrors', error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

   /**
   * Clears all save notifications shown by the form.
   *
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.saveTransplantDetails as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }


  // API response keys on the left, id for our UI on the right
    public idLookup(): {[key: string]: string} {
    return {
      'transplant_start_date' : 'transplantStartDate',
      'end_cold_storage_date' : 'removedFromColdDate',
      'x_clamp_removal_date'  : 'clampRemovalDate',
      'cold_isch_time'        : 'coldIschemicTime',
      'rewarm_time'           : 'rewarmTime',
      'transplant_start_time' : 'transplantStartTime',
      'end_cold_storage_time' : 'removedFromColdTime',
      'x_clamp_removal_time'  : 'clampRemovalTime',
    };
  }

  /**
   * Returns a journey patch object containing changes from a Transplant Details form
   *
   * @returns {RecipientTransplantAttributes}
   */
  private extractHeartTransplantDetailsPatch(transplantDetails: HeartTransplantDetailsPageState): HeartTransplantAttributes {
    const transplant = this.editState.transplantDetails || {};
    const heartTransplantDetails = transplant.organTransplantDetails as HeartTransplantDetailsPageState || {};

    // Fetch Transplant Date from the top-level of the form
    const transplantDate = this.editState.transplantDetails?.transplantDate;

    // Build nested 'factors' object to include in the Transplant Attributes patch
    const factors: TransplantFactors = {
      transplant_date: transplantDate ? this.sanitizeDateApi(transplantDate) : null,
    };

    // Build patch for Transplant Attributes object
    const result: HeartTransplantAttributes = {
      transplant_start_date: this.sanitizeDateTimeApi(heartTransplantDetails.transplantStartDate, heartTransplantDetails.transplantStartTime),
      end_cold_storage_date: this.sanitizeDateTimeApi(heartTransplantDetails.removedFromColdDate, heartTransplantDetails.removedFromColdTime),
      x_clamp_removal_date: this.sanitizeDateTimeApi(heartTransplantDetails.clampRemovalDate, heartTransplantDetails.clampRemovalTime),
      factors,
      donor_id: transplant.donorId ? { $oid: transplant.donorId } : null,
    };

    // Copy Details to Clustered Organs for details see https://shore.tpondemand.com/entity/7541-722-v42-transplant-details
    if (this.isClustered) {
      result.copy_to_cluster = transplant.copyToCluster;
    }

    return result;
  }
}
