
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { TableConfig } from '@/types';
import { Getter, State } from 'vuex-class';
import { ObjectId, NumericCodeValue } from '@/store/types';
import { Organ, Province, CountryValue, OrganCodeValue } from '@/store/lookups/types';
import TextInput from '@/components/shared/TextInput.vue';
import DateInput from '@/components/shared/DateInput.vue';
import SubSection from '@/components/shared/SubSection.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectInput from '@/components/shared/SelectInput.vue';
import CountryInput from '@/components/shared/CountryInput.vue';
import SelectOtherInput from '@/components/shared/SelectOtherInput.vue';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { RecipientOopTransplant } from '@/store/recipients/types';
import { SaveableSection, SaveProvider, SaveResult } from '@/types';
import { OrganReferralForm } from '@/components/recipients/OrganReferrals.vue';

// Declare all v-model fields used in the form
interface PreviousTransplantRow {
  journeyId?: ObjectId;
  transplantedOrgan?: string;
  transplantDate?: string;
  provinceCountry?: string;
  transplantHospital?: string;
}

export interface PreviousTransplantForm {
  transplantedOrgan?: number;
  transplantedOrganOther?: string;
  transplantDate?: string;
  province?: string;
  country?: string;
  state?: string;
  countryOther?: string;
  transplantHospital?: string;
  dialysisStartDate?: string;
}

const PROVINCE_ON = 'ON';

@Component({
  components: {
    TextInput,
    DateInput,
    SubSection,
    SelectInput,
    CountryInput,
    SelectOtherInput
  }
})
export default class PreviousTransplants extends mixins(DateUtilsMixin) implements SaveableSection {
  // State
  @State(state => state.pageState.currentPage.organReferrals) editState!: OrganReferralForm;
  @State(state => state.recipients.outOfProvinceJourneys) oopJourneys!: RecipientJourney[];
  @State(state => state.recipients.selectedPreviousTransplant) selectedPreviousTransplant!: RecipientOopTransplant;
  // Lookups
  @State(state => state.lookups.oop_transplant_organ_codes) private oopTransplantOrganCodeLookup!: NumericCodeValue[];

  // Properties
  @Prop({ default: false }) newRecipient!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  // Getters
  @Getter('organName', { namespace: 'lookups'}) organName!: (organCode?: number) => string;
  @Getter('organOptions', { namespace: 'lookups' }) organOptions!: (type?: string) => { code: number; value: string }[];
  @Getter('country', { namespace: 'lookups' }) private countryLookup: any;
  @Getter('us_state', { namespace: 'lookups' }) private usStateLookup: any;
  @Getter('province', { namespace: 'lookups' }) private provinceLookup: any;
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('previousTransplants', { namespace: 'recipients' }) previousTransplants!: RecipientOopTransplant[];
  @Getter('defaultLookup', { namespace: 'lookups' }) defaultLookup!: (lookupId: string) => any;

  // Emit loaded event immediately for new recipients, or after loading existing recipient's out of province journeys
  private mounted(): void {
    // Clear any previously loaded records
    this.$store.commit('recipients/clearOutOfProvinceJourneys');
    if (this.newRecipient) {
      // For a new recipient, loading completes immediately
      this.$emit('loaded', 'previousTransplants');
    } else {
      // For an existing recipient, begin loading records
      this.$store.dispatch('recipients/loadOutOfProvinceJourneys', this.recipientId).then(() => {
        // Loading completes after fetching records
        this.$emit('loaded', 'previousTransplants');
      }).catch(() => {
        // Loading completes after failing to fetch records
        this.$emit('loaded', 'previousTransplants');
      });
    }
  }

  /**
   * Clear transplanted organ other when transplanted organ changes
   * null otherwise will fail on update
   *  */ 
  public clearTransplantedOrganOther() {
    Vue.set(this.editState.previousTransplant, "transplantedOrganOther", null);
  }

  // Build options list for Transplanted Organ
  get transplantedOrganOptions(): { code: number; value: string }[] {
    return this.organOptions('single');
  }

  /**
   * Shows Dialysis State Date if selected Organ is Kidney / Pancreas(Whole)
   * 
   **/ 
  get showDialysisInfo() {
    const organs_allowed = [ OrganCodeValue.Kidney.toString() ];
    const organCode = this.editState.previousTransplant.transplantedOrgan;
    if(organCode) return organs_allowed.includes(organCode.toString());
  }

  // Build options list for Transplant Province
  get transplantProvinceOptions(): { code: string; value: string }[] {
    if (!this.provinceLookup) {
      return [];
    }
    // Exclude Ontario
    const filtered = this.provinceLookup.filter((option: { code: string; value: string }) => {
      return option.code != PROVINCE_ON;
    });
    return filtered;
  }

  // Stores selected Previous Transplant based on clicked row
  private selectPreviousTransplant(event: any): void {
    // Get selected ID from the table row reference in the select event
    const selectedId = event.row.journeyId && event.row.journeyId.$oid ? event.row.journeyId!.$oid : undefined;
    if (!selectedId || !this.editState || !this.previousTransplants) {
      return;
    }
    // Find the selected source document
    const found = this.previousTransplants.find((transplant: RecipientOopTransplant) => {
      return transplant.journeyId && transplant.journeyId.$oid === selectedId;
    });
    if (!found) {
      return;
    }
    // Store the selection
    this.$store.commit('recipients/selectPreviousTransplant', found);
    // Build form state based on selected document
    const form: PreviousTransplantForm = this.buildPreviousTransplantForm(this.selectedPreviousTransplant);
    Vue.set(this.editState, 'previousTransplant', form);
  }

  // Resets edit state to prepare for entering a new document
  private createPreviousTransplant(): void {
    // Clear stored selection
    this.$store.commit('recipients/clearPreviousTransplant');
    // Build empty form state
    const form: PreviousTransplantForm = this.buildPreviousTransplantForm();
    Vue.set(this.editState, 'previousTransplant', form);

    this.$emit('clear');
    this.resetSaveToolbar();
  }

  // Builds Previous Transplant form state based on a Recipient Out of Province Transplant
  public buildPreviousTransplantForm(transplant?: RecipientOopTransplant): PreviousTransplantForm {
    // Define defaults
    const defaultValues: PreviousTransplantForm = {
      country: this.defaultLookup('country'),
    };
    // Return defaults if building a form for undefined i.e. creating a new record
    if (!transplant) {
      return defaultValues;
    }
    // Return values from existing record, using defaults as needed if missing from record
    return {
      transplantedOrgan: transplant.organ_code,
      transplantedOrganOther: transplant.organ_code_other,
      transplantDate: this.parseDateUi(transplant.transplant_date),
      province: transplant.province,
      state: transplant.province,
      country: transplant.country || defaultValues.country,
      transplantHospital: transplant.facility,
      countryOther: transplant.country_other,
      dialysisStartDate: transplant.organ_code == OrganCodeValue.Kidney ? this.parseDateUi(transplant.dialysis_start_date) : undefined
    };
  }

  // Builds Previous Transplant table rows
  get previousTransplantRows(): PreviousTransplantRow[] {
    if (!this.previousTransplants) {
      return [];
    }
    return this.previousTransplants.map((transplant: RecipientOopTransplant): PreviousTransplantRow => {
      let provinceCountry = '-';
      if (transplant.country_other) {
        provinceCountry = transplant.country_other;
      } else if (transplant.province || transplant.country) {
        provinceCountry =  [transplant.province, transplant.country].join(' / ');
      }
      return {
        journeyId: transplant.journeyId,
        transplantedOrgan: this.organName(transplant.organ_code) || transplant.organ_code_other || '-',
        transplantDate: this.parseDisplayDateUi(transplant.transplant_date) || '-',
        provinceCountry,
        transplantHospital: transplant.facility || '-',
      };
    });
  }

  // Configuration for Previous Transplants table
  public previousTransplantsTableConfig(): TableConfig {
    return {
      data: this.previousTransplantRows,
      columns: [
        { label: this.$t('transplanted_organ').toString(), field: 'transplantedOrgan', width: '25%' },
        { label: this.$t('transplant_date').toString(), field: 'transplantDate', width: '25%' },
        { label: this.$t('province_country').toString(), field: 'provinceCountry', width: '25%' },
        { label: this.$t('transplant_hospital').toString(), field: 'transplantHospital', width: '25%' }
      ],
      empty: this.$t('use_form_below').toString(),
      createButton: this.canSave,
      createText: this.$t('create_oop_transplant').toString(),
    };
  }

  // Save Previous Transplant form patch
  public savePatch(): void {
    // Report to parent that saving has begun e.g. so parent can clear previous validation errors
    this.$emit('saving', 'previousTransplant');
    // Reference local form save button
    const saveProvider = this.$refs.savePreviousTransplant as unknown as SaveProvider;
    // Setup route parameters and payload
    const journeyId = this.selectedPreviousTransplant == undefined ? undefined : this.selectedPreviousTransplant.journeyId;
    const payload = {
      journeyId,
      recipientId: this.recipientId,
      journey: this.extractPatch(),
    };
    // Begin saving
    this.$store.dispatch('recipients/saveOutOfProvinceJourney', payload).then((success: SaveResult) => {
      // Report success to parent
      this.$emit('saved', 'previousTransplant');
      // Reload source data
      this.$store.dispatch('recipients/loadOutOfProvinceJourneys', this.recipientId);
      // Initialize form state
      this.createPreviousTransplant();
      // Show success notification
      saveProvider.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      // Report errors to parent
      this.handleErrors(error);
      // Show error notification
      saveProvider.registerSaveResult(error);
    });
  }

  // Clear save notifications
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.savePreviousTransplant as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  // Patch based on Previous Transplant form
  public extractPatch() {
    if (!this.editState || !this.editState.previousTransplant) {
      return {};
    }
    const form = this.editState.previousTransplant;
    let oop_province = null;
    if (form.country === CountryValue.Canada) {
      oop_province = form.province;
    } else if (form.country === CountryValue.USA) {
      oop_province = form.state;
    }
    const oopJourneyPatch: RecipientJourney = {
      organ_code: form.transplantedOrgan,
      oop_info: {
        oop_organ_other: form.transplantedOrganOther,
        oop_hospital: form.transplantHospital || null,
        oop_country: form.country || null,
        oop_province,
        oop_country_other: form.countryOther || null,
      },
      stage_attributes: {
         waitlist: {
          factors: {
            dialysis_start_date: this.sanitizeDateApi(form.dialysisStartDate)
          }
        },
        transplant: {
          factors: {
            transplant_date: this.sanitizeDateApi(form.transplantDate),
          },
        },
      },
    };
    return oopJourneyPatch;
  }

  // Clear province, state, and other country on country dropdown change
  public clearProvince() {
    if (!this.editState || !this.editState.previousTransplant) {
      return;
    }
    Vue.set(this.editState.previousTransplant, 'state', undefined);
    Vue.set(this.editState.previousTransplant, 'province', undefined);
    Vue.set(this.editState.previousTransplant, 'countryOther', undefined);
  }

  // Returns whether or not the edit state is empty
  get isEmpty(): boolean {
    if (!this.editState || !this.editState.previousTransplant) {
      return true;
    }
    const form = this.editState.previousTransplant;
    return (form.transplantedOrgan == null || form.transplantedOrgan.toString.length === 0)
        && (form.transplantedOrganOther == null || form.transplantedOrganOther.toString.length === 0)
        && (form.transplantDate == null || form.transplantDate.toString().length === 0)
        && (form.province == null || form.province.toString().length === 0)
        && (form.country == null || form.country.toString().length === 0)
        && (form.state == null || form.state.toString().length === 0)
        && (form.countryOther == null || form.countryOther.toString().length === 0)
        && (form.transplantHospital == null || form.transplantHospital.toString().length === 0);
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit("handleErrors", errors);
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    return {
      'organ_code'                                            : 'gci-previous-transplants-organ',
      'stage_attributes.transplant.factors.transplant_date'   : 'gci-previous-transplants-transplant_date',
      'oop_info.oop_organ_other'                              : 'gci-previous-transplants-organ-other',
      'oop_info.oop_country'                                  : 'gci-previous-transplants-country',
      'oop_info.oop_province'                                 : 'previous-transplants-province-state',
      'oop_info.oop_hospital'                                 : 'gci-previous-transplants-facility',
      'oop_info.oop_country_other'                            : 'gci-previous-transplants-othercountry',
      'stage_attributes.waitlist.factors.dialysis_start_date' : 'gci-previous-transplants-dialysis-start-date',
    };
  }
}
