
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RecipientJourney } from '@/store/recipientJourney/types';

import TransplantDetailsSection from '@/components/organs/shared/_TransplantDetailsSection.vue';

@Component({
  components: {
    TransplantDetailsSection
  }
})
export default class OrganOop extends Vue {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney|undefined;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;

  // Props
  @Prop({ default: false }) newJourney!: boolean;

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    const result = {};

    // Transplant Details Section
    const transplantDetailsSection = this.$refs.transplantDetailsSection as TransplantDetailsSection;
    if (transplantDetailsSection) {
      
      Object.assign(result, { ...transplantDetailsSection.idLookup() });
    }

    return result;
  }

  // PRIVATE

  // Loading processes unique to this component
  private mounted(): void {
    if (!this.newJourney) {
      const transplantDetailsSection = this.$refs.transplantDetailsSection as TransplantDetailsSection;
    }
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private saving(formReference: string) {
    this.$emit('saving', formReference);
  }

  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }
}
