import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=16facac8&"
import script from "./Reports.vue?vue&type=script&lang=ts&"
export * from "./Reports.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/views/_locales/Reports.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FReports.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports