
import { State, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { organCodeLookup } from '@/types';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { Recipient } from '@/store/recipients/types';
import { aggregateSaveResults } from '@/utils';
import { SaveProvider, SaveResult, SaveableSection } from '@/types';
import { PostTransplantFollowUp, RecipientJourney, RecipientReferralAttributes } from '@/store/recipientJourney/types';

import Vca from '@/components/organs/vca/Vca.vue';
import Lung from '@/components/organs/lung/Lung.vue';
import Heart from '@/components/organs/heart/Heart.vue';
import Liver from '@/components/organs/liver/Liver.vue';
import Kidney from '@/components/organs/kidney/Kidney.vue';
import SmallBowel from '@/components/organs/bowel/SmallBowel.vue';
import PancreasWhole from '@/components/organs/pancreas/PancreasWhole.vue';
import PancreasIslets from '@/components/organs/pancreas/PancreasIslets.vue';
import LungSpecificDetails from '@/components/organs/lung/LungSpecificDetails.vue';
import DonorAcceptability from '@/components/organs/shared/DonorAcceptability.vue';
import LiverSpecificDetails from '@/components/organs/liver/LiverSpecificDetails.vue';
import HeartSpecificDetails from '@/components/organs/heart/HeartSpecificDetails.vue';
import KidneySpecificDetails from '@/components/organs/kidney/KidneySpecificDetails.vue';
import PancreasWholeSpecificDetails from '@/components/organs/pancreas/PancreasWholeSpecificDetails.vue';
import VcaSpecificDetails from '@/components/organs/vca/VcaSpecificDetails.vue';
import PancreasIsletsSpecificDetails from '@/components/organs/pancreas/PancreasIsletsSpecificDetails.vue';
import SmallBowelSpecificDetails from '@/components/organs/bowel/SmallBowelSpecificDetails.vue';
import PageTop from '@/components/shared/PageTop.vue';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SideNavJourney from '@/components/organs/shared/side-nav/SideNavJourney.vue';
import RecipientStickySummary from '@/components/recipients/RecipientStickySummary.vue';
import RecipientSummary from '@/components/recipients/RecipientSummary.vue';
import ReferralDetailsSection from '@/components/organs/shared/ReferralDetailsSection.vue';
import OrganDetailsSection from '@/components/organs/shared/_OrganDetailsSection.vue';
import ReferralSection from '@/components/organs/shared/_ReferralSection.vue';
import deepmerge from 'deepmerge';
import { SmallBowelDetails } from '@/store/organSpecificDetails/types';
import LoadingSideNav from '@/components/shared/side-nav/LoadingSideNav.vue';
import LoadingDonorPage from '@/components/shared/LoadingDonorPage.vue';
import PostTransplantFollowUpSection from '@/components/organs/shared/PostTransplantFollowUpSection.vue';
import TransplantDetails from '@/components/organs/shared/TransplantDetails.vue';
import TransplantDetailsSection from '@/components/organs/shared/_TransplantDetailsSection.vue';
import PostTransplantSection from '@/components/organs/shared/_PostTransplantSection.vue';

@Component({
  components: {
    Vca,
    Lung,
    Heart,
    Liver,
    Kidney,
    SmallBowel,
    PancreasWhole,
    PancreasIslets,
    PageTop,
    SaveToolbar,
    SideNavJourney,
    RecipientSummary,
    RecipientStickySummary,
    LoadingSideNav, 
    LoadingDonorPage
  }
})
export default class EditOrgan extends Vue {
  // State
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedPostTransplantFollowUp) selectedPostTransplantFollowUp!: PostTransplantFollowUp;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;
  @Getter('recipientDisplayName', { namespace: 'recipients' } ) private recipientDisplayName!: string;
  @Getter('canSaveGetter', { namespace: 'validations' }) private canSaveGetter!: (newRecord: boolean) => boolean;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;
  @Getter("includeStomach", { namespace: "recipients" }) includeStomach!: (journeyId?: string) => boolean;
  @Getter("isTransplantDetailsApplicable", { namespace: "journeyState" }) isTransplantDetailsApplicable!: boolean;
  @Getter('lookupValue', { namespace: 'lookups' }) lookupValue!: (code: string|undefined, lookupId: string) => any;

  private sectionsLoaded = new Set();
  private sectionsLoading = new Set();
  private allSectionsLoaded = false;

  // used to check if all sections saved successfully, if one fails then show error message
  private journeySaveSuccess = false
  private transplantDetailsSuccess = false
  private postTransplantFollowUpSuccess = false;

  // used to check if all sections have finished saving
  // if all sections finished saving and all sections saved successfully then reroute page
  private journeyFinishedSaving = false;
  private transplantDetailsFinishedSaving = false;
  private postTransplantFollowUpFinishedSaving = false;

  /**
   * Vue lifecyle hook, for when the reactivity system has taken control of the Document Object Model.
   *
   * @listens #mounted
   */
  private mounted(): void {
    const organId = this.$route.params.organId;
    const recipientId = this.recipientId;
    const journeyId = this.journey?._id?.$oid;
    Promise.all([
      this.$store.dispatch('journeyState/getJourney', organId),
      this.setPageTitle(),
      this.$store.dispatch('validations/loadEdit', { view: `recipients/${recipientId}/journeys`, action: 'edit_validations', clientId: journeyId })
    ]).finally(() => {
      this.$store.dispatch('utilities/scrollBehavior');
    });
  }

  /**
   * Return true if all subsections are loaded
   *
   * @returns {boolean} true if the page is loaded
   */
  get isLoaded(): boolean {
    return this.allSectionsLoaded;
  }
  
  // Organ details section will let us know what sections need to be loaded
  public sectionsToLoad(ref: string[]): void {
    // Incoming array sections we're loading from the top level organ details component
    this.sectionsLoading = new Set(ref);
  }
  
  public loaded(ref: string): void {
    if (!ref) return;
    // Add the ref we just loaded
    this.sectionsLoaded.add(ref);
    if (this.sectionsLoaded.size === this.sectionsLoading.size) {
      this.$store.dispatch('utilities/scrollBehavior');
      this.allSectionsLoaded = true;
    }
  }
  
  /**
   * Clear sections loaded when the organ_id changes
   * 
   * @listens $route.params.organ_id
   */
  private clearSectionsLoaded(): void {
    this.sectionsLoaded.clear();
    this.sectionsLoading.clear();
  }
  
  /**
   * Return titlized organ name
   *
   * @returns {string} organ name titlized
   */
  get organDescription(): string {
    let organName = this.organComponent;
    if (this.includeStomach(this.journeyId) && this.journey.organ_code == OrganCodeValue.SmallBowel) {
      return organName = this.$t(`${organName} + Stomach`).toString();
    } else {
      return this.$t(organName).toString();
    }
  }

  /**
   * Gets the current journey as lower case
   *
   * Using the organ code, return the lower case organ name
   *
   * @returns {string} organ as lower case
   */
  get organComponent(): string {
    return organCodeLookup[`${this.journey ? this.journey.organ_code : ''}`] || '';
  }

  // Save organ
  public performSave(): void {
    // Get the organ code from the route params
    const organCode = this.$route.params.organ_code;
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveOrgan as SaveToolbar;
    // Show appropriate notification
    saveToolbar.startSaving();
    // Refer to top level organ component
    const organComponent = this.$refs.organComponent as Liver|Lung|Kidney|Heart|PancreasWhole|PancreasIslets|Vca|SmallBowel;
    // Refer to organ specific component
    const organDetails = organComponent.$refs.organSpecificDetailsSection as LiverSpecificDetails|LungSpecificDetails|KidneySpecificDetails|HeartSpecificDetails|PancreasWholeSpecificDetails|PancreasIsletsSpecificDetails|VcaSpecificDetails|SmallBowelSpecificDetails;

    // Card section 1 - Organ Details
    const organDetailsSection = organComponent.$refs.organDetailsSection as OrganDetailsSection;
    const organDetailsSectionPatch = organDetailsSection.extractPatch();

    // Card section 2 - Referral Details / Referring Physician Details
    const referralSection = organComponent.$refs.referralSection as ReferralSection;
    const referralDetailsPatch = (referralSection.$refs.referralDetails as ReferralDetailsSection).extractPatch();

    // Card section 3 - Organ Specific Details
    const organSpecificDetailsPatch = organDetails.extractPatch();

    // Card section 4 - Donor Acceptability
    const donorAcceptabilitySection = organComponent.$refs.donorAcceptabilitySection as DonorAcceptability;
    const donorAcceptabilityPatch = donorAcceptabilitySection.extractPatch();

    // Card section 5 - Transplant Details
    const transplantDetailsSection = organComponent.$refs.transplantDetailsSection as TransplantDetailsSection;
    const transplantDetails = transplantDetailsSection.$refs.transplantDetails as TransplantDetails;
    const organTransplantDetails = transplantDetails.getOrganRef(transplantDetails.organ);
    const organTransplantDetailsPatch = organTransplantDetails.extractPatch();

    // Card section 6 - Transplant Details
    const postTransplantSection = organComponent.$refs.postTransplantSection as PostTransplantSection;
    const postTransplantFollowUpSection = postTransplantSection.$refs.postTransplantFollowUp as PostTransplantFollowUpSection;
    const postTransplantFollowUpPatch = postTransplantFollowUpSection.extractPatch();

    // Deepmerge all sections
    let payload = deepmerge.all([organDetailsSectionPatch, organSpecificDetailsPatch, referralDetailsPatch, donorAcceptabilityPatch]);

    // Create payload for API
    const recipientId = this.recipientId;
    const journeyId = this.journeyId;
    const journeyPayload = { recipientId, journeyId, journey: payload };

    // Reset validation errors
    this.resetValidationErrors();

    // Dispatch save actions in parallel and register the responses
    this.$store.dispatch('journeyState/saveJourney', journeyPayload).then((success: SaveResult) => {
      this.journeySaveSuccess = true;
      this.journeyFinishedSaving = true;
      this.registerSaveResult(success);
    }).catch((error: SaveResult) => {
      this.journeyFinishedSaving = true;
      this.registerSaveResult(error);
    });

    const transplantDetailsPayload = {
      journeyId: this.journey._id ? this.journey._id.$oid : undefined,
      recipientId: this.recipientId,
      transplantAttributes: organTransplantDetailsPatch,
    };
    // Only Dispatch save action and register the response if transplant details applicable 
    if(this.isTransplantDetailsApplicable) {
      this.$store.dispatch('journeyState/saveTransplantDetails', transplantDetailsPayload).then((success: SaveResult) => {
        this.transplantDetailsSuccess = true;
        this.transplantDetailsFinishedSaving = true;
        // Report to parent that saving has completed
        this.$emit('saved', `${transplantDetails.organ}TransplantDetails`);
        this.registerSaveResult(success);
      }).catch((error: SaveResult) => {
        this.transplantDetailsFinishedSaving = true;
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // // Show error notification
        this.registerSaveResult(error);
      }); 
    } else {
      this.transplantDetailsSuccess = true;
    }


    // only save post transplant followup if it is not in an empty state 
    if (!postTransplantFollowUpSection.isFollowUpEmpty()) {
      const postTransplantFollowUpPayload = {
        journeyId: this.journey._id ? this.journey._id.$oid : undefined,
        recipientId: this.recipient.client_id,
        followUp: postTransplantFollowUpPatch,
        id: !!this.selectedPostTransplantFollowUp ? this.selectedPostTransplantFollowUp._id?.$oid : undefined,
      };  

      this.$store.dispatch('journeyState/savePostTransplantFollowUp', postTransplantFollowUpPayload).then((success: SaveResult) => {
        this.postTransplantFollowUpSuccess = true;
        this.postTransplantFollowUpFinishedSaving = true;
        // Reload follow ups list
        this.$store.dispatch('journeyState/loadPostTransplantFollowUps', { journeyId: this.journey._id!.$oid, recipientId: this.recipient.client_id });
        //Reload the recipient and journey data
        this.$store.dispatch('recipients/get', this.recipient.client_id).then(() => {
          this.$store.dispatch('journeyState/getJourney', this.journeyId);
        });
        // Clear form state
        postTransplantFollowUpSection.createPostTransplantFollowUp();
        // // Show success notification
        this.registerSaveResult(success);
      }).catch((error: SaveResult) => {
        this.postTransplantFollowUpFinishedSaving = true;
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // // Show error notification
        this.registerSaveResult(error);
      });
    } else {
      this.postTransplantFollowUpSuccess = true;
    }
  }

  // PRIVATE

  /**
   * Update journey when organ_id changes
   * 
   * @listens $route.params.organ_id
   */
  @Watch('$route.params.organ_id')
  private updateJourney() {
    this.clearSectionsLoaded();
    this.$store.dispatch('journeyState/getJourney', this.$route.params.organ_id);
    this.setPageTitle();
  }

  /**
   * Gets a Boolean result for the selected journey.
   *
   * Converts the selected journey into a Boolean for easy reference.
   * A new journey returns true, an existing journey would be false.
   *
   * @returns {string} organ as lowercase string or empty string
   */
  @Watch('organDescription')
  private setPageTitle(): void {
    this.$store.commit('setPageTitle', `${this.$t('recipients')} / ${this.recipientDisplayName} / ${this.organDescription}`);
  }

  /**
   * Handle completion of each parallel save action, and complete saving when all actions have registered results
   *
   * @param result Save Result from a save action
   */
  private registerSaveResult(result: SaveResult): void {
    // Refer to the save toolbar that handles this page
    const saveToolbar = this.$refs.saveOrgan as SaveToolbar;
    // Register aggregate saving results
    if (this.journeyFinishedSaving && this.transplantDetailsFinishedSaving && this.postTransplantFollowUpFinishedSaving) {
      // once all sections have finished saving then check to see if all saved successfully
      // if one section fails then make sure error shows
      if(this.journeySaveSuccess && this.transplantDetailsSuccess && this.postTransplantFollowUpSuccess) {
        saveToolbar.stopSaving( { success: true } );
      } else {
        saveToolbar.stopSaving( { success: false } );
      }
    }
    // If successful, reload the root record
    if (result.success) {
      // Clear the recipient
      if (this.journeySaveSuccess && this.transplantDetailsSuccess && this.postTransplantFollowUpSuccess) {
        const clientId = this.recipientId;
        this.$store.dispatch('recipients/get', clientId);
        saveToolbar.stopSaving(result);
      }
    } else {
      // Handle errors
      this.handleErrors(result);
      saveToolbar.stopSaving(result);
    }
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult|SaveResult[]): void {
    // Clear previous errors
    (this.$refs.validations as any).reset();
    // Refer to top level organ component
    const organComponent = this.$refs.organComponent as Liver|Lung|Kidney|Heart|PancreasWhole|PancreasIslets|Vca|SmallBowel;
    // Gather validation IDs used in forms on this page
    const idLookup: {[key: string]: string} = {
      // until all organs are updated this will cause a linter error
      ...organComponent.idLookup(),
    };
    // Aggregate validation errors
    let aggregateErrors: {[key: string]: []} = {};
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((item) => {
      aggregateErrors = { ...item.validationErrors };
    });
    const formErrors: {[key: string]: []} = {};
    for (const key of Object.keys(aggregateErrors)) {
      formErrors[idLookup[key]] = aggregateErrors[key];
    }

    // Set all validation errors using the validation observer wrapping entire form
    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Clearing validations when saving
  private saving(formReference: string) {
    this.resetValidationErrors();
  }

  // Tell the top-level form validation observer to reset all errors
  private resetValidationErrors() {
    (this.$refs.validations as any).reset();
  }
}
