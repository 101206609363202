import { GetterTree } from 'vuex';
import { isMasked, uniqueElements } from '@/utils';
import { ObjectId, RootState } from '@/store/types';
import { CountryValue } from '@/store/lookups/types';
import { AlcoholicLiverDiseaseProgram, LiverDetails, SmallBowelDetails } from '@/store/organSpecificDetails/types';
import { RecipientReferralDecision, LookupsState, RecipientAssessmentDecision, RecipientConsultationDecision, WaitlistFactorCodeValue, OrganCodeValue, HeartMedicalStatusValue } from '@/store/lookups/types';
import { SignificantEventDecision, RecipientSignificantEventType, Recipient } from '@/store/recipients/types';
import { JourneyState, ReferralDecision, AssessmentDecision, ConsultationDecision, RecipientJourney, WaitlistDecision, RecipientWaitlistAttributes, WaitlistStatusValue, JourneyStage, AllocatedDonorDetails, JourneyOption, RecipientJourneyTransferStatus, TransferType, TRANSFER_TYPES_CONSIDERED_ACTIVE_TRANSFER, ReferredWithOption } from '@/store/recipientJourney/types';

export const getters: GetterTree<JourneyState, RootState> = {
  selectedJourney(state) {
    return state.selectedJourney;
  },
  // TODO: refactor all getters for journey ID to use this
  journeyId(state): string|undefined {
    if (state.selectedJourney && state.selectedJourney._id) {
      return state.selectedJourney._id.$oid.toString();
    } else {
      return undefined;
    }
  },

  /**
   * Returns true if selected journey is heart organ and ipos heart enabled
   * 
   * @returns {boolean} if heart and ipos enabled
   */
   showIposForJourney(state, getters, rootState, rootGetters): boolean {
    if (!getters.selectedJourney) return false;
    const iposEnabled = rootGetters['features/ctrIposHeart'];
    return getters.selectedJourney.organ_code == OrganCodeValue.Heart && iposEnabled;
  },

  /**
   * Check summary display text derived from combination of journey stage and state 
   *
   * @param journey recipient journey
   * @returns {string} phase
   */
   journeyPhase(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): string => {
      const journeyPhase = rootGetters['recipients/getJourneyStageDisplayValue'](journey);
      return journeyPhase;
    };
  },

  /**
   * Filter array of journeys to exclude any with specified hospital ID
   *
   * @param journeys recipient journeys to filter
   * @param hospitalId transplant program hospital ID
   * @returns {RecipientJourney[]}
   */
   excludeHospital(state, getters) {
    return (journeys: RecipientJourney[], hospitalId: string): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        return journey?.transplant_program?.transplant_hospital_id?.$oid !== hospitalId;
      });
      return result;
    };
  },

  /**
   * Filter array of journeys to only include those for specified hospital IDs
   *
   * @param journeys array of recipient journeys to filter
   * @param hospitalIds array of transplant program hospital IDs
   * @returns {RecipientJourney[]}
   */
   filterByHospitalIds(state, getters) {
    return (journeys: RecipientJourney[], hospitalIds: string[]): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        const journeyHospitalId = journey?.transplant_program?.transplant_hospital_id?.$oid || '';
        return hospitalIds.includes(journeyHospitalId);
      });
      return result;
    };
  },

  /**
   * Filter array of journeys to only include those with specified cancellation status
   * 
   * @param journeys recipient journeys to filter
   * @param cancellationStatus boolean flag indicating whether or not journey has been cancelled
   * @returns {RecipientJourney[]}
   */
   filterByCancellationStatus(state, getters) {
    return (journeys: RecipientJourney[], cancellationStatus: boolean): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        return journey?.completed === cancellationStatus;
      });
      return result;
    };
  },

  /**
   * Filter array of journeys to only include those with specified organ code
   * 
   * @param journeys recipient journeys to filter
   * @param organCode numeric organ code
   * @returns {RecipientJourney[]}
   */
   filterByOrgan(state, getters) {
    return (journeys: RecipientJourney[], organCode: number): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        return journey?.organ_code === organCode;
      });
      return result;
    };
  },

  /**
   * Filter array of journeys to only include those in specified phase
   *
   * @param journeys recipient journeys to filter
   * @param phase journey phase
   * @returns {RecipientJourney[]}
   */
   filterByPhase(state, getters, rootState, rootGetters) {
    return (journeys: RecipientJourney[], phase: string): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        const journeyPhase = getters.journeyPhase(journey);
        return journeyPhase.toLowerCase() === phase.toLowerCase();
      });
      return result;
    };
  },

  /**
   * Filter array of journeys to only include those in clusters containing all specified organ codes
   *
   * Note: assumes specified journeys and all of their related journeys are for the selected recipient
   * 
   * @param journeys recipient journeys to filter
   * @param organCodes array of numeric organ codes
   * @returns {RecipientJourney[]}
   */
   filterByClusterOrgans(state, getters) {
    return (journeys: RecipientJourney[], organCodes: number[]): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        const journeyClusterOrganCodes = getters.clusterOrganCodes(journey);
        let hasEveryOrgan = true;
        organCodes.forEach((organCode: number) => {
          hasEveryOrgan = hasEveryOrgan && journeyClusterOrganCodes.includes(organCode);
        });
        return hasEveryOrgan;
      });
      return result;
    };
  },

  /**
   * Filter array of journeys to only include those in clusters where every journey is in specified phase
   *
   * Note: assumes specified journeys and all of their related journeys are for the selected recipient
   *
   * @param journeys recipient journeys to filter
   * @param phase journey phase
   * @returns {RecipientJourney[]}
   */
   filterByClusterPhases(state, getters, rootState, rootGetters) {
    return (journeys: RecipientJourney[], phase: string): RecipientJourney[] => {
      const result = journeys.filter((journey: RecipientJourney) => {
        const clusterPhases = getters.clusterPhases(journey);
        let areAllJourneysInPhase = true;
        clusterPhases.forEach((clusterPhase: string) => {
          areAllJourneysInPhase = areAllJourneysInPhase && clusterPhase.toLowerCase() === phase.toLowerCase();
        });
        return areAllJourneysInPhase;
      });
      return result;
    };
  },

  // List of journeys selected journey is Referred With plus the selected journey itself
  referredWithJourneysIncludingSelf(state, getters, rootState, rootGetters): RecipientJourney[] {
    if (!rootState.recipients?.selectedRecipient) return [];

    const journeys = rootState.recipients?.selectedRecipient?.journeys || [];
    return journeys.filter((journey: RecipientJourney) => {
      const referralNumber = journey.stage_attributes?.referral?.referral_number;
      return referralNumber && referralNumber === state.selectedJourney?.stage_attributes?.referral?.referral_number;
    });
  },

  // List of journeys selected journey is Referred With
  referredWithJourneys(state, getters, rootState, rootGetters): RecipientJourney[] {
    const journeys = getters.referredWithJourneysIncludingSelf || [];
    return journeys.filter((journey: RecipientJourney) => {
      return journey._id?.$oid !== getters.journeyId;
    });
  },

  // Is selected journey Referred With any other journeys?
  isReferredWith(state, getters, rootState, rootGetters): boolean {
    const journeys = getters.referredWithJourneys || [];
    return journeys.length > 0;
  },

  /**
   * Generate options for the selected journey's 'Referred With' dropdown,
   * based on the full list of the selected recipient's journeys
   *
   * @returns {ReferredWithOption[]}
   */
  referredWithOptions(state, getters, rootState, rootGetters) {
    return (transplantProgram: string): ReferredWithOption[] => {

      if (!rootState.recipients?.selectedRecipient) return [];

      // Fetch all journeys from selected recipient
      const journeys = rootState.recipients?.selectedRecipient?.journeys || [];

      // Filter out Out-of-Province previous transplants
      const inProvinceJourneys = journeys.filter((journey: RecipientJourney) => {
        return !journey.oop_journey;
      });

      // Filter only journeys that are the same transplant program: "Match the Transplant. program of the current journey."
      const sameTransplantProgram = inProvinceJourneys.filter((journey: RecipientJourney) => {
        return journey.transplant_program?.transplant_hospital_id?.$oid == transplantProgram;
      });

      // Filter out Completed/Cancelled journeys (unless already Referred With selected journey itself)
      const selectedJourneyReferralNumber = state.selectedJourney?.stage_attributes?.referral?.referral_number;
      const filtered = sameTransplantProgram.filter((journey: RecipientJourney) => {
        return !journey.completed || journey.stage_attributes?.referral?.referral_number == selectedJourneyReferralNumber;
      });

      // Aggregate 'referral packages' i.e. sets of journeys with same referral_number
      const referralPackages: { [key: string]: { journeys: RecipientJourney[] }} = {};
      filtered.forEach((journey: RecipientJourney) => {
        const referralNumberKey = `${journey.stage_attributes?.referral?.referral_number}`;

        // Skip journeys that user does not have permission to view referral number
        if (isMasked(referralNumberKey)) return;

        const referralPackage = Object.assign({}, referralPackages[referralNumberKey] || { journeys: [] });
        referralPackage.journeys.push((journey));
        referralPackages[referralNumberKey] = referralPackage;
      });

      // Generate selectable journey option for each referral package
      const options: ReferredWithOption[] = Object.entries(referralPackages).map((referralPackage: [string, { journeys: RecipientJourney[]; }]): ReferredWithOption => {
        // Parse referral number from string key
        const code: number = parseInt(referralPackage[0]);

        // Generate user-facing text value based on all journeys in referral package
        const organCodes = referralPackage[1].journeys.map((packageJourney: RecipientJourney): number => {
          return packageJourney.organ_code || 0;
        });
        organCodes.sort();
        const organNames: string[] = organCodes.map((code: number): string => {
          return rootGetters['lookups/organName'](code);
        });
        const value = organNames.join(' / ');

        // Return referral number code, display text value, and referral package journeys
        return { code, value, journeys };
      });

      // Remove self-option unless Referred With
      let result: ReferredWithOption[];
      if (getters.isReferredWith) {
        result = options;
      } else {
        result = options.filter((option: ReferredWithOption) => {
          return option.code !== selectedJourneyReferralNumber;
        });
      }

      return result;
    };
  },

  /**
   * Identify which of a recipient's journeys are valid 'Transfer To' options for Transfer Listing
   *
   * @returns {RecipientJourney[]}
   */
  transferDestinationJourneys(state, getters, rootState, rootGetters) {
    return (recipient: Recipient, originatingJourney: RecipientJourney): RecipientJourney[] => {
      // Fetch all journeys from specified recipient
      const recipientJourneys = recipient?.journeys || [];
  
      // Must NOT match originating journey's transplant program / hospital
      const hospitalId = originatingJourney?.transplant_program?.transplant_hospital_id?.$oid || '';
      const filteredByHospital = getters.excludeHospital(recipientJourneys, hospitalId);
  
      // Must not be cancelled
      const filteredByCancellation = getters.filterByCancellationStatus(filteredByHospital, false);
  
      // Must match originating journey's organ
      const organCode = originatingJourney?.organ_code || 0;
      const filteredByOrgan = getters.filterByOrgan(filteredByCancellation, organCode);

      // Filtering for organ and phase depends on whether or not originating journey is clustered
      const isFromJourneyClustered = getters.isClusteredByJourney(originatingJourney);
      let filtered: RecipientJourney[];
      if (isFromJourneyClustered) {
        // 'From journey' is clustered
        // If clustered, must include all organs in the current cluster (may include additional organs as well)
        const organCodes = getters.clusterOrganCodes(originatingJourney);
        const filteredByClusterOrgans = getters.filterByClusterOrgans(filteredByOrgan, organCodes);

        // If clustered, all related journeys must be in Assessment phase
        filtered = getters.filterByClusterPhases(filteredByClusterOrgans, JourneyStage.Assessment);
      } else {
        // 'From journey' is non-clustered journey
        // Must be in Assessment phase
        filtered = getters.filterByPhase(filteredByOrgan, JourneyStage.Assessment);
      }

      return filtered;
    };
  },

  /**
   * Return a string representation of all organs in specified journey's cluster
   *
   * Note: if not clustered, this will describe single journey's organ
   *
   * @param journey recipient journey to describe
   * @returns {string}
   */
  describeClusterOrJourney(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): string => {
      const journeys = getters.relatedJourneysIncludingSelf(journey);
      const organCodes = journeys.map((relatedJourney: RecipientJourney): number => {
        return relatedJourney?.organ_code || 0;
      });
      organCodes.sort();
      const organNames: string[] = organCodes.map((code: number): string => {
        return rootGetters['lookups/organName'](code);
      });
      return organNames.join(' / ');
    };
  },

  /**
   * Return a dropdown option for one specified recipient journey
   * 
   * @param journey recipient journey to build an option for
   * @param showHospital set to true to include hospital abbreviation in brackets
   * @returns {JourneyOption}
   */
  buildJourneyOption(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney, showHospital = false): JourneyOption => {
      // First describe the organ, including clustered organs if there are any
      const code = journey._id?.$oid || '';
      const organDescription = getters.describeClusterOrJourney(journey);

      // If not showing journey hospital, then use the organ description string as the value.
      if (!showHospital) return { code, value: organDescription, journey };

      // When showing journey hospital, we also append its transplant program identifier in brackets.
      // Note: the 'program_identifier' abbreviation is preferred for hospital transplant program.
      const hospitalId = journey?.transplant_program?.transplant_hospital_id?.$oid;
      const hospitalAbbreviation = rootGetters['hospitals/getHospitalAbbreviation'](hospitalId);
      const value = `${organDescription} (${hospitalAbbreviation})`;
      return { code, value, journey };
    };
  },

  /**
   * Returns list of options for specified recipient journeys
   * 
   * @param journeys recipient journeys to build options for
   * @param showHospital set to true to include hospital abbreviation in brackets
   * @returns {JourneyOption[]}
   */
  buildJourneyOptions(state, getters) {
    return (journeys: RecipientJourney[], showHospital = false): JourneyOption[] => {
      const options = journeys.map((journey: RecipientJourney): JourneyOption => {
        // Map each journey in the input array to an option object
        return getters.buildJourneyOption(journey, showHospital);
      });
      return options;
    };
  },

  /**
   * Provide a string representation of an existing Transfer Status object, to be used as read-only
   * display text for an existing 'Transferred From' or 'Transferred To' value. E.g. "Liver (TGH)"
   * for a liver journey transferred from the Toronto General Hospital transplant program.
   *
   * Note: assumes transfer status journeys and all of their related journeys are for the selected recipient
   *
   * @param transferStatus transfer status object to describe
   * @returns {string} organ/program summary string for read-only Transferred From / To
   */
   describeTransferStatus(state, getters, rootState, rootGetters) {
    return (transferStatus: RecipientJourneyTransferStatus,journey_id: string): string => {
      // Find the journey
      // Journey_id is used if provided, because in some scenarios we are not able to access other_program_journey_id
      const journeyId = journey_id ? journey_id : transferStatus.other_program_journey_id?.$oid;
      const journeys = rootState?.recipients?.selectedRecipient?.journeys || [];
      const journey = journeys.find((journey: RecipientJourney) => {
        return journey._id?.$oid == journeyId;
      });
      // First describe the organ, including clustered organs if there are any
      const organDescription = getters.describeClusterOrJourney(journey);

      // Append hospital transplant program 'program_identifier' in brackets.
      const hospitalAbbreviation = transferStatus.program_identifier;
      const result = `${organDescription} (${hospitalAbbreviation})`;
      return result;
    };
  },

  /**
   * Returns convenience function for filtering journey transfer statuses.
   *
   * @returns {(types: string[]) => RecipientJourneyTransferStatus[]} filtering function
   */
  transferStatusesByTypes(state, getters, rootState, rootGetters) {
    /**
     * @param types array of strings representing transfer status types to include
     * @returns {RecipientJourneyTransferStatus[]} filtered transfer statuses
     */
    return (types: string[]): RecipientJourneyTransferStatus[] => {
      if (!state.selectedJourney) return [];
      const unfiltered = state.selectedJourney.transfer_statuses || [];
      const filtered = unfiltered.filter((item: RecipientJourneyTransferStatus) => {
        return item.type && types.includes(item.type);
      }); 
      return filtered;
    };
  },

  // Whether or not selected journey has at least one Active/Cluster transfer status
  hasActiveTransfer(state, getters, rootState, rootGetters): boolean {
    const postTransplantTransferStatuses = getters.transferStatusesByTypes(TRANSFER_TYPES_CONSIDERED_ACTIVE_TRANSFER);
    return postTransplantTransferStatuses.length > 0;
  },

  // Get most recent Active/Cluster transfer status object
  // Note: API has already pre-sorted the contents of the 'transfer_statuses' array
  mostRecentActiveTransfer(state, getters, rootState, rootGetters): RecipientJourneyTransferStatus|null {
    if (!state.selectedJourney || !state.selectedJourney.transfer_statuses || state.selectedJourney.transfer_statuses.length === 0 ) return null;

    const filtered = getters.transferStatusesByTypes(TRANSFER_TYPES_CONSIDERED_ACTIVE_TRANSFER);
    const mostRecent = filtered.length > 0 ? filtered[filtered.length - 1] : null;
    return mostRecent;
  },

  // Whether or not selected journey has at least one Post-Transplant Follow-Up transfer status
  hasPostTransplantFollowUpTransfer(state, getters, rootState, rootGetters): boolean {
    const postTransplantTransferStatuses = getters.transferStatusesByTypes([TransferType.PostTransplant]);
    return postTransplantTransferStatuses.length > 0;
  },

  // Get most recent Transfer Status object with Post-Transplant type
  // Note: API has already pre-sorted the contents of the 'transfer_statuses' array
  mostRecentFollowUpTransfer(state, getters, rootState, rootGetters): RecipientJourneyTransferStatus|null {
    if (!state.selectedJourney || !state.selectedJourney.transfer_statuses || state.selectedJourney.transfer_statuses.length === 0 ) return null;

    const filtered = getters.transferStatusesByTypes([TransferType.PostTransplant]);
    const mostRecent = filtered.length > 0 ? filtered[filtered.length - 1] : null;
    return mostRecent;
  },

  // Date of the most recent Transfer Status object with Post-Transplant type
  mostRecentFollowUpTransferDate(state, getters, rootState, rootGetters): string|null {
    return getters.mostRecentFollowUpTransfer?.status_changed_date || null;
  },

  // Fetch most recent Follow-Up Hospital if there is one, otherwise the Transplant Program Hospital
  currentFollowUpHospitalId(state, getters, rootState, rootGetters): string|null {
    if (!state.selectedJourney) return null;

    // If no post-transplant transfers, then check journey level Transplant Program sub-document
    if (!getters.hasPostTransplantFollowUpTransfer) {
      return state.selectedJourney?.transplant_program?.transplant_hospital_id?.$oid || null;
    }

    // Get hospital ID from most recent post-transplant transfer status object
    return getters.mostRecentFollowUpTransfer?.hospital_id?.$oid || null;
  },

  // Fetch most recent Follow-Up Coordinator if there is one, otherwise the Transplant Program Coordinator
  currentFollowUpCoordinatorId(state, getters, rootState, rootGetters): string|null {
    if (!state.selectedJourney) return null;

    // If no post-transplant transfers, then check journey level Transplant Program sub-document
    if (!getters.hasPostTransplantFollowUpTransfer) {
      return state.selectedJourney?.transplant_program?.transplant_coordinator_id?.$oid || null;
    }

    // Get hospital ID from most recent post-transplant transfer status object
    return getters.mostRecentFollowUpTransfer?.coordinator_id?.$oid || null;
  },

  referralDecisions(state, getters, rootState, rootGetters): ReferralDecision[] {
    // Fetch all Decision Events associated with the recipient
    const recipientDecisionEvents = rootGetters['recipients/decisionEvents'];
    if (!recipientDecisionEvents || !state.selectedJourney ) {
      return [];
    }
    // Filter events by Journey ID and stage
    const selectedJourneyId = state.selectedJourney._id!.$oid;
    const stageName = 'referral';
    const referralDecisionEvents = recipientDecisionEvents.filter((event: SignificantEventDecision) => {
      const matchJourney = event.journey_id.$oid === selectedJourneyId;
      const matchStage = event.stage === stageName;
      return matchJourney && matchStage;
    });
    // Map events to decision type
    const referralDecisions = referralDecisionEvents.map((event: SignificantEventDecision): ReferralDecision => {
      const recipientCoordinator = event.decision_details.coordinator_id == undefined ? undefined : event.decision_details.coordinator_id.$oid;
      const decision = {
        _id: event._id,
        referralDecisionDate: event.event_date,
        recipientCoordinator,
        referralDecisionCode: event.decision_code,
        referralDecisionReasonCode: event.decision_details.reason_code,
        referralComments: event.decision_details.comments,
      };
      return decision;
    });
    return referralDecisions;
  },
  assessmentDecisions(state, getters, rootState, rootGetters): AssessmentDecision[] {
    // Fetch all Decision Events associated with the recipient
    const recipientDecisionEvents = rootGetters['recipients/decisionEvents'];
    if (!recipientDecisionEvents || !state.selectedJourney ) {
      return [];
    }
    // Filter events by Journey ID and stage
    const selectedJourneyId = state.selectedJourney._id!.$oid;
    const stageName = 'assessment';
    const decisionType = 'medical-assessment';
    const assessmentDecisionEvents = recipientDecisionEvents.filter((event: SignificantEventDecision) => {
      const matchJourney = event.journey_id.$oid === selectedJourneyId;
      const matchStage = event.stage === stageName;
      const matchType = event.decision_type === decisionType;
      // Exclude the 'add to waitlist' event, which despite being made during assessment is shown as 'waitlist' to users
      const isAddToWaitlistDecision = event.decision_code === 112; // ASSESSMENT_DECISION_CODE_WAITLIST_RECIPIENT;
      return matchJourney && matchStage && matchType && !isAddToWaitlistDecision;
    });
    const assessmentDecisions = assessmentDecisionEvents.map((event: SignificantEventDecision): AssessmentDecision => {
      const recipientCoordinator = event.decision_details.coordinator_id == undefined ? undefined : event.decision_details.coordinator_id.$oid;
      const decision = {
        _id: event._id,
        assessmentDate: event.event_date,
        recipientCoordinator,
        assessmentDecisionCode: event.decision_code,
        assessmentDecisionReasonCode: event.decision_details.reason_code,
        reasonFurtherTreatmentOther: event.decision_details.other_reason,
        absoluteContraindicationCode: event.decision_details.absolute_contraindication_code,
        relativeContraindicationCode: event.decision_details.relative_contraindication_code,
        assessmentComments: event.decision_details.comments,
      };
      return decision;
    });
    return assessmentDecisions;
  },
  consultationDecisions(state, getters, rootState, rootGetters): AssessmentDecision[] {
    // Fetch all Decision Events associated with the recipient
    const recipientDecisionEvents = rootGetters['recipients/decisionEvents'];
    if (!recipientDecisionEvents || !state.selectedJourney ) {
      return [];
    }
    // Filter events by Journey ID and stage
    const selectedJourneyId = state.selectedJourney._id!.$oid;
    const stageName = 'assessment';
    const decisionType = 'consultation';
    const consultationDecisionEvents = recipientDecisionEvents.filter((event: SignificantEventDecision) => {
      const matchJourney = event.journey_id.$oid === selectedJourneyId;
      const matchStage = event.stage === stageName;
      const matchType = event.decision_type === decisionType;
      return matchJourney && matchStage && matchType;
    });
    const consultationDecisions = consultationDecisionEvents.map((event: SignificantEventDecision): AssessmentDecision => {
      const recipientCoordinator = event.decision_details.coordinator_id == undefined ? undefined : event.decision_details.coordinator_id.$oid;
      const primaryPhysician = event.decision_details.primary_physician_id == undefined ? undefined : event.decision_details.primary_physician_id.$oid;

      const decision = {
        _id: event._id,
        consultationDate: event.event_date,
        recipientCoordinator,
        consultationDecisionCode: event.decision_code,
        consultationDecisionReasonCode: event.decision_details.reason_code,
        consultationDelayEndDate: event.decision_details.delay_end_date,
        consultationDelayStartDate: event.decision_details.delay_start_date,
        primaryPhysician: primaryPhysician,
        consultationComments: event.decision_details.comments,
      };
      return decision;
    });
    return consultationDecisions;
  },
  /**
   * Returns whether or not this journey already has a 'final' Referral Decision
   * 
   * Decisions that stop the journey and decisions that advance the journey to the next stage are both considered to be
   * "final". For robustness we check all referral decisions logged for this journey, but typically only the last
   * referral decision would have a decision code whose lookup entry has "final: true".
   * 
   * @returns {boolean} true if any of the journey's referral decisions have final: true
   */
  hasFinalReferral(state, getters, rootState, rootGetters): boolean {
    // Get relevant data from this module as well as others
    const decisions: ReferralDecision[] = getters.referralDecisions;
    const lookupsModule: LookupsState|null = rootState.lookups || null;
    const decisionLookups = lookupsModule ? lookupsModule.recipient_referral_decision : null;
    // Return false until all necessary data is available
    if (!decisions || !lookupsModule || !decisionLookups) {
      return false;
    }
    // Fetch decision codes that have been logged for this journey in recipient events
    const decisionCodes = decisions.map((decision: ReferralDecision): number|null => {
      return decision.referralDecisionCode || null;
    });
    // Filter lookups to only include 'final' entries that also have codes found in these recipient events
    const finalDecisions = decisionLookups.filter((lookup: RecipientReferralDecision) => {
      return lookup.final && decisionCodes.includes(lookup.code);
    });
    // Return true if at least one decision logged is 'final'
    return finalDecisions.length > 0;
  },
  /**
   * Returns whether or not this journey already has a 'final' Assessment Decision
   * 
   * Decisions that stop the journey and decisions that advance the journey to the next stage are both considered to be
   * "final". For robustness we check all assessment decisions logged for this journey, but typically only the last
   * assessment decision would have a decision code whose lookup entry has "final: true".
   * 
   * @returns {boolean} true if any of the journey's assessment decisions have final: true
   */
  hasFinalAssessment(state, getters, rootState, rootGetters): boolean {
    // Get relevant data from this module as well as others
    const decisions: AssessmentDecision[] = getters.assessmentDecisions;
    const lookupsModule: LookupsState|null = rootState.lookups || null;
    const decisionLookups = lookupsModule ? lookupsModule.recipient_assessment_decisions : null;
    // Return false until all necessary data is available
    if (!decisions || !lookupsModule || !decisionLookups) {
      return false;
    }
    // Fetch decision codes that have been logged for this journey in recipient events
    const decisionCodes = decisions.map((decision: AssessmentDecision): number|null => {
      return decision.assessmentDecisionCode || null;
    });
    // Filter lookups to only include 'final' entries that also have codes found in these recipient events
    const finalDecisions = decisionLookups.filter((lookup: RecipientAssessmentDecision) => {
      return lookup.final && decisionCodes.includes(lookup.code);
    });
    // Return true if at least one decision logged is 'final'
    return finalDecisions.length > 0;
  },
  /**
   * Returns whether or not this journey already has a 'final' Consultation Decision
   * 
   * Decisions that stop the journey and decisions that advance the journey to the next stage are both considered to be
   * "final". For robustness we check all consultation decisions logged for this journey, but typically only the last
   * consultation decision would have a decision code whose lookup entry has "final: true".
   * 
   * @returns {boolean} true if any of the journey's consultation decisions have final: true
   */
  hasFinalConsultation(state, getters, rootState, rootGetters): boolean {
    // Get relevant data from this module as well as others
    const decisions: ConsultationDecision[] = getters.consultationDecisions;
    const lookupsModule: LookupsState|null = rootState.lookups || null;
    const decisionLookups = lookupsModule ? lookupsModule.recipient_consultation_decisions : null;
    // Return false until all necessary data is available
    if (!decisions || !lookupsModule || !decisionLookups) {
      return false;
    }
    // Fetch decision codes that have been logged for this journey in recipient events
    const decisionCodes = decisions.map((decision: ConsultationDecision): number|null => {
      return decision.consultationDecisionCode || null;
    });
    // Filter lookups to only include 'final' entries that also have codes found in these recipient events
    const finalDecisions = decisionLookups.filter((lookup: RecipientConsultationDecision) => {
      return lookup.final && decisionCodes.includes(lookup.code);
    });
    // Return true if at least one decision logged is 'final'
    return finalDecisions.length > 0;
  },
  /**
   * Returns whether or not this journey has the Referral Details information filled out.
   * 
   * @returns {boolean} true if we have their Referral Details
   */
  hasReferralDetails(state): boolean {
    const journey: RecipientJourney = state.selectedJourney || {};
    const failedOrgan = journey.failed_organ || {};
    const referralAttributes = journey.stage_attributes?.referral || {};
    const isVCA = journey.organ_code === OrganCodeValue.VCA;
    // DIAG: Disease code uses 0 for some values so convert to a string before testing the value
    const primaryDiseaseCode = (typeof failedOrgan.primary_diagnosis_code === 'number') ? 
      failedOrgan.primary_diagnosis_code.toString() : 
      failedOrgan.primary_diagnosis_code;
    return !!referralAttributes.received_date && (isVCA || !!primaryDiseaseCode);
  },
  /**
   * Returns whether or not this journey has the complete Referring Physician Details.
   * 
   * @returns {boolean} true if we have Referring Physician Details
   */
  hasReferringPhysicianDetails(state): boolean {
    const journey: RecipientJourney = state.selectedJourney || {};
    const organizationAttributes = journey.stage_attributes?.referral?.referrer?.organization || {};
    const professionalAttributes = journey.stage_attributes?.referral?.referrer?.professional || {};
    const country = organizationAttributes.country;
    return !!organizationAttributes.name
      && !!organizationAttributes.type_code
      && !!organizationAttributes.street
      && !!organizationAttributes.city
      && !!country
      // If country is other we need to check for other_country otherwise provice
      && (country === CountryValue.Other ? !!organizationAttributes.other_country : !!organizationAttributes.province)
      && !!organizationAttributes.postal_code
      && !!professionalAttributes.type_code
      && !!professionalAttributes.first_name
      && !!professionalAttributes.last_name;
  },
  // Is the journey currently placed on a waitlist medical hold?
  isOnMedicalHold(state): boolean {
    // Get journey waitlist factors
    const journey: RecipientJourney = state.selectedJourney || {};
    const attributes = journey.stage_attributes || {};
    const waitlist = attributes.waitlist || {};
    const factors = waitlist.factors || {};
    // Check if journey has waitlist medical hold
    const isOnHold = factors.on_hold_medical || false;
    return isOnHold;
  },
  // What is the most recent waitlist medical status or medical hold in the waitlist decisions list
  latestMedicalHoldOrMedicalStatus(state): WaitlistDecision|null {
    const decisions = state.waitlistDecisions || [];
    const filtered = decisions.filter((decision: WaitlistDecision) => {
      const isMedicalStatus = decision._type == RecipientSignificantEventType.Factor && decision.stage_factor_code == WaitlistFactorCodeValue.MedicalStatus;
      const isMedicalHold = decision._type == RecipientSignificantEventType.MedicalHold;
      return isMedicalStatus || isMedicalHold;
    });
    if (filtered.length === 0) return null;

    const latest: WaitlistDecision = filtered.reduce((prev: WaitlistDecision, curr: WaitlistDecision) => {
      if(prev && curr) {
        const prevDate = prev.event_date;
        const currDate = curr.event_date;
        return prevDate < currDate ? curr : prev;
      }
      return curr;
    });
    return latest;
  },
  /**
   * Return all journeys from the related_journeys
   * 
   * Related journeys is an array of string or ObjectIds so this will return
   * all the RecipientJourney objects for those id's. 
   *
   * @returns {RecipientJourney[]} RecipientJourney 
   */
  clusteredJourneys(state, getters, rootState, rootGetters): RecipientJourney[] {
    // The selectedJourney related_journeys
    const journeyRelatedJourneys = state.selectedJourney?.related_journeys || [];
    // If no related journeys
    if (journeyRelatedJourneys.length === 0) {
      return [];
    }
    const relatedJourneys: RecipientJourney[] = [];
    // The selectedRecipient journeys
    const recipientJourneys = rootState.recipients?.selectedRecipient?.journeys || [];
    // For each journey id get the journey
    journeyRelatedJourneys.forEach((jid: string|ObjectId) => {
      // DIAG: TECH_DEBT Migrated recipients could have their related_journeys as string[]
      // this was fixed on API but we are checking here for either string[] or ObjectId[]
      const journeyId = (typeof jid === 'string') ? jid : jid.$oid;
      // Find the related journey
      const journey = recipientJourneys.find((journey: RecipientJourney) => {
        return journey._id?.$oid === journeyId;
      });
      if (journey) { relatedJourneys.push(journey); }
    });
    return relatedJourneys;
  },

  /**
   * Return array containing specified journey and any related journeys it is clustered with
   *
   * Note: assumes specified journey and all of its related journeys are for the selected recipient
   *
   * @param journey recipient journey to get related journeys for
   * @returns {RecipientJourney[]}
   */
  relatedJourneysIncludingSelf(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): RecipientJourney[] => {
      // Start the array with at least the specified journey itself
      const result = [journey];

      // Find related journeys in the the selected recipient's list of journeys
      const recipientJourneys = rootState.recipients?.selectedRecipient?.journeys || [];

      // Get journey for each related journey ID
      const journeyIds = journey?.related_journeys || [];
      journeyIds.forEach((jid: string|ObjectId) => {
        // DIAG: TECH_DEBT Migrated recipients could have their related_journeys as string[]
        // this was fixed on API but we are checking here for either string[] or ObjectId[]
        const journeyId = (typeof jid === 'string') ? jid : jid.$oid;
        // Find the related journey
        const match = recipientJourneys.find((relatedJourney: RecipientJourney) => {
          return relatedJourney._id?.$oid === journeyId;
        });
        if (match) result.push(match);
      });

      return result;
    };
  },

  /**
   * Return sorted array containing numeric organ codes found in a cluster
   *
   * Note: assumes specified journey and all of its related journeys are for the selected recipient
   *
   * @param journey clustered recipient journey to get organ codes for
   * @returns {number[]}
   */
  clusterOrganCodes(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): number[] => {
      const journeys = getters.relatedJourneysIncludingSelf(journey);
      const result = journeys.map((relatedJourney: RecipientJourney): number => {
        return relatedJourney.organ_code || 0;
      });
      result.sort();
      return result;
    };
  },

  /**
   * Return array containing all unique 'phase' values found in a cluster
   *
   * Note: assumes specified journey and all of its related journeys are for the selected recipient
   *
   * @param journey clustered recipient journey to get organ codes for
   * @returns {number[]}
   */
  clusterPhases(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): number[] => {
      const journeys = getters.relatedJourneysIncludingSelf(journey);
      const phases = journeys.map((relatedJourney: RecipientJourney): string => {
        return getters.journeyPhase(relatedJourney);
      });
      const unique = uniqueElements(phases);
      return unique;
    };
  },

  /**
   * Return all ald_program_participations for the selectedJourney
   *
   * @returns {AlcoholicLiverDiseaseProgram[]} AlcoholicLiverDiseaseProgram entries
   */
  aldProgramParticipations(state, getters, rootState, rootGetters): AlcoholicLiverDiseaseProgram[] {
    const journey = state.selectedJourney;
    // If we don't have a journey
    if (!journey) return [];

    // If there are no organ details or this isn't a liver
    if (journey.organ_specific_details == null || journey.organ_code != OrganCodeValue.Liver) return [];
    
    const liverSpecificDetails: LiverDetails = journey.organ_specific_details;
    const aldPrograms: AlcoholicLiverDiseaseProgram[] = liverSpecificDetails.ald_program_participations || [];

    // If we have no entries
    if (aldPrograms.length === 0) return [];

    return aldPrograms;
  },

  /**
   * Standard way to check if specified journey is clustered.
   *
   * @returns {boolean} true only if the journey is clustered
   */
  isClusteredByJourney(state, getters, rootState, rootGetters) {
    return (journey: RecipientJourney): boolean => {
      // Journey is clustered if 'related_journeys' array has values
      const relatedJourneys = journey?.related_journeys || [];
      return relatedJourneys.length > 0 ? true : false; 
    };
  },

  /**
   * Whether or not journey specified by given ID is clustered.
   * Note: assumes the journey exists on the selected recipient.
   *
   * @returns {boolean} true only if the journey is clustered
   */
  isClusteredById(state, getters, rootState, rootGetters) {
    return (journeyId: string): boolean => {
      // Find arbitrary journey on the selected recipient
      const recipient = rootState?.recipients?.selectedRecipient;
      const journey = (recipient?.journeys || []).find((recipientJourney: RecipientJourney) => {
        return recipientJourney?._id?.$oid === journeyId;
      });
      // Detect clustering in standard way
      return getters.isClusteredByJourney(journey);
    };
  },

  /**
   * Return boolean if the selectedJourney is part of a cluster
   * 
   * @returns {boolean} true if the journey is in a cluster 
   */
  isClustered(state, getters, rootState, rootGetters): boolean {
    return getters.isClusteredByJourney(state.selectedJourney);
  },

  /**
   * Returns a numeric representation of the selected journey's wait time in days
   * 
   * @returns {number|null} number of days, or null
   */
  waitTimeDays(state, getters, rootState, rootGetters): number|null {
    if (!state.journeyDurations) {
      return null;
    }
    const waitDays = state.journeyDurations?.waitlist?.wait_days;
    if (waitDays == null) {
      return null;
    }
    return waitDays;
  },
  /**
   * Returns a string representation of the specified waitlist attributes information
   * 
   * E.g. "Active" or "On Hold: Medical"
   * 
   * @returns {string} text description of waitlist status
   */
  waitlistStatusDescription(state, getters, rootState, rootGetters) {
    return (attributes: RecipientWaitlistAttributes): string => {
      const holdsAndSuspensions: string[] = [];
      // Check each boolean waitlist factor related to holds or suspensions
      const factors = attributes?.factors || {};
      if (factors.on_hold_initial_waitlisted) {
        holdsAndSuspensions.push(WaitlistStatusValue.OnHoldInitialWaitlisted);
      }
      if (factors.on_hold_incomplete_data) {
        holdsAndSuspensions.push(WaitlistStatusValue.OnHoldIncompleteData);
      }
      if (factors.on_hold_serum_hla_antibody) {
        holdsAndSuspensions.push(WaitlistStatusValue.OnHoldSerumHlaAntibody);
      }
      if (factors.on_hold_medical) {
        holdsAndSuspensions.push(WaitlistStatusValue.OnHoldMedical);
      }
      if (factors.suspended_medical) {
        holdsAndSuspensions.push(WaitlistStatusValue.SuspendedMedical);
      }
      if (factors.suspended_liver_sodium_meld) {
        holdsAndSuspensions.push(WaitlistStatusValue.SuspendedLiverSodumMeld);
      }
      if (factors.suspended_liver_hcc) {
        holdsAndSuspensions.push(WaitlistStatusValue.SuspendedLiverHcc);
      }
      if (factors.suspended_heart) {
        holdsAndSuspensions.push(WaitlistStatusValue.SuspendedHeart);
      }
      if (factors.on_hold_incomplete_cluster) {
        holdsAndSuspensions.push(WaitlistStatusValue.OnHoldIncompleteCluster);
      }
      if (factors.on_hold_cluster) {
        holdsAndSuspensions.push(WaitlistStatusValue.OnHoldCluster);
      }
      if (factors.suspended_cluster) {
        holdsAndSuspensions.push(WaitlistStatusValue.SuspendedCluster);
      }
      if (factors.on_hold_partial_cluster_transplant) {
        holdsAndSuspensions.push(WaitlistStatusValue.PartialClusterTransplantHold);
      }
      // If no hold or suspension was placed, then the journey must be active
      let result: string;
      if (holdsAndSuspensions.length === 0) {
        result = WaitlistStatusValue.Active;
      } else {
        // Otherwise we join the hold/suspension text descriptions together
        result = holdsAndSuspensions.join(', ');
      }
      return result;
    };
  },
  /**
   * Check if the selected journey is considered 'on the waitlist'
   * 
   * @returns {boolean} true if the journey is waitlisted, false otherwise
   */
  isWaitlisted(state, getters, rootState, rootGetters): boolean {
    const journey = state.selectedJourney;
    if (!journey) {
      return false;
    }
    const isWaitlistStage = journey.stage === JourneyStage.Waitlist;
    const isCompleted = journey.completed || false;
    return isWaitlistStage && !isCompleted;
  },
  /**  
   * Check if the selected journey was Removed from the Waitlist, whether because a
   * journey cancellation reason has been entered or if organ has been transplanted
   * 
   * @returns {boolean} true if the journey is no longer on the waitlist, false otherwise
   */
  wasRemovedFromWaitlist(state, getters, rootState, rootGetters): boolean {
    const journey = state.selectedJourney;
    if (!journey) {
      return false;
    }
    // First check for cancellation reasons provided during waitlist stage
    const isWaitlistStage = journey.stage === JourneyStage.Waitlist;
    const isCompleted = journey.completed || false;
    const wasCancelledDuringWaitlist = isWaitlistStage && isCompleted;
    if (wasCancelledDuringWaitlist) return true;
    // Otherwise check if the journey has moved on to a different stage after waitlist
    const stagesAfterWaitlist: string[] = [JourneyStage.Transplant, JourneyStage.PostTransplant];
    const wasWaitlisted = !!journey.stage && stagesAfterWaitlist.includes(journey.stage);
    return wasWaitlisted;
  },
  /**
   * Check if the selected journey was ever 'on the waitlist', is waitlisted or is currently completed
   * 
   * @returns {boolean} true if conditions match
   */
  isUrgentListingLocked(state, getters): boolean {
    return getters.isWaitlisted || getters.wasRemovedFromWaitlist || !!state.selectedJourney?.completed;
  },
  /**
   * Check if journey has been moved to a stage after waitlist i.e. transplanted
   *
   * @returns {boolean} true only if journey in transplant or post-transplant
   */
  wasTransplanted(state, getters, rootState, rootGetters): boolean {
    const journey = state.selectedJourney;
    if (!journey || !journey.stage) return false;

    const stagesAfterTransplant: string[] = [JourneyStage.Transplant, JourneyStage.PostTransplant];
    return stagesAfterTransplant.includes(journey.stage);
  },
  /**
   * Find the final Referral Decision for the selected journey:
   * - Referral Declined
   * - Referral Accepted
   * - Referral Cancelled
   * 
   * Note: this determination is based solely on the 'final' flag in the decision lookup
   * 
   * @returns {ReferralDecision|null} final Referral Decision, or null if journey does not have one
   */
  finalReferralDecision(state, getters, rootState, rootGetters): ReferralDecision|null {
    // Get information from recipient significant events associated with the selected journey
    const decisionEvents: ReferralDecision[] = getters.referralDecisions || [];
    // Derive whether an event is 'final' based on the relevant decision lookup
    const decisionLookup: RecipientReferralDecision[] = (rootState.lookups || {}).recipient_referral_decision || [];
    // Filter journey decision events down to only the 'final' decisions
    const finalDecisions: ReferralDecision[] = decisionEvents.filter((event: ReferralDecision) => {
      const lookupEntry = decisionLookup.find((lookup: RecipientReferralDecision) => {
        return lookup.code === event.referralDecisionCode;
      });
      return lookupEntry?.final || false;
    });
    // There should only be one 'final' decision, but for robustness here we choose the last one if there are multiple
    const sortedFinalDecisions = rootGetters['utilities/sortByDate'](finalDecisions, 'referralDecisionDate');
    const finalDecision = sortedFinalDecisions.length > 0 ? sortedFinalDecisions[sortedFinalDecisions.length - 1] : null;
    return finalDecision;
  },
  /**
   * Find the final Consultation Decision for the selected journey:
   * - Consultation Completed
   * - Consultation Cancelled
   * 
   * Note: this determination is based solely on the 'final' flag in the decision lookup
   * 
   * @returns {ConsultationDecision|null} final Consultation Decision, or null if journey does not have one
   */
  finalConsultationDecision(state, getters, rootState, rootGetters): ConsultationDecision|null {
    // Get information from recipient significant events associated with the selected journey
    const decisionEvents: ConsultationDecision[] = getters.consultationDecisions || [];
    // Derive whether an event is 'final' based on the relevant decision lookup
    const decisionLookup: RecipientConsultationDecision[] = (rootState.lookups || {}).recipient_consultation_decisions || [];
    // Filter journey decision events down to only the 'final' decisions
    const finalDecisions: ConsultationDecision[] = decisionEvents.filter((event: ConsultationDecision) => {
      const lookupEntry = decisionLookup.find((lookup: RecipientConsultationDecision) => {
        return lookup.code === event.consultationDecisionCode;
      });
      return lookupEntry?.final || false;
    });
    // There should only be one 'final' decision, but for robustness here we choose the last one if there are multiple
    const sortedFinalDecisions = rootGetters['utilities/sortByDate'](finalDecisions, 'consultationDate');
    const finalDecision = sortedFinalDecisions.length > 0 ? sortedFinalDecisions[sortedFinalDecisions.length - 1] : null;
    return finalDecision;
  },
  /**
   * Find the final Assessment Decision for the selected journey:
   * - Assessment Cancelled
   * - Recipient not Eligible for Transplant
   * - Recipient proceeding with living donor transplant
   * 
   * Note: this determination is based solely on the 'final' flag in the decision lookup
   * 
   * @returns {AssessmentDecision|null} final Assessment Decision, or null if journey does not have one
   */
  finalAssessmentDecision(state, getters, rootState, rootGetters): AssessmentDecision|null {
    // Get information from recipient significant events associated with the selected journey
    const decisionEvents: AssessmentDecision[] = getters.assessmentDecisions || [];
    // Derive whether an event is 'final' based on the relevant decision lookup
    const decisionLookup: RecipientAssessmentDecision[] = (rootState.lookups || {}).recipient_assessment_decisions || [];
    // Filter journey decision events down to only the 'final' decisions
    const finalDecisions: AssessmentDecision[] = decisionEvents.filter((event: AssessmentDecision) => {
      const lookupEntry = decisionLookup.find((lookup: RecipientAssessmentDecision) => {
        return lookup.code === event.assessmentDecisionCode;
      });
      return lookupEntry?.final || false;
    });
    // There should only be one 'final' decision, but for robustness here we choose the last one if there are multiple
    const sortedFinalDecisions = rootGetters['utilities/sortByDate'](finalDecisions, 'assessmentDate');
    const finalDecision = sortedFinalDecisions.length > 0 ? sortedFinalDecisions[sortedFinalDecisions.length - 1] : null;
    return finalDecision;
  },
  /**
   * Calculate total number of days journey was waiting for completion of Referral
   * 
   * (Referral Decision Date - Referral Received Date)
   * where Referral Decision = Referral Declined, Referral Accepted, or Referral Cancelled
   * else
   * (Current Date - Referral Received Date)
   * 
   * @returns {number|null} number of referral wait days, or null if not applicable to journey
   */
  referralWaitDays(state, getters, rootState, rootGetters): number|null {
    // Get information directly from the selected journey  
    const referralReceivedDate: string|null = state.selectedJourney?.stage_attributes?.referral?.received_date || null;
    // Get information from the journey's decision events
    const referralDecision: ReferralDecision|null = getters.finalReferralDecision || null;
    // Determine the opening and closing dates for the calculation
    const openingDate = referralReceivedDate ? new Date(referralReceivedDate) : new Date();
    const closingDate = referralDecision && referralDecision.referralDecisionDate ? new Date(referralDecision.referralDecisionDate) : new Date();
    // Return null if we don't have enough information for the calculation
    if (!openingDate) {
      return null;
    }
    // Calculate the number of days between the events
    const days = rootGetters['utilities/daysBetweenDates'](openingDate, closingDate);
    return days;
  },
  /**
   * Calculate total number of days journey was waiting for completion of Consultation
   * 
   * (Consultation Decision Date - Referral Accepted Date)
   * where Consultation Decision = Consultation Completed, Consultation Cancelled
   * else
   * (Current Date - Referral Accepted Date)
   * 
   * @returns {number|null} number of consultation wait days, or null if not applicable to journey
   */
  consultationWaitDays(state, getters, rootState, rootGetters): number|null {
    // Get information from the journey's decision events
    const referralDecision: ReferralDecision|null = getters.finalReferralDecision || null;
    const consultationDecision: ConsultationDecision|null = getters.finalConsultationDecision || null;
    // Determine the opening and closing dates for the calculation
    const openingDate = referralDecision && referralDecision.referralDecisionDate ? new Date(referralDecision.referralDecisionDate) : new Date();
    const closingDate = consultationDecision && consultationDecision.consultationDate ? new Date(consultationDecision.consultationDate) : new Date();
    // Return null if we don't have enough information for the calculation
    if (!openingDate) {
      return null;
    }
    // Calculate the number of days between the events
    const days = rootGetters['utilities/daysBetweenDates'](openingDate, closingDate);
    return days;
  },
  /**
   * Calculate total number of days journey was waiting for completion of Medical Assessment
   * 
   * (Added Date - Referral Accepted Date)
   * else
   * (Assessment Decision Date - Referral Accepted Date)
   * where Assessment Decision = Assessment Cancelled, Recipient not Eligible for Transplant, or Recipient proceeding with living donor transplant
   * else
   * (Current Date - Referral Accepted Date)
   * 
   * Note: in the case of a cluster, we will continue to use the individual journey added_date, and not the cluster listing_date
   * 
   * @returns {number|null} number of assessment wait days, or null if not applicable to journey
   */
  assessmentWaitDays(state, getters, rootState, rootGetters): number|null {
    // Get information directly from the selected journey  
    const addedDate: string|null = state.selectedJourney?.stage_attributes?.waitlist?.factors?.added_date || null;
    // Get information from the journey's decision events
    const referralDecision: ReferralDecision|null = getters.finalReferralDecision || null;
    const assessmentDecision: AssessmentDecision|null = getters.finalAssessmentDecision || null;
    // Determine the opening and closing dates for the calculation
    const openingDate = referralDecision && referralDecision.referralDecisionDate ? new Date(referralDecision.referralDecisionDate) : new Date();
    let closingDate: Date|null = null;
    if (addedDate) {
      closingDate = new Date(addedDate);
    } else if (assessmentDecision && assessmentDecision.assessmentDate) {
      closingDate = new Date(assessmentDecision.assessmentDate);
    } else {
      closingDate = new Date();
    }
    // Return null if we don't have enough information for the calculation
    if (!openingDate) {
      return null;
    }
    // Calculate the number of days between the events
    const days = rootGetters['utilities/daysBetweenDates'](openingDate, closingDate);
    return days;
  },
  isPediatricKidney(state, getters, rootState, rootGetters): boolean {
    const recipientAge = rootGetters['recipients/recipientAge'];
    
    return recipientAge < 18 ? true : false;
  },
  /**
   * Whether or not selected journey is a Heart journey and/or a cluster
   * that includes a Heart journey.
   *
   * Note: this is used to determine whether or not to prompt the user to
   * acknowledge that placing a medical hold will automatically cause the
   * journey/cluster to be suspended.
   *
   * @returns {boolean} true if selected journey is heart or heart cluster, false otherwise
   */
  isHeartJourneyOrHeartCluster(state, getters, rootState, rootGetters): boolean {
    if (!state.selectedJourney) return false;

    const organCode = state.selectedJourney.organ_code;
    const isHeartJourney = organCode === OrganCodeValue.Heart;
    if (isHeartJourney) return true;

    const clusteredJourneys = getters.clusteredJourneys || [];
    const clusteredHeartJourney = clusteredJourneys.find((journey: RecipientJourney ) => {
      return journey.organ_code === OrganCodeValue.Heart;
    });
    return !!clusteredHeartJourney;
  },

  isTransplantDetailsApplicable(state): boolean {
    const transplantDetails = state.transplantDetails;
    const journey = state.selectedJourney;

    if (!journey || !journey.stage || !transplantDetails) return false;

    const stagesAfterTransplant: string[] = [JourneyStage.Transplant, JourneyStage.PostTransplant];
    const  isTransplanted = stagesAfterTransplant.includes(journey.stage);
    const isTransplantDetailsApplicable = !!transplantDetails.transplant_details_applicable;

    return isTransplantDetailsApplicable || isTransplanted;
  },

  allocatedDonorDetails(state): AllocatedDonorDetails[] {
    const transplantDetails = state.transplantDetails;
    const details:AllocatedDonorDetails[] = [];
    if(transplantDetails?.allocated_donor_id || transplantDetails?.deceased_donor_id || transplantDetails?.living_donor_id) {
      details.push({
        code: transplantDetails.allocated_donor_id || '',
        value: transplantDetails.deceased_donor_id || transplantDetails.living_donor_id || '',
        donor_client_id: transplantDetails.allocated_donor_client_id || ''
      });
    }
    return details;
  },

  noAllocatedDonorIdOptionsText(state,getters){
    return getters.allocatedDonorDetails.length>0 ? "Select..." : "No Allocated Donors";
  },
};
