
import { mixins } from "vue-class-component";
import { DateUtilsMixin } from "@/mixins/date-utils-mixin";
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { GenericCodeValue } from '@/store/types';
import { SaveResult } from '@/types';
import SaveToolbar from '@/components/shared/SaveToolbar.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import DateInput from "@/components/shared/DateInput.vue";
import SubSection from '@/components/shared/SubSection.vue';
import { ChangeStatusReportState } from '@/store/reports/types';
import { Organ, OrganCodeValue } from '@/store/lookups/types';
import { Hospital, ACTIVE_ONTARIO_TRANSPLANT_PROGRAM, ACTIVE_OOP_TRANSPLANT_PROGRAM } from '@/store/hospitals/types';
import { User } from '@/store/users/types';

@Component({
  components: {
    SaveToolbar,
    SelectInput,
    DateInput,
    SubSection
  }
})

export default class ChangeStatusReport extends mixins(DateUtilsMixin) {
  // State
  @State(state => state.reports.ChangeStatusReportState) editState!: ChangeStatusReportState;
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.hospitals.all) hospitals!: Hospital[];
  @State(state => state.users.user) user!: User;

  @Getter('activeOntarioTransplantOopHospitalOptions', { namespace: 'hospitals' }) hospitalOptions!: GenericCodeValue[];
  @Getter('allOptionsPlaceholderValue', { namespace: 'reports' }) allOptionsPlaceholderValue!: (state: any) => string;

  get defaultDate(): string|undefined|null {
    return this.currentDateUi();
  }

  public mounted(): void {
    this.$store.dispatch('hospitals/load').then(() => {
      this.$store.commit('reports/setChangeStatusReport', {
        organCode: null,
        hospital: null,
        startDate: null,
        endDate: this.defaultDate
      });
    });
  }

  public allOptionsPlaceholderText(state: any) {
    return this.allOptionsPlaceholderValue(state);
  }

  // pass lookup options to get all options code
  public defaultAllOptions(options: any) {
    return options.map((option: any) => {
      return option.code;
    });
  }

  public buildReportQuery(): ChangeStatusReportState {
    if (!this.editState) {
      return {};
    }
    const organ_codes = (this.editState.organCode || []).length === 0 ? this.defaultAllOptions(this.organOptions) : this.editState.organCode;
    const organ_options = (organ_codes || []).map((organ_code: number) => organ_code.toString());

    const result = {
      organ_type: organ_options,
      hospital: (this.editState.hospital || []).length === 0 ? this.defaultAllOptions(this.filteredHospitalsByUser) : this.editState.hospital,
      start_date: this.editState.startDate,
      end_date: this.editState.endDate
    };
    return result;
  }

   /**
   * Returns an array of options for organs
   *
   * @returns {Organ[]} organ options
   */
  get organOptions(): { code: number; value: string }[] {
    const filterBy: { [key: string]: any } = this.user.hospital_organ_codes || {};
    const userOrganCodes = Object.keys(filterBy).map((hospital: string) => filterBy[hospital]).flat();
    const nonClusteredOrgans = this.organLookup.filter((organ: Organ) => {
      return organ.type != 'cluster' && organ.code != OrganCodeValue.VesselsForBanking;
    });

    if(userOrganCodes.length === 0) {
      return nonClusteredOrgans;
    }

    return nonClusteredOrgans.filter((organ) => userOrganCodes.includes(organ.code));
  }

  /**
   * If user.hospital_organ_codes is empty then returns all hospitals from filteredHospitals()
   * otherwise filters the hospitals based on the hospital_id keys in user.hospital_organ_codes
   *
   * @returns {Organ[]} filtered list of ontario transplant programs and oop transplant programs by user
   */
  get filteredHospitalsByUser(): GenericCodeValue[]|undefined {
    const hospitals = this.hospitalOptions || [];
    const filterBy = this.user.hospital_organ_codes || {};
    
    if (Object.keys(filterBy).length === 0 || this.user.all_hospitals) {
      // if there are no user hospital organ codes then return all hospitals
      return hospitals;
    } else {
      // get all hospital keys from user.hospital_organ_codes and filter hospitals by the hospital_id key
      return hospitals.filter((hospital: any) => {
        return Object.keys(filterBy).includes(hospital.code);
      });
    }
  }

  public generateReport(): void {
    this.clear();
    const saveToolbar = this.$refs.generateReport as SaveToolbar;
    saveToolbar.startSaving();

    // Submit query
    this.$store.dispatch('reports/printChangeStatusReport', this.buildReportQuery()).then((result: SaveResult) => {
      // Get the filename
      const fileName = result.responseData.report;

      // Is there actually a filename
      if (!!fileName) {
        // Create a link
        const link = document.createElement('a');
        link.href = fileName;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        // Then click it forcing a save/open dialogue
        link.click();
        // Show success notification
      }
      saveToolbar.stopSaving(result);

    }).catch((error: SaveResult) => {
      // Emit event to handle errors
      this.handleErrors(error);
      saveToolbar.stopSaving(error);
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup: {[key: string]: string} = {
    'start_date': 'change-status-report-start-date',
    'end_date': 'change-status-report-end-date',
    'organ_type': 'changeStatusReport-organ_type',
    'hospital': 'changeStatusReport-hospital'
  }

  // Parse and highlight errors from api response
  private handleErrors(errors: SaveResult[]|SaveResult): void {
    const idLookup = this.idLookup;

    let aggregateErrors: {[key: string]: []} = {};
    errors = Array.isArray(errors) ? errors : [errors];
    errors.forEach((item: {[key: string]: any }) => {
      Object.keys(item).forEach((key) => aggregateErrors[key] = item[key]);
    });
    const formErrors: {[key: string]: []} = {};

    for (const key of Object.keys(aggregateErrors)) {
      formErrors[idLookup[key]] = aggregateErrors[key];
    }
    (this.$refs.validations as any).setErrors(formErrors);
  }

  // Emit event to parent so it can clear validations
  private clear() {
    this.$emit('clear');
  }
}
