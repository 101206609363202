import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { Hospital } from '@/store/hospitals/types';
import { TranslationContext, CtrErrorContext, CTR_ERROR_MESSAGE_PARSERS, UNKNOWN } from '@/types';
import { AllocationResponse, AllocationState, AllocationChecklistResponse, AllocationChecklistDetails, AllocationChecklistForm, Allocation, Allocations, AllocationOffer, AllocationRecipient, AllocationOfferTypeValues, DonorDetails, RecipientDetails, AllocationOfferResponseCodeValues, AllocationOfferRecipient, RANKING_CATEGORY_HSP, RANKING_CATEGORY_HSH, SYSTEM_ONLY_ALLOCATION_STATES, CTR_HSP_ORGAN_CODES, CTR_HSH_ORGAN_CODES, InterProvincialOrganSharingProgram, RESPONSE_CODES_CONSIDERED_TO_BE_OPEN } from '@/store/allocations/types';
import { GenericCodeValue } from '@/store/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { DeceasedDonor, DeceasedDonorOrgan } from '../deceasedDonors/types';

/**
 * Is the specified allocation recipient offer an open / proposed offer?
 *
 * @param offer offer data from allocation recipient entry
 * @returns {boolean} true only if offer is considered open, false otherwise
 */
function isOpenOffer(offer: AllocationOffer): boolean {
  if (!offer) return false;

  const responseCode = offer.response_code || null;
  return RESPONSE_CODES_CONSIDERED_TO_BE_OPEN.includes(responseCode);
}

/**
 * Which Inter-Provincial Organ-Sharing (IPOS) program does this allocation support integration
 * with for the national-level Canadian Transplant Registry (CTR) system?
 *
 * @returns {string}
 */
export function organIposProgram(organCode?: number, isCtrIposHeartEnabled?: boolean): string {
  let result = 'unknown';
  if (!organCode) return result;

  // Highly-Sensitized Patient (HSP) program
  // NOTE: only applies to kidney allocations
  if (CTR_HSP_ORGAN_CODES.includes(organCode)) {
    result = InterProvincialOrganSharingProgram.HSP;
  }

  // High-Status Heart (HSH) program
  // NOTE: only applies to heart allocations, and only if IPOS Heart feature is enabled
  if (CTR_HSH_ORGAN_CODES.includes(organCode) && isCtrIposHeartEnabled) {
    result = InterProvincialOrganSharingProgram.HSH;
  }

  return result;
}

export const getters: GetterTree<AllocationState, RootState> = {
  show(state): any {
    return state.exclusionRules;
  },
  selectedAllocation(state): Allocation|undefined {
    return state.selected;
  },
  recipients(state): AllocationRecipient[] {
    if (state.selected && state.selected.recipients) {
      return state.selected.recipients;
    } else {
      return [];
    }
  },

  /**
   * Returns true if selected allocation is heart organ and ipos heart enabled
   * 
   * @returns {boolean} if heart and ipos enabled
   */
  showIposForAllocation(state, getters, rootState, rootGetters): boolean {
    if (!state.selected) return false;
    const iposEnabled = rootGetters['features/ctrIposHeart'];
    return state.selected.organ_code == OrganCodeValue.Heart && iposEnabled;
  },
  
  donorDetails(state): DonorDetails[] | undefined {
    return state.donorDetails;
  },

  checklist(state): AllocationChecklistForm | undefined {
    return state.checklist;
  },

  checklistDetails(state): AllocationChecklistResponse | undefined {
    return state.checklistDetails;
  },

  getChecklistHistory(state): AllocationChecklistDetails[] | undefined {
    return state.checklistHistory || [];
  },

  selectedRecipientDetails(state): RecipientDetails | undefined {
    return state.recipientDetails;
  },
  allAllocations(state): Allocations[] {
    if (state.allAllocations && state.allAllocations.length > 0) {
      return state.allAllocations;
    }
    return [];
  },
  activeAllocations(state): Allocations[] {
    if (state.activeAllocations && state.activeAllocations.length > 0) {
      return state.activeAllocations;
    }
    return [];
  },
  findAllocations(_state, getters, _rootState, rootGetters): any {
    // return Allocation object from an organCode and if needed also an option (local / provincial)
    return (allocations: Allocations[], organCode: string, option?: string, doubleKidney?: boolean): Allocation[]|null => {
      // No organCode
      if (organCode == null) return null;

      // No allocations
      if (!allocations || allocations.length === 0) return null;

      // Fetch allocations for organ specified in the Organ Consent data
      const allocationsForOrgan = allocations?.find((allocationList: Allocations) => {
        const allocationOrganCode = allocationList.organ_code ? allocationList.organ_code.toString() : '';
        return allocationOrganCode == organCode;
      });

      // Return if we have no Allocations
      if (!allocationsForOrgan || allocationsForOrgan.allocations.length === 0) return null;

      // Filter allocations by option and return the allocation
      return allocationsForOrgan.allocations;
    };
  },
  findAllocationForPage(_state, getters, _rootState, rootGetters): any {
    // return Allocation object from an organCode and if needed also an option (local / provincial)
    return (allocations: Allocations[], organCode: string, option?: string, doubleKidney?: boolean): Allocation|null => {
      if (!allocations) return null; // check for null values

      // Filter out allocations with system-only states e.g. discontinued, error
      const filteredAllocations = allocations.filter((allocation: any) => {
        return !SYSTEM_ONLY_ALLOCATION_STATES.includes(allocation.state);
      });

      return getters.filterAllocationsByOption(filteredAllocations, option, doubleKidney || false);
    };
  },
  filterAllocationsByOption(_state, _getters, _rootState, rootGetters): any {
    return (allocations: Allocation[], option: string, doubleKidney: boolean): Allocation|null => {
      // Result to return
      let result: Allocation|null;

      if (!allocations) return null; // check for null values

      // Filter out any allocations that should not be displayed, regardless of order
      const filteredAllocations = allocations.filter((allocation: Allocation) => {
        return !allocation.superceded_by_id;
      });

      // Sort allocations
      const sortByDate: any = (rootGetters as any)['utilities/sortByDate'];
      const sortedAllocations = sortByDate(filteredAllocations, 'start_date');

      // Option is only applicable on Kidney allocations (local/provincial/double)
      if (option) {
        // Allocations to return or null if none match
        result = sortedAllocations.find((allocation: Allocation) => {
          // Get our Allocation type
          const allocationType = allocation.allocation_type || '';

          // In the case of a double allocation it can either be local or provincal
          // A local donor is one whose out_of_province indicator is false, otherwise it's provincal
          if (doubleKidney) {
            // Get our selected donor
            const donor: DeceasedDonor = (rootGetters as any)['deceasedDonors/show'] || {};
            // Is the selected donor out of province
            const outOfProvince = !!donor.indicators?.out_of_province;
            return allocation.double_kidney === true
              && allocationType.toLowerCase() === (outOfProvince ? 'provincial' : 'local');
          } else {
            return allocation.double_kidney === false
              && allocationType.toLowerCase() === option ? option.toLowerCase() : '';
          }
        }) || null;
      } else {
        // Return the most recent allocation by created_at
        result = sortedAllocations[0];
      }

      return result;
    };
  },

  getAllocationInfoByConsentedOrgan(state, getters, rootState, rootGeters): any {
    return (organConsent: DeceasedDonorOrgan, option?: string, doubleKidney?: boolean): Allocation|null => {
      // Fetch active allocations for organ specified in the Organ Consent data
      const allAllocationsForOrgan = getters.allAllocations?.find((allocationList: Allocations) =>{
        return allocationList.organ_code == organConsent.organ_code;
      });
      // Return if we don't have any allocations
      if (!allAllocationsForOrgan || allAllocationsForOrgan.allocations.length === 0) return null;

      // Filter allocations by option and return the active allocation
      return getters.filterAllocationsByOption(allAllocationsForOrgan.allocations, option, doubleKidney);
    };
  },
  recipientOffer(state, clientId?: any): any {
    // return recipients offer with client_id as input
    return (clientId?: any): AllocationOffer|undefined => {
      // if there is no selected allocation or no clientId
      if (!state.selected || !clientId) {
        return undefined;
      }
      // if there are recipients
      if (state.selected.recipients.length > 0) {
        const recipient = state.selected.recipients.find((item: AllocationRecipient) => {
          return item._id === clientId;
        });
        // is there a recipient and an offer?
        if (recipient && recipient.offer) {
          return recipient.offer;
        }
      }
      return undefined;
    };
  },

  allPrimaryBackupOffers(state): AllocationRecipient[] {
    // return all open primary offers for a selected allocation
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const allOffers = state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      const offerType = recipient.offer.offer_type_code;
      if (offerType == AllocationOfferTypeValues.Primary || offerType == AllocationOfferTypeValues.Backup) {
        if (recipient.offer.response_code != AllocationOfferResponseCodeValues.Withdraw && recipient.offer.response_code != AllocationOfferResponseCodeValues.Cancel) {
          return recipient;
        }
      }
    });
    return allOffers;
  },
  openPrimaryOffers(state): AllocationRecipient[] {
    // return all open primary offers for a selected allocation
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const openPrimaryOffers = state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === AllocationOfferTypeValues.Primary) {
        if (isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return openPrimaryOffers;
  },
  closedPrimaryOffers(state): AllocationRecipient[] {
    // return all closed primary offers for a selected allocation
    // (a closed offer is considered to have a response_code of not null)
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const closedPrimaryOffers = state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === AllocationOfferTypeValues.Primary) {
        if (!isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return closedPrimaryOffers;
  },
  primaryOffers(state): AllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    return state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      return recipient.offer.offer_type_code === AllocationOfferTypeValues.Primary;
    });
  },
  openBackupOffers(state): AllocationRecipient[] {
    // return all open backup offers for a selected allocation
    // (an open offer is considered to have a response_code of null)
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const openBackupOffers = state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === AllocationOfferTypeValues.Backup) {
        if (isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return openBackupOffers;
  },
  closedBackupOffers(state): AllocationRecipient[] {
    // return all closed backup offers for a selected allocation
    // (a closed offer is considered to have a response_code of not null)
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const closedBackupOffers = state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code === AllocationOfferTypeValues.Backup) {
        if (!isOpenOffer(recipient.offer)) {
          return recipient;
        }
      }
    });
    return closedBackupOffers;
  },
  backupOffers(state): AllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    return state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      return recipient.offer.offer_type_code === AllocationOfferTypeValues.Backup;
    });
  },
  openNoOffers(state): AllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    const openNoOffers = state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      if (recipient.offer.offer_type_code == AllocationOfferTypeValues.NoOffer && isOpenOffer(recipient.offer)) {
        return recipient;
      }
    });

    return openNoOffers;
  },
  /**
   * Return filtered offer responses options
   *
   * @returns {GenericCodeValue[]} Response options
   */
  noOffers(state): AllocationRecipient[] {
    if (!state.selected || state.selected.recipients.length <= 0) {
      return [];
    }
    return state.selected.recipients.filter((recipient: AllocationRecipient) => {
      if (!recipient.offer) return false;

      return recipient.offer.offer_type_code === AllocationOfferTypeValues.NoOffer;
    });
  },
  responseOptions(state, getters) {
    return (offer: AllocationResponse, offerResponses: GenericCodeValue[]): GenericCodeValue[] => {
      if (offerResponses && offerResponses.length <= 0) return [];
      const offerType: any = offer.offerType;
      const responseCode: any = offer.responseCode;
      const filteredOfferResponses = offerResponses.filter((item: any) => {
        switch(item.code) {
          case AllocationOfferResponseCodeValues.RequestExtension:
            return offerType == AllocationOfferTypeValues.Primary;
            break;
          case AllocationOfferResponseCodeValues.Accept:
            return [AllocationOfferTypeValues.Primary, AllocationOfferTypeValues.Backup].includes(offerType);
            break;
          case AllocationOfferResponseCodeValues.Decline:
            if (offerType == AllocationOfferTypeValues.ConvertToAcceptedPrimary) return true;
            if (responseCode !== AllocationOfferResponseCodeValues.AcceptWithCondition) {
              return true;
            }
            break;
          case AllocationOfferResponseCodeValues.Cancel:
          case AllocationOfferResponseCodeValues.Withdraw:
          case AllocationOfferResponseCodeValues.TimeOut:
            return false;
            break;
          default:
            return true;
            break;
        }
      });
      return filteredOfferResponses;
    };
  },

  /**
   * Return Reason Category options based on selected type
   *
   * Conditionals for Decline require a further sub set of options
   * based on the organ_code.
   *
   * @returns {GenericCodeValue[]} Reason Category options
   */
  reasonCategoryOptions(state, getters) {
    return (offer: AllocationResponse, offerResponses: GenericCodeValue[], organCode: string): GenericCodeValue[] => {
      const responseCode = offer.responseCode;
      switch (responseCode) {
        case AllocationOfferResponseCodeValues.AcceptWithCondition:
        case AllocationOfferResponseCodeValues.Withdraw:
        case AllocationOfferResponseCodeValues.RequestExtension:
          const reasonCategoryOptions = offerResponses.find((item: GenericCodeValue) => {
            return item.code == responseCode;
          });
          return reasonCategoryOptions?.sub_tables?.offer_reason_categories || [];
          break;
        case AllocationOfferResponseCodeValues.Cancel:
        case AllocationOfferResponseCodeValues.Decline:
          // Get response type lookup value
          const offerResponseType = offerResponses.find((item: any) => {
            return item.code == responseCode;
          });
          // Get organ specific sub table
          const organReasonCategories = offerResponseType?.sub_tables?.organ_specific_categories_reasons;
          if (!organReasonCategories || organReasonCategories.length <= 0) {
            return [];
          }
          // Get organ specific reason categories
          const organReasonCategoryOptions = offerResponseType?.sub_tables?.organ_specific_categories_reasons.find((item: any) => {
            return item.code == organCode;
          });
          return organReasonCategoryOptions.sub_tables.offer_reason_categories;
          break;
        default:
          return [];
          break;
      }
    };
  },

  /**
   * Return filtered reason options
   *
   * @returns {GenericCodeValue[]} Reason options
   */
  reasonOptions(state, getters) {
    return (offer: AllocationResponse, offerResponses: GenericCodeValue[], organCode: string): GenericCodeValue[] => {
      const responseCategoryCode = offer.responseCategoryCode;
      if (responseCategoryCode == null) {
        return [];
      }
      const reasonOptions = getters.reasonCategoryOptions(offer, offerResponses, organCode).find((item: GenericCodeValue) => {
        return item.code == responseCategoryCode.toString();
      });
      return reasonOptions ? reasonOptions.sub_tables.offer_reasons : [];
    };
  },

  // Disable response options
  disableResponseOptions(state, getters) {
    return (offer: AllocationResponse): boolean => {
      const offerType = offer.offerType;
      if (offerType == AllocationOfferResponseCodeValues.Accept || offerType == AllocationOfferResponseCodeValues.Decline || offerType == AllocationOfferResponseCodeValues.RequestExtension) {
        return true;
      }
      return !offerType ? true : false;
    };
  },

  // Disable response category options
  disableResponseCategoryOptions(state, getters) {
    return (offer: AllocationResponse): boolean => {
      const responseCode = offer.responseCode;
      if (responseCode == AllocationOfferResponseCodeValues.AcceptWithCondition || responseCode == AllocationOfferResponseCodeValues.Decline || responseCode == AllocationOfferResponseCodeValues.RequestExtension) {
        return false;
      }
      return true;
    };
  },
  /**
   * Return offer Response Category value
   *
   * @param responseCode response code
   * @param responseCategoryCode response category code
   * @param offerResponses list of offer responses
   * @param organCode organ code
   * @returns {string} response category value
   */
  offerResponseCategory(state, getters) {
    return (responseCode: string|null, responseCategoryCode: number|null, offerResponses: any[], organCode: number): string|undefined => {
      const offer = { responseCode };
      const responseCategory = getters.reasonCategoryOptions(offer, offerResponses, organCode).find((item: GenericCodeValue) => {
        return Number(item.code) == responseCategoryCode;
      });
      if (responseCategory) {
        return responseCategory.value;
      } else {
        return undefined;
      }
    };
  },
  /**
   * Return offer Response Reason value
   *
   * @param responseCode response code
   * @param responseCategoryCode response category code
   * @param reasonCode reason code
   * @param offerResponses list of offer responses
   * @param organCode organ code
   * @returns {string} reason code value
   */
  offerResponseReason(state, getters) {
    return (responseCode: string|null, responseCategoryCode: number|null, reasonCode: number|null, offerResponses: any[], organCode: string): string|undefined => {
      if (responseCode == null || responseCategoryCode == null || reasonCode == null) {
        return undefined;
      }
      const offer = { responseCode };
      const responseCategory = getters.reasonCategoryOptions(offer, offerResponses, organCode).find((item: GenericCodeValue) => {
        return Number(item.code) == responseCategoryCode;
      });
      if (responseCategory && responseCategory.sub_tables) {
        const reasons = responseCategory.sub_tables.offer_reasons;
        const reason = reasons.find((item: GenericCodeValue) => Number(item.code) == reasonCode);
        return reason ? reason.value : undefined;
      } else {
        return undefined;
      }
    };
  },

  /**
   * Check if offer has been confirmed
   *
   * @param offer allocation offer
   * @returns {boolean} offer confirmed
   */
  isOfferConfirmed(state) {
    return (offer: Allocation): boolean => {
      if (!offer) return false;
      return offer.state === 'offer-confirmed';
    };
  },

  /**
   * Check if offer has been accepted
   *
   * @param offer allocation offer
   * @returns {boolean} offer accepted
   */
  isOfferAccepted(state) {
    return (offer: Allocation): boolean => {
      if (!offer) return false;
      return offer.state === 'offer-accepted';
    };
  },

  /**
   * Check if offer is backup
   *
   * @param offer allocation offer
   * @returns {boolean} offer backup
   */
   isOfferingBackup(state) {
    return (offer: any): boolean => {
      if (!offer) return false;
      if (offer.offered_recipients && offer.offered_recipients.length == 0) return false;
      return offer.offered_recipients[0].offer_type_code == AllocationOfferTypeValues.Backup;
    };
  },

  /**
   * Check if the selected Allocation is offerable
   *
   * Allocation state is offering, offer-accepted or offer-confirmed and there are selected rows
   *
   * @returns {boolean} Allocation is offerable
   */
  isAllocationOfferable(state) {
    // There is no selected Allocation
    if (!state.selected) return false;
    // What states are offerable
    const allocationOfferableStates = ['offering', 'offer-accepted', 'offer-confirmed'];
    return allocationOfferableStates.includes(state.selected.state);
  },

  /**
   * Special Considerations for the selected Allocation
   *
   * This determines what is shown in both Allocation Details and Checklists, which must always
   * match to ensure user recipients are not unintentionally missed (see B#14478)
   *
   * NOTE: relies on 'deceasedDonors' and 'hospitals' modules to handle some considerations
   *
   * @returns {TranslationContext[]} array of objects containing i18n translation keys and template values
   */
  specialConsiderations(state, getters, rootState, rootGetters): TranslationContext[] {
    if (!state.selected) return [];

    // Derive special considerations from Allocation
    const considerations: TranslationContext[] = [];
    const allocation: Allocation = state.selected;

    // Kidney-specific considerations
    if (allocation.organ_code === OrganCodeValue.Kidney) {
      // Allocation Type e.g. Local or Provincial
      const allocationType = (allocation.allocation_type || 'unknown').toLowerCase();

      // Add Kidney Special Consideration message if this isn't a Double Kidney
      if (!allocation.donor.double_kidney) considerations.push({ key: 'special_consideration.allocation_type', values: { allocationType } });

      // Check for ECD flag and add consideration message
      if (allocation.donor.ecd) considerations.push({ key: 'special_consideration.ecd' });
    }

    // Manually added Recipients and Out-of-province Programs
    // NOTE: for Out-of-province Programs, we need to load program name from hospitals module
    const recipients: AllocationRecipient[] = allocation.recipients || [];
    const manualRecipients: AllocationRecipient[] = recipients.filter((recipient: AllocationRecipient) => {
      // NOTE: here we use an explicit equality check, to prevent "*******" from being treated as truthy
      return recipient.added_manually === true;
    });
    if (manualRecipients.length > 0) {
      const allHospitals = rootState.hospitals?.all || [];
      manualRecipients.forEach((recipient: AllocationRecipient) => {
        if (!recipient.out_of_province) {
          const recipientId = recipient.client_id.toString();
          considerations.push({ key: 'special_consideration.manually_added.recipient', values: { recipientId } });
        } else {
          const hospitalId = recipient.hospital_id;
          const recipientHospital = allHospitals.find((hospital: Hospital) => {
            return hospital._id.$oid === hospitalId;
          });
          const hospitalName = recipientHospital?.hospital_name_info?.name || 'unknown';
          if (hospitalName) considerations.push({ key: 'special_consideration.manually_added.out_of_province_program', values: { hospitalName } });
        }
      });
    }

    // Expedited Allocation
    if (allocation.expedited) {

      // Expedited Reason: Missing Donor Virology
      if (allocation.expedited_reasons.donor_virology_missing) {
        considerations.push({ key: 'special_consideration.expedited_allocation.missing_virology' });
      }

      // Expedited Allocation: Missing donor HLA Test Results
      if (allocation.expedited_reasons.donor_hla_typing_missing) {
        considerations.push({ key: 'special_consideration.expedited_allocation.missing_hla' });
      }

      // If we're an Expedited Allocation but we're not showing Virology
      // or HLA missing show an Unknown message
      if (!allocation.expedited_reasons.donor_virology_missing
          && !allocation.expedited_reasons.donor_hla_typing_missing) {
        // Expedited Allocation: Unknown
        considerations.push({ key: 'special_consideration.expedited_allocation.unknown' });
      }
    }

    // CTR Unavailable errors, based on 'ctr_unavailable_message' in the Allocation
    const ctrUnavailableErrors: CtrErrorContext[] = getters.selectedAllocationCtrUnavailable;

    // NOTE: the specific IPOS program can affect translations (HSP for kidneys vs HSH for hearts)
    const allocationIposProgram: string = getters.allocationIposProgram;

    if (ctrUnavailableErrors.length > 0) {
      // Add each individual error to the special consideration list
      ctrUnavailableErrors.forEach((context: CtrErrorContext) => {
        const errorId = context.ctr_error_id || UNKNOWN;
        const errorMessage = context.ctr_error_message || UNKNOWN;
        const parsedValue = context.parsedValue || UNKNOWN;
        considerations.push({ key: `special_consideration.ctr.${allocationIposProgram}.${errorId}`, values: { errorMessage, parsedValue } });
      });

      // Add the required special consideration explaining impact for HSP allocation
      const isHSPOrgan = CTR_HSP_ORGAN_CODES.includes(allocation.organ_code);

      // Add the required special consideration explaining impact for HSH allocation (see TPGLI-2006)
      // NOTE: only applies if the IPOS Hearts feature is enabled
      const isIPOSHeartEnabled = rootGetters['features/ctrIposHeart'];
      const isHSHOrgan = isIPOSHeartEnabled && CTR_HSH_ORGAN_CODES.includes(allocation.organ_code);

      if (isHSPOrgan || isHSHOrgan) {
        considerations.push({ key: `special_consideration.ctr.${allocationIposProgram}.recipients_missing` });
      }
    }

    return considerations;
  },

  /**
  * Return if the selected Allocation is provincial
  *
  * @returns {boolean} true if allocation_type is provincial
  */
  isProvincialAllocation(state): boolean {
    return state.selected?.allocation_type?.toLowerCase() === 'provincial';
  },

  /**
  * Return if the selected Allocation is local
  *
  * @returns {boolean} true if allocation_type is local
  */
  isLocalAllocation(state): boolean {
    if (!state.selected || !state.selected.allocation_type) return false;

    return state.selected.allocation_type.toLowerCase() === 'local';
  },

  // Get the ctr_error_id and ctr_error_message values from one or more offer actions in the post response
  parseCtrErrors() {
    return (actions: any[]): CtrErrorContext[] => {
      const actionsWithCtrErrors = actions.filter((action: any) => {
        return !!action.ctr_error_id;
      });
      const ctrErrors = actionsWithCtrErrors.map((action: any): CtrErrorContext => {
        return { ctr_error_id: action.ctr_error_id, ctr_error_message: action.ctr_error_message };
      });
      return ctrErrors;
    };
  },

  // Get array of ctr_error_id and ctr_error_message values from a stringified JSON object
  parseCtrUnavailableFromString(state) {
    return (ctrUnavailableMessage: string): CtrErrorContext[] => {
      // Check for missing value
      if (!ctrUnavailableMessage) { return [{ ctr_error_id: UNKNOWN, ctr_error_message: UNKNOWN }]; }

      // Check for unexpected flat string message
      // NOTE: here we simply use the presence of '{' character as a quick proxy check for JSON format
      const isJsonString = (ctrUnavailableMessage || '').includes('{');
      if (!isJsonString) { return [{ ctr_error_id: UNKNOWN, ctr_error_message: ctrUnavailableMessage }]; }

      // Now we can proceed to parse what is expected to be a stringified JSON object
      const parsedCtrUnavailableMessage = JSON.parse(ctrUnavailableMessage);
  
      // Check errors associated with allocation e.g. 'run HSP match on donor'
      const ctrErrors: CtrErrorContext[] = [];
      const ctrMethodKeys = Object.keys(parsedCtrUnavailableMessage);
      ctrMethodKeys.forEach((methodKey: string) => {
        const rawCtrErrors: CtrErrorContext[] = parsedCtrUnavailableMessage[methodKey] || [];
        rawCtrErrors.forEach((rawCtrError: CtrErrorContext) => {
          let parsedValue;
          let parserResult;
          // NOTE: here is where UI extracts parsed information from specific CTR error messages
          CTR_ERROR_MESSAGE_PARSERS.forEach((parser) => {
            if (parser.ctrMethod === methodKey && parser.ctrErrorId == rawCtrError.ctr_error_id) {
              parserResult = rawCtrError.ctr_error_message.match(parser.ctrErrorMessageParser);
              if (parserResult && parserResult.length > 0) parsedValue = parserResult[1];
            }
          });
          // NOTE: here is where UI injects method name prefix as part of the error ID
          const parsedCtrError: CtrErrorContext = {
            ctr_error_id: `${methodKey}.${rawCtrError.ctr_error_id || UNKNOWN}`,
            ctr_error_message: rawCtrError.ctr_error_message || UNKNOWN,
          };
          if (parsedValue) parsedCtrError.parsedValue = parsedValue;
          ctrErrors.push(parsedCtrError);
        });
      });
  
      return ctrErrors;
    };
  },

  // Get array of ctr_error_id and ctr_error_message values from allocation expedited reasons
  // NOTE: Webapp API stores stringified JSON object in the 'ctr_unavailable_message'
  parseCtrUnavailableFromAllocation(state, getters) {
    return (allocation: Allocation): CtrErrorContext[] => {
      if (!allocation.expedited_reasons?.ctr_unavailable) return [];

      // CTR errors related to allocation run are stored in 'allocation.expedited_reasons'
      const rawCtrUnavailableMessage = allocation.expedited_reasons?.ctr_unavailable_message;
      return getters.parseCtrUnavailableFromString(rawCtrUnavailableMessage);
    };
  },

  // Get ctr errors for selected allocation
  selectedAllocationCtrUnavailable(state, getters): CtrErrorContext[] {
    if (!state.selected) return [];

    return getters.parseCtrUnavailableFromAllocation(state.selected);
  },

  /**
   * Return a list of recipients from the selected allocation
   *
   * From the selected allocation, take the effective_rank and
   * find the corresponding recipient in the allocation.
   *
   * @param recipientRanks array of effective_rank
   * @returns {AllocationOfferRecipient[]} array of recipients id and offer_organ_code
   */
   getRecipientsByEffectiveRank(state, getters) {
     return (recipientRanks: number[]): AllocationOfferRecipient[] => {
      const validRecipients = getters.selectedAllocation.recipients.filter((recipient: AllocationRecipient) => {
        return recipientRanks.includes(recipient.effective_rank);
      });
      const recipientPayload: AllocationOfferRecipient[] = validRecipients.map((recipient: AllocationRecipient) => {
        const entry: AllocationOfferRecipient = {
          id: recipient._id,
          effective_rank: recipient.effective_rank,
          offer_organ_code: recipient.organ_code,
          hsp: getters.determineHspValue(recipient),
          hsh: getters.determineHshValue(recipient),
          re_offer_scenario: recipient.re_offer_scenario,
          cluster_organ_code: recipient.cluster_organ_code,
          cluster_organ_codes: recipient.cluster_organ_codes,
          offer_type_code: recipient?.offer?.offer_type_code || null,
          response_code: recipient?.offer?.response_code || null,
          offered_to_2nd_category: getters.offeringToSecondCategory(recipient),
          out_of_province: recipient?.out_of_province
        };
        return entry;
      });
      return recipientPayload;
    };
  },

  determineHspValue(state, getters) {
    return (recipient: AllocationRecipient): string => {
      // if HSP Patient Identified by CTR, show 'HSP' indicator
      return recipient.ranking_category && recipient.ranking_category == RANKING_CATEGORY_HSP ? 'HSP' : '-';
    };
  },

  determineHshValue(state, getters) {
    return (recipient: AllocationRecipient): string => {
      // if HSH Patient Identified by CTR, show 'HSH' indicator
      return recipient.ranking_category && recipient.ranking_category == RANKING_CATEGORY_HSH ? 'HSH' : '-';
    };
  },

  /**
   * Which Inter-Provincial Organ-Sharing (IPOS) program does this allocation support integration
   * with for the national-level Canadian Transplant Registry (CTR) system?
   *
   * @returns {string}
   */
  allocationIposProgram(state, getters, rootState, rootGetters): string {
    return organIposProgram(state.selected?.organ_code, rootGetters['features/ctrIposHeart']);
  },

  /**
   * Which recipient has discontinue offer flagged as 'Re-Allocated to Non-Intended'
   *
   * @returns {string|null} string from Recipient Object ID if found, null otherwise
   */
  intendedRecipientId(state, getters): string|null {
    const recipientEntries: AllocationRecipient[] = getters.selectedAllocation?.recipients || [];
    if (recipientEntries.length === 0) return null;

    const reAllocatedRecipients = recipientEntries.filter((entry: AllocationRecipient) => {
      return !!entry.reallocated_to_nonintended;
    });
    if (reAllocatedRecipients.length === 0) return null;

    // Here we assume finding the first matching Recipient is sufficient
    const reAllocatedRecipient = reAllocatedRecipients[0];
    return reAllocatedRecipient?._id || null;
  },

  // Check if Allocation has at least one Discontinue flagged as Re-Allocated to Non-Intended
  hasReAllocatedToNonIntended(state, getters): boolean {
    const discontinuesReAllocated = getters?.selectedAllocation?.reallocated_to_nonintended || 0;
    return discontinuesReAllocated > 0;
  },

  /**
   * Derive 'offered_to_2nd_category' flag as needed: true if an offer
   * being made to this recipient's entry would be an offer to their
   * 'second category' in the ranking rules.
   *
   * E.g. an HSP Kidney recipient with 'Medically Urgent' medical
   * status (H) can appear twice in a Kidney (Provincial) listing. In
   * this case the 'HSP Step' is the first category, and 'Medically
   * Urgent' is the second category.
   *
   * Essentially, all we need to do here is check if the recipient has
   * the 'is_2nd_ranking_entry' or 'has_2nd_ranking_entry' flags set.
   * If so, we send a boolean value for 'offered_to_2nd_category'.
   *
   * Note: this determination is only needed for recipients with two
   * entries in the allocation for the same organ. Most recipients will
   * have only one entry and for them this function will return 'false'.
   *
   * @param recipientEntry recipient row from Allocation Recommendation
   *
   * @returns {boolean} true only if offered to 'is_2nd_ranking_entry'
   */
   offeringToSecondCategory(state, getters) {
    return (recipientEntry: AllocationRecipient): boolean|undefined => {
      return !!recipientEntry.is_2nd_ranking_entry;
    };
  },

  // Get allocation recipient entries that are manually added and out of province
  manuallyAddedOutOfProvinceEntries(state, getters): AllocationRecipient[] {
    if (!state.selected) return [];

    const entries: AllocationRecipient[] = state.selected.recipients || [];
    const filtered = entries.filter((entry: AllocationRecipient) => {
      return entry.added_manually && entry.out_of_province;
    });
    return filtered;
  }
};
