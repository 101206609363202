
import { Getter, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import SiteHeader from './components/shared/SiteHeader.vue';
import SiteFooter from './components/shared/SiteFooter.vue';
import i18n from '@/i18n';
import { EP } from '@/api-endpoints';

@Component({
  components: {
    SiteHeader,
    SiteFooter
  }
})
export default class App extends Vue {
  @Getter('getUser', { namespace: 'users' }) private getUser!: any;
  @Getter('hasAccessToken', { namespace: 'users' }) private hasAccessToken!: boolean;
  @Getter('isLoggedIn', { namespace: 'users' }) private isLoggedIn!: boolean;

  public baseInfoLoaded = false;
  public organTypes: [] = [];

  /**
   * true if on /login url
   *
   * @returns {boolean}
   */
  get isLoginPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/login') ? true : false;
  }

  /**
   * true if on /access_token url
   *
   * @returns {boolean}
   */
  get isAccessTokenPage(): boolean {
    const path = window.location.pathname || '';
    return path.includes('/access_token') ? true : false;
  }

  public mounted(): void {
    if (this.hasAccessToken && !this.isLoginPage && !this.isAccessTokenPage) {
      this.$store.dispatch('users/loadUser').then(() => {
        Promise.all([
          this.$store.dispatch('features/load'),
          this.$store.dispatch('lookups/load', 'organ'),
          this.$store.dispatch('administration/loadEnvironment')
        ]).finally(() => {
          this.baseInfoLoaded = true;
        });
      });
    } else {
      this.$store.dispatch('features/load').then(() => {
        this.baseInfoLoaded = true;
      }).catch((error) => {
        this.baseInfoLoaded = true;
      });
    }
  }
}
