
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';

import CardSection from '@/components/shared/CardSection.vue';
import AssessmentStatusSection, { AssessmentPageState } from '@/components/organs/shared/AssessmentStatusSection.vue';

export interface AssessmentSectionPageState {
  assessment?: AssessmentPageState;
}

@Component({
  components: {
    CardSection,
    AssessmentStatusSection,
  }
})
export default class MedicalAssessmentSection extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private lookupsToLoad = [
    'recipient_assessment_decisions',
  ];

  /**
   * Populates form state with default values for the Consultation Section
   */
  public initializeForm(): void {
    // Initialize empty page state
    let assessmentPageState: AssessmentPageState = {};
    // Delegate building page states to the subsection
    const assessmentForm = this.$refs.medicalAssessment as AssessmentStatusSection;
    // Build page stage for Assessments
    if (!!assessmentForm) {
      assessmentPageState = assessmentForm.buildAssessmentPageState();
    }
    
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'assessmentSection',
      value: { assessment: assessmentPageState },
    });
    this.$emit('clear');
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    return (this.$refs.medicalAssessment as AssessmentStatusSection).idLookup;
  }

  // PRIVATE

  // Loading processes unique to this component
  private mounted(): void {
    // Clear stored medical assesment
    this.$store.commit('journeyState/clearAssessmentDecision');
    // Initialize Form
    this.initializeForm();
  }

  // Called after all lookups are loaded
  private loaded(): void {
    this.$emit('loaded', 'medicalAssessmentSection');
  }

  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }
}
