
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from '@/store/recipients/types';
import { RecipientJourney } from '@/store/recipientJourney/types';

import CardSection from '@/components/shared/CardSection.vue';
import ConsultationDetailsSection, { ConsultationPageState } from '@/components/organs/shared/ConsultationDetailsSection.vue';

export interface ConsultationSectionPageState {
  consultation?: ConsultationPageState;
}

@Component({
  components: {
    CardSection,
    ConsultationDetailsSection,
  }
})
export default class ConsultationSection extends Vue {
  // State
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;

  // Getters
  @Getter('clientId', { namespace: 'recipients' }) recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState', }) journeyId!: string|undefined;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;
  @Prop({ default: false }) canSave!: boolean;

  private lookupsToLoad = [
    'recipient_consultation_decisions',
  ];

  /**
   * Populates form state with default values for the Consultation Section
   */
  public initializeForm(): void {
    // Initialize empty page state
    let consultationPageState: ConsultationPageState = {};
    // Delegate building page states to the subsection
    const consultationForm = this.$refs.consultationDetails as ConsultationDetailsSection;
    // Build page state for Consultation
    if (!!consultationForm) {
      consultationPageState = consultationForm.buildConsultationPageState();
    }
    
    // Commit our state
    this.$store.commit('pageState/set', {
      pageKey: 'consultationSection',
      value: { consultation: consultationPageState },
    });
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    return (this.$refs.consultationDetails as ConsultationDetailsSection).idLookup;
  }

  // PRIVATE

  // Loading processes unique to this component
  private mounted(): void {
    Promise.all([
      this.$store.commit('journeyState/clearConsultationDecision'), // Clear stored consultation decision
      this.$store.dispatch('responsiblePhysicians/loadAllResponsiblePhysicians')
    ]).finally(() => {
      this.initializeForm(); // Initialize Form
    });
  }

  // Called after all lookups are loaded
  private loaded(): void {
    this.$emit('loaded', 'consultationSection');
  }
  
  // Emit event to parent so it can handle clearing validations when saving
  private clear() {
    this.$emit('clear');
  }

  // Emit event to parent so it can handle validations
  private handleErrors(errors: any) {
    this.$emit('handleErrors', errors);
  }
}
