
import { State, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Recipient } from '@/store/recipients/types';
import { organCodeLookup } from '@/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import BaseSideNav from '@/components/shared/side-nav/BaseSideNav.vue';
import SelectedRecipientHlaLinks from '@/components/recipients/side-nav/SelectedRecipientHlaLinks.vue';
import SelectedRecipientJourneyLinks from '@/components/recipients/side-nav/SelectedRecipientJourneyLinks.vue';
import SelectedRecipientProfileLinks from '@/components/recipients/side-nav/SelectedRecipientProfileLinks.vue';
import SideNavToolbar from '@/components/shared/side-nav/SideNavToolbar.vue';

@Component({
  components: {
    BaseSideNav,
    SideNavToolbar,
    SelectedRecipientHlaLinks,
    SelectedRecipientJourneyLinks,
    SelectedRecipientProfileLinks,
  }
})
export default class SideNavJourney extends Vue {
  @State(state => state.recipients.selectedRecipient) private recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) private journey!: RecipientJourney;
  
  @Getter('selectedRecipientJourneysList', { namespace: 'recipients' }) private selectedRecipientJourneysList!: { id: string; name: string, organCode: number }[];
  @Getter('isGroupWriteable', { namespace: 'validations' }) private isGroupWriteable!: (groupName: string) => boolean;
  @Getter('groupExists', { namespace: 'validations' }) private groupExists!: (groupName: string) => boolean;

  @Prop({ default: false }) active!: boolean;
  @Prop({ default: null }) newOrgan!: string|null;
  @Prop({ default: null }) organCode!: string;
  @Prop({ default: false }) newRecipient!: boolean;

  public show = true;
  public sideNavClosed = false;

  public toggleNav() {
    this.show = !this.show;
  }

  /**
   * Return organ name from the organ code using organCodeLookup
   * 
   * @returns {string} organ name 
   */
  get organName(): string {
    return this.organCode ? organCodeLookup[this.organCode] : '';
  }

  /**
   * Boolean used to show the side menu, if true side-nav is open
   * Changes the 'closed' class on the nav
   *
   * @returns {void} changes the sideNavClosed boolean
   */
  public toggleSideNav(): void {
    this.sideNavClosed = !this.sideNavClosed;
  }

  
}
