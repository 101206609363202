
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LoginContainer extends Vue {
  /**
   * return true if afflo
   *
   * @returns boolean
   */
  get isAfflo(): boolean {
    return process.env.VUE_APP_LOGO === 'AFFLO';
  }
}
