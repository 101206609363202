import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { LabVirology } from '@/store/labs/types';
import { RecipientJourney } from '@/store/recipientJourney/types';
import { ObjectId } from '@/store/types';
import { PancreasIsletsInfusion } from '../organSpecificDetails/types';

export interface RecipientsState {
  selectedRecipient?: Recipient;
  recipientsList?: RecipientsSearchResult;
  selectedPreviousTransplant?: RecipientOopTransplant;
  decisionEvents?: SignificantEventDecision[];
  outOfProvinceJourneys?: RecipientJourney[];
  previousTransplants?: RecipientOopTransplant[];
  selectedDeath?: DeathRecipient;
  selectedDiagnosticsHla?: RecipientDiagnosticsHla;
}

export interface RecipientsSearchResult {
  page_number: number;
  page_size: number;
  count: number;
  entries: Recipient[];
  sort_name: string;
  sort_direction: string;
}

export interface Recipient {
  _id?: ObjectId;
  client_id?: number;
  state?: string;
  patient_profile?: RecipientProfileDetails;
  financial_assistance?: RecipientFinancialAssistance;
  death?: DeathRecipient;
  physicians?: RecipientPhysicians;
  diagnostics?: RecipientDiagnostics;
  measurements?: RecipientMeasurement[];
  journeys?: RecipientJourney[];
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  urgent?: boolean;
  urgent_last_toggled_date?: string;
  dialysis_procedures?: DialysisProcedure[];
  validations?: RecipientValidations;
  virology_labs?: LabVirology[];
  insurance_plan_code?: number;
  attachments?: RecipientFinancialAttachment[];
  potential_duplicate_profile_confirmed?: boolean;
  oop_recipient?: boolean;
}

export interface RecipientProfileDetails {
  _id?: ObjectId;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  ethnicity_code?: string|null;
  ethnicity?: string;
  ethnicity_other?: string|null;
  sex?: string|null;
  gender?: string|null;
  gender_sex_different?: boolean;
  gender_other?: string|null;
  birth?: {
    _id?: ObjectId;
    city?: string;
    province?: string;
    country?: string;
    date?: string|null;
  };
  insurance?: {
    _id?: ObjectId;
    plan_code?: number;
    number?: string | null;
    other_province_plan?: string | null;
    other_plan?: number | null;
  };
  addresses?: RecipientAddress[];
  contact_details?: {
    phone_primary?: string;
    phone_alternative?: string;
    phone_mobile?: string;
    phone_pager?: string;
    email?: string;
  };
  ctr?: {
    _id?: ObjectId;
    // TODO: look into what the types should be for CTR IDs
    ctr_last_updated?: string;
    national_recipient_id?: any;
    national_recipient_serum_id?: any;
    now_consent_withdrawn?: boolean;
  };
  comments?: string;
  effective_date?: string;
  expired_date?: string;
  created_at?: string;
  created_by?: string;
  updated_at?: string;
  updated_by?: string;
  age?: string|null;
}

export interface DeathRecipient {
  cause_category_code?: string|null;
  cause_type_code?: number|null;
  cause_type_other?: string|null;
  death_date?: string|null;
  estimated_death_date?: boolean|null;
  comments?: string;
  graft_functioning_at_death?:string;
}

export interface RecipientPhysicians {
  attending: RecipientPhysician;
  family: RecipientPhysician;
  referring: RecipientPhysician;
}

export interface RecipientPhysician {
  name?: string;
  street?: string;
  city?: string;
  province?: string;
  country?: string;
  postal_code?: string;
  phone?: string;
}

export interface RecipientDiagnostics {
  blood?: RecipientDiagnosticsBlood;
  hla?: RecipientDiagnosticsHla;
}

export interface RecipientDiagnosticsBlood {
  type?: string|null;
  type_verified?: boolean|null;
  rh_indicator?: string|null;
}

export interface RecipientDiagnosticsHla {
  hla_comments?: string;
  hla_comments_updated_at?: string;
  cumulative_antibodies?: AntibodiesCumulative;
  cumulative_pra_class1?: number|null;
  cumulative_pra_class2?: number|null;
  cumulative_cpra?: number|null;
  cumulative_cpra_comments?: string|null;
}

export interface AntibodiesCumulative {
  class1?: HlaCumulativeAntibodyClass;
  class2?: HlaCumulativeAntibodyClass;
}

export interface HlaCumulativeAntibodyClass {
  antibodies: HlaAntibodyArrays;
  epitopes?: HlaEpitopeArrays;
}

export interface HlaAntibodyArrays {
  acceptable?: string[];
  unacceptable_allele_group?: string[];
  unacceptable_allele_specific?: string[];
  unacceptable_alpha_beta?: string[];
  indeterminate_allele_group?: string[];
  indeterminate_allele_specific?: string[];
  indeterminate_alpha_beta?: string[];
  possible_allele_specific?: string[];
  untested?: string[];
}

export interface HlaEpitopeArrays {
  acceptable?: string[];
  unacceptable?: string[];
  indeterminate?: string[];
  untested?: string[];
}

export interface RecipientOopTransplant {
  journeyId?: ObjectId;
  organ_code?: number;
  organ_code_other?: string;
  transplant_date?: string;
  facility?: string;
  province?: string;
  country?: string;
  country_other?: string;
  dialysis_start_date?: string;
}

export interface RecipientFinancialAssistance {
  program_type?: string;
  status?: string;
  date?: string|null;
  comments?: string;
  tper_applicable?: boolean;
}

export interface RecipientMeasurement {
  _id?: ObjectId;
  date?: string;
  weight?: number|null;
  height?: number|null;
  weight_lbs?: number;
  height_in?: number;
  abdominal_girth?: number;
  chest_circumference?: number;
  chest_vertical_left?: number;
  chest_vertical_right?: number;
  chest_transverse?: number;
  bmi?: number;
}

export interface RecipientAddress {
  _id?: ObjectId;
  type?: string;
  street?: string;
  city?: string;
  province_code?: string|null;
  country_code?: string;
  postal_code?: string;
  country_other?: string|null;
}

export interface RecipientMedicalSuspendedReason {
  suspended_reason_code?: number;
  suspended_reason?: string;
  suspended_date?: string;
  suspended_lab_test_id?: number;
}

export interface RecipientMedicalHoldReason {
  medical_hold_reason_code?: number;
  medical_hold_reason?: string;
  medical_hold_assessment_date?: string;
  medical_hold_reason_comment?: string;
  medical_hold_email_alert_first?: string;
  medical_hold_email_alert_second?: string;
  medical_hold_email_alert_third?: string;
}

export interface DialysisProcedure {
  _id?: ObjectId;
  access_method_code?: string;
  dialysis_type_code?: string;
  dialysis_hospital_id?: string;
  location_type_code?: string;
  other_location_desc?: string;
  dialysis_provider_code?: number|null;
  start_date?: string|null;
  end_date?: string|null;
}

export interface RecipientTransplantAttributes {}

export interface RecipientPostTransplantAttributes {}

export interface RecipientValidations {
  rules?: {
    state?: any;
    patient_profile?: any;
    diagnostics?: any;
  };
}

export const recipientState: RecipientsState = {};

export interface RecipientSignificantEvent {
  _id: ObjectId;
  recipient_id: ObjectId;
  _type: string;
  event_date: string;
}

export enum RecipientSignificantEventType {
  Stage = 'sg',
  State = 's',
  Factor = 'f',
  Decision = 'd',
  DecisionDetailsChanged = 'd-dc',
  MedicalHold = 'f-hm',
  MedicalHoldDetailsChanged = 'f-hm-dc',
  Journey = 'j',
  WaitlistRemoval = 'wr',
}

export interface DecisionDetails {
  decision_type: string;
  coordinator_id: ObjectId;
  primary_physician_id: ObjectId;
  delay_end_date: string;
  delay_start_date: string;
  reason_code: number;
  other_reason: string;
  comments: string;
  // TODO: When multi-select component is in, fix the interface
  absolute_contraindication_code: any[];
  relative_contraindication_code: any[];
}

export interface SignificantEventDecision extends RecipientSignificantEvent {
  journey_id: ObjectId;
  stage: string;
  stage_factor_code: number;
  decision_code: number;
  decision_type: string;
  decision_details: DecisionDetails;
}
export interface RecipientFinancialAttachment {
  _id: ObjectId;
  created_at?: string;
  updated_at?: string;
  original_filename?: string;
  description?: string;
  mime_type?: string;
  created_by?: string;
  deleted?: boolean;
}

export interface journeyStatusOption {
  stage: string;
  state?: string;
  completed: boolean;
  status?: string;
  decisionType?: string;
  decisionState?: string;
  waitlistFactors?: string;
  stageDisplayValue?: string;
  statusDisplayValue?: string;
  listPageStatus?: string;
}
