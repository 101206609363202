
import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import ModalSection from '@/components/shared/ModalSection.vue';
import SelectInput from '@/components/shared/SelectInput.vue';
import store from '@/store';
import i18n from '@/i18n';
import { APIBaseRoute, EP } from '@/api-endpoints';

interface RoleChoice {
  code?: string,
  value?: string
}

@Component({
  components: {
    ModalSection,
    SelectInput
  }
})
export default class UserMenu extends Vue {
  @Getter('getUserName', { namespace: 'users' }) private getUserName!: string;
  @Getter('getHelpUrl', { namespace: 'administration' }) private getHelpUrl!: string;
  @Getter('isLanguageSelectEnabled', { namespace: 'administration' }) private isLanguageSelectEnabled!: string;
  @Getter('bypassFusionAuth', { namespace: 'features' }) private bypassFusionAuth!: boolean;

  public preventClose(event: any): void {
    event.stopPropagation();
  }

  public setLocale(event :any): void {
    const language = event;
    if (language) {
      localStorage.setItem('language', language);
      i18n.locale = language;
    }
  }

  get langOptions(): any {
    return [
      { code: 'en' , value: this.$t('english')},
      { code: 'fr' , value: this.$t('french') }
    ];
  }

  get getCurrentLanguage(): string {
    return this.$t('language') + " " +this.langOptions.find((t: any) => t.code === this.$i18n.locale).value;
  }

  logout(): void {
    store.dispatch('users/logout').then(() => {
      // if fusionauth enabled and access token exists, redirect to user list page
      if (!this.bypassFusionAuth && localStorage.getItem('access_token')) {
        const access_token = localStorage.getItem('access_token') || '';
        const url = APIBaseRoute(EP.fusionAuth.access_token);      
        window.location.href = url;
      // otherwise login page
      } else {
        window.location.href = "/login";
      }
    });
  }
}
