
import { Getter, State } from 'vuex-class';
import { mixins } from "vue-class-component";
import { Recipient } from '@/store/recipients/types';
import { OrganCodeValue } from '@/store/lookups/types';
import { organCodeLookup, ModalContent } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextInput from "@/components/shared/TextInput.vue";
import SubSection from '@/components/shared/SubSection.vue';
import SelectInput from "@/components/shared/SelectInput.vue";
import ModalSection from "@/components/shared/ModalSection.vue";
import TextAreaInput from "@/components/shared/TextAreaInput.vue";
import { WaitDaysAdjustmentReasons } from '@/store/lookups/types';
import { SaveResult, SaveableSection, SaveProvider } from '@/types';
import SelectOtherInput from "@/components/shared/SelectOtherInput.vue";
import { TranslationUtilsMixin } from "@/mixins/translation-utils-mixin";
import { Organ, OrganWaitlistMedicalStatus, HeartMedicalStatusValue, ReasonForMedicalHold } from '@/store/lookups/types';
import { WaitlistSectionPageState } from '@/components/organs/shared/_WaitlistSection.vue';
import { JourneyStage, RecipientJourney, RecipientWaitlistAttributes, RecipientWaitlistFactors, WaitlistStatusValue } from '@/store/recipientJourney/types';

export interface AddToWaitlistState {
  medicalStatus?: string;
  secondaryMedicalStatus?: string;
  applyMedicalHold?: boolean;
  reasonForHold?: number;
  ifOtherReason?: string;
  holdComments?: string;
  modalErrorMessages?: string[];
}

const ADD_TO_WAITLIST_MODAL_ERRORS = [
  'validation.messages.add_to_waitlist.pancreas_after_kidney',
  'validation.messages.add_to_waitlist.staged_kidney',
  'validation.messages.add_to_waitlist.active_vad_procedure_destination_therapy',
];

@Component({
  components: {
    TextInput,
    SubSection,
    SelectInput,
    ModalSection,
    TextAreaInput,
    SelectOtherInput,
  },
})
export default class AddToWaitlist extends mixins(TranslationUtilsMixin) {
  @State(state => state.lookups.organ) organLookup!: Organ[];
  @State(state => state.recipients.selectedRecipient) recipient!: Recipient;
  @State(state => state.journeyState.selectedJourney) journey!: RecipientJourney;
  @State(state => state.pageState.currentPage.waitlistSection) editState!: WaitlistSectionPageState;
  @State(state => state.lookups.medical_hold_reasons) reasonForMedicalHoldOptions!: ReasonForMedicalHold[];

  @Getter('clientId', { namespace: 'recipients' }) private recipientId!: string;
  @Getter('journeyId', { namespace: 'journeyState' }) journeyId!: string|undefined;
  @Getter('waitTimeDays', { namespace: 'journeyState' }) waitTimeDays!: number|null;
  @Getter('isHeartJourneyOrHeartCluster', { namespace: 'journeyState' }) isHeartJourneyOrHeartCluster!: boolean
  @Getter('waitlistStatusDescription', { namespace: 'journeyState' }) waitlistStatusDescription!: (attributes: RecipientWaitlistAttributes) => string;
  @Getter('medicalStatusLookup', { namespace: 'lookups' }) medicalStatusLookup!: (excludeHold: boolean, organLookup?: Organ[], organCode?: number) => any;
  @Getter('secondaryMedicalStatusLookup', { namespace: 'lookups' }) secondaryMedicalStatusLookup!: (organLookup?: Organ[], organCode?: number) => any;
  @Getter('describeMedicalStatus', { namespace: 'lookups' }) describeMedicalStatus!: (organLookup?: Organ[], organCode?: number, medicalStatusCode?: string|null, secondaryMedicalStatusCode?: string|null) => string|undefined;
  @Getter('checkAllowed', { namespace: 'users' }) private checkAllowed!: (url: string, method?: string) => boolean;

  // Properties
  @Prop({ default: false }) newJourney!: boolean;

  // Whether or not user can Add to Waitlist is based on the add_to_waitlist endpoint
  get canSave(): boolean {
    return this.journey && !this.journey.completed && this.checkAllowed('/recipients/:recipient_id/journeys/:journey_id/assessment/add_to_waitlist', 'PUT');
  }

 /**
   * Gets the organ name
   * 
   * @returns {String} organ name
   */
  get organDescription(): string {
    return this.organComponent;
  }

  /**
   * Gets the current journey as lower case
   * 
   * Using the organ code, return the lower case organ name
   * 
   * @returns {String} organ as lower case
   */
  get organComponent(): string {
    if (!this.journey) {
      const organCode: string = this.$route.params.organ_code;
      return organCode === null ? "" : organCodeLookup[organCode];
    } else {
      return this.journey.organ_code === null ? "" : organCodeLookup[this.journey.organ_code!.toString()];
    }
  }

  /**
   * Emits a loaded event when the component has finished mounting
   * 
   * @emits loaded
   */
  private mounted(): void {
    this.initializeForm();
    this.$emit('loaded', 'addToWaitlist');
  }

  /**
   * Populates form state with default values for the Add to Waitlist sub-section
   */
  private initializeForm(): void {
    this.$store.commit('pageState/set', {
      pageKey: 'waitlistSection',
      componentKey: 'addToWaitlist',
      value: this.buildAddToWaitlistState(),
    });
  }

  /**
   * Gets a boolean value representing whether or not to show Secondary Medical Status in the Add to Waitlist form
   * 
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showSecondaryMedicalStatus(): boolean {
    if (!this.editState || !this.editState.addToWaitlist) {
      return false;
    }
    return this.editState.addToWaitlist.medicalStatus == HeartMedicalStatusValue.Sensitized;
  }

  /**
   * Changing a medical status should clear the secondary medical status selection
   */
  private clearSecondaryMedicalStatus(): void {
    if (!this.editState || !this.editState.addToWaitlist) {
      return;
    }
    Vue.set(this.editState.addToWaitlist, 'secondaryMedicalStatus', undefined);
  }

  /**
   * Gets whether or not to show Reason for Hold
   * 
   * @returns {boolean} true if the form area should be shown, false otherwise
   */
  get showMedicalHoldDetails(): boolean {
    if (!this.editState || !this.editState.addToWaitlist) {
      return false;
    }
    return this.editState.addToWaitlist.applyMedicalHold || false;
  }

  /**
   * Build state for the Add to Waitlist form area (a.k.a. Waitlist Decision)
   * 
   * @param waitlistAttributes recipient journey stage attributes for waitlist
   * @returns {AddToWaitlistState} form state for Add to Waitlist
   */
  public buildAddToWaitlistState(waitlistAttributes?: RecipientWaitlistAttributes): AddToWaitlistState {
    return {};
  }

  /**
   * Saves the Add to Waitlist form
   * 
   * @emits saving saving has begun
   * @emits saved saving has completed successful
   */
  public savePatch(): void {
    // Refer to the save provider that handles this form area
    const saveProvider = this.$refs.addToWaitlistButton as unknown as SaveProvider;
    // Report to parent that saving has began
    this.$emit('saving', 'addToWaitlist');
    // Generate payload based on current edit state
    const waitlistAttributesPatch = this.extractPatch();
    // Setup saving payload
    const payload = {
      journeyId: this.journeyId,
      recipientId: this.recipientId,
      waitlistAttributes: waitlistAttributesPatch,
      prefix: 'addToWaitlist',
    };
    // Dispatch save action and register the response
    this.$store.dispatch('journeyState/addToWaitlist', payload).then((success: SaveResult) => {
      saveProvider.registerSaveResult(success);
      // Display outcome notification based on resulting Waitlist Attributes data
      const waitlistAttributes = success?.responseData?.waitlist_attributes || {};
      this.displayOutcomeNotification(waitlistAttributes);

      // Reload recipient and journey for latest Waitlist Factors
      this.$store.dispatch('recipients/get', this.recipientId).then(() => {
        this.$store.dispatch('journeyState/getJourney', this.journeyId);
      });

      // Report to parent that saving has completed so it can clear validations and reload waitlist decisions
      this.$emit('saved', 'addToWaitlist');
      
    }).catch((error: SaveResult) => {
      // Do we have any waitlist errors
      if (this.checkModalErrors(error)) {
        // Display generic error message for SaveProvider
        saveProvider.registerSaveResult({
          success: error.success,
          errorMessages: [this.$t('validation.messages.add_to_waitlist.generic_error').toString()] 
        });
      } else {
        // Emit event to handle errors
        this.$emit('handleErrors', error);
        // Show error notification
        saveProvider.registerSaveResult(error);
      }
    });
  }

  // Check if the error result needs an error popup modal
  private checkModalErrors(error: SaveResult): boolean {
    const errorMessages: string[] = error?.errorMessages || [];
    const modalErrors = errorMessages.filter((error: string) => {
      return ADD_TO_WAITLIST_MODAL_ERRORS.includes(error);
    });
    if (modalErrors.length > 0) {
      this.displayErrorModal(modalErrors);
      return true;
    } else {
      return false;
    }
  }

  // Display a outcome modal for the add to waitlist action
  private displayErrorModal(modalErrorKeys: string[]): void {
    if (!this.editState || !this.editState.addToWaitlist) return;

    // Translate error keys to display messages
    const modalErrorMessages: string[] = modalErrorKeys.map((errorKey: string): string => {
      return this.$t(errorKey).toString();
    });

    // Set our modalContent
    Vue.set(this.editState.addToWaitlist, 'modalErrorMessages', modalErrorMessages);

    // Display the error modal
    const errorModal = this.$refs.waitlistErrorModal as ModalSection;
    if (errorModal) errorModal.showModal();
  }

  // Handle translations for Wait Time
  get waitTimeDescription(): string {
    return this.translateWaitTime(this.waitTimeDays);
  }

  /**
   * Define what is needed for the outcome modal and emit an event
   *
   * @params attributes Recipient Journey Waitlist Attributes from successful Add to Waitlist response
   */
  private displayOutcomeNotification(attributes: RecipientWaitlistAttributes): void {
    // Always describe organ and waitlist status: "Active" or a description of holds/suspensions
    const statusDescription = this.waitlistStatusDescription(attributes) || 'Error';
    // Check status to determine what style of modal notification to display
    const isActive = statusDescription === WaitlistStatusValue.Active;
    // Build template string for modal body
    let body = `${this.$t('recip_success_added')} ${this.organDescription} ${this.$t('waitlist_waitlist_status')}:<br /><br />${statusDescription}`;
    // If the journey was an automated relisting (Graft-Failure < 90 Days), then also describe wait time reinstatement
    const factors = attributes?.factors || {};
    const isReinstatingWaitTime = factors.wait_days_adjustment_reason_code === WaitDaysAdjustmentReasons.GraftFailureWithin90DaysOfTransplant;
    if (isReinstatingWaitTime) {
      const waitDays = this.waitTimeDescription || 'Error';
      body += `<br /><br />${this.$t('wait_time_reinstatement')} ${waitDays} ${this.$t('for_this_journey')}.`;
    }
    // Choose a modal style based on the waitlist status and reinstatement logic
    // E.g. success if active, danger if inactive, warning if inactive with a reinstatement
    let style = 'modal-success';
    if (!isActive && isReinstatingWaitTime) {
      style = 'modal-warning';
    } else if (!isActive && !isReinstatingWaitTime) {
      style = 'modal-danger';
    }
    // Emit event for top-level waitlist section to show outcome
    const modalContent: ModalContent = {
      style,
      body,
    };
    this.$emit('display-outcome-notification', modalContent);
  }

  /**
   * Returns an API patch based on form state
   * 
   * @returns {any} API patch
   */
  public extractPatch(): any {
    if (!this.editState || !this.editState.addToWaitlist) {
      return {};
    }
    // Fetch data from the Add to Waitlist form
    const form = this.editState.addToWaitlist || {};
    const isApplyingMedicalHold = form.applyMedicalHold || false;
    // Determine which type of patch to send
    if (!isApplyingMedicalHold) {
      return this.extractAddToWaitlistPatch(form);
    } else {
      return this.extractListWithInitialMedicalHoldPatch(form);
    }
  }

  /**
   * Returns a Waitlist Attributes patch for adding a journey to the waitlist
   * 
   * @param form Add to Waitlist UI form state
   * @returns {RecipientWaitlistAttributes} Waitlist Attributes API patch
   */
  private extractAddToWaitlistPatch(form: AddToWaitlistState): RecipientWaitlistAttributes {
    // Waitlist Factors for initial Medical Status
    const medicalStatusFactors: RecipientWaitlistFactors = {
      medical_status_code: form.medicalStatus !== undefined ? form.medicalStatus : null,
      medical_status_secondary_code: this.showSecondaryMedicalStatus ? form.secondaryMedicalStatus || null : null,
    };
    // Waitlist factors for preventing a Medical Hold
    const medicalHoldFactors: RecipientWaitlistFactors = {
      on_hold_medical: false,
      on_hold_medical_date: null,
      on_hold_medical_reason_code: null,
      on_hold_medical_other_reason: null,
      on_hold_medical_reason_comment: null,
      on_hold_medical_expiry_date: null,
      on_hold_medical_extension_date: null,
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors: {
        ...medicalStatusFactors,
        ...medicalHoldFactors
      },
    };
    return result;
  }

  /**
   * Returns a Waitlist Attributes patch when adding to waitlist with an initial medical hold
   * 
   * @param form Add to Waitlist UI form state
   * @returns {RecipientWaitlistAttributes} Waitlist Attributes API patch
   */
  private extractListWithInitialMedicalHoldPatch(form: AddToWaitlistState): RecipientWaitlistAttributes {
    // Waitlist factors for Medical Status
    const medicalStatusFactors: RecipientWaitlistFactors = {
      medical_status_code: form.medicalStatus !== undefined ? form.medicalStatus : null,
      medical_status_secondary_code: this.showSecondaryMedicalStatus ? form.secondaryMedicalStatus || null : null,
    };
    // Waitlist factors for Medical Hold
    const reasonCode = form.reasonForHold;
    const reasonDocument = this.reasonForMedicalHold(reasonCode);
    const isOther = reasonDocument === undefined ? false : reasonDocument.other_selected || false;
    const medicalHoldFactors: RecipientWaitlistFactors = {
      on_hold_medical: true,
      on_hold_medical_reason_code: reasonCode,
      on_hold_medical_other_reason: isOther ? form.ifOtherReason : null, // if other
      on_hold_medical_reason_comment: form.holdComments,
      on_hold_medical_extension_date: null,
    };
    // Waitlist attributes with all factor changes included
    const result: RecipientWaitlistAttributes = {
      factors: {
        ...medicalStatusFactors,
        ...medicalHoldFactors,
      },
    };
    return result;
  }

  /**
   * Returns full lookup document for a Reason for Medical Hold
   * 
   * @param reasonCode code number
   * @returns {ReasonForMedicalHold|undefined} lookup document if it exists, undefined otherwise
   */
  private reasonForMedicalHold(reasonCode?: number): ReasonForMedicalHold|undefined {
    if (!this.reasonForMedicalHoldOptions || !reasonCode) {
      return undefined;
    }
    return this.reasonForMedicalHoldOptions.find((reason: ReasonForMedicalHold) => {
      return reason.code == reasonCode;
    });
  }

  /**
   * Clears all save notifications shown by the form.
   * 
   * Gets the Save Provider associated with the form, and requests that it reset its own Save Toolbar
   */
  public resetSaveToolbar(): void {
    const saveProvider = this.$refs.addToWaitlistButton as unknown as SaveProvider;
    saveProvider.resetSaveToolbar();
  }

  /**
   * Get a numeric representation of which Organ is associated with the selected Journey
   * 
   * @returns {number} code that can be used when fetching entries from the Organ lookup table
   */
  get organCode(): number {
    if (!this.journey || !this.journey.organ_code) {
      return 0;
    }
    return this.journey.organ_code;
  }

  /**
   * Gets an array of options for Medical Status
   * 
   * @returns {OrganWaitlistMedicalStatus[]} organ-specific options for medical status
   */
  get medicalStatusOptions(): OrganWaitlistMedicalStatus[] {
    // Delegate common medical status handling to shared utils file
    const options = this.medicalStatusLookup(true, this.organLookup, this.organCode);
    const journeyStage = this.journey?.stage;

    // For Urgent Listing filter to only include urgent listing options
    const isUrgent = this.journey?.urgent || false;
    // system should allow user to change medical status to any non-urgent selection after listing 
    if (!isUrgent || journeyStage === JourneyStage.Waitlist) return options;

    const filtered = options.filter((lookup: OrganWaitlistMedicalStatus) => {
      return lookup.urgent_listing_option;
    });
    return filtered;
  }

  /**
   * Gets an array of options for Secondary Medical Status
   * 
   * @returns {OrganWaitlistMedicalStatus[]} organ-specific options for secondary medical status
   */
  get secondaryMedicalStatusOptions(): OrganWaitlistMedicalStatus[] {
    return this.secondaryMedicalStatusLookup(this.organLookup, this.organCode);
  }

  /**
   * Include an additional note in the confirmation text if an automatic relisting applies to this journey
   * 
   * @returns {string} note about wait time reinstatement if it is applicable, empty string otherwise
   */
  get automaticRelistingNote(): string {
    if (!this.journey) {
      return '';
    }
    // Fetch relevant details from journey state
    const waitlistFactors = this.journey.stage_attributes?.waitlist?.factors || {};
    const waitTimeAdjustmentReason = waitlistFactors.wait_days_adjustment_reason_code;
    const isAutomaticRelisting = waitTimeAdjustmentReason === WaitDaysAdjustmentReasons.GraftFailureWithin90DaysOfTransplant;
    // Build string with specific indentation and line breaks
    return isAutomaticRelisting ? `${this.$t('note')}

` : '';
  }

  /**
   * Gets a string used to populate the confirmation alert dialog when adding a recipient to waitlist
   * 
   * @returns {string} text for confirmation property of Sub Section
  */
  get confirmationText(): string {
    // Fetch form state
    const form = this.editState?.addToWaitlist || {};
    // Check if placing medical hold
    const isInitialMedicalHold = form.applyMedicalHold || false;
    // Describe medical status and secondary medical status using a combination of codes and values
    const medicalStatus = this.describeMedicalStatus(this.organLookup, this.organCode, form.medicalStatus) || 'Unknown';
    const secondaryMedicalStatus = this.describeMedicalStatus(this.organLookup, this.organCode, form.secondaryMedicalStatus);
    // Build string with specific indentation and line breaks
    const result = `${this.$t('this_action_will_add')} ${this.organDescription} ${this.$t('waitlist_with_following')}:

${this.$t('medical_status')}: ${isInitialMedicalHold ? `${this.$t('zero')}` : `${medicalStatus}${secondaryMedicalStatus !== undefined ? `

${this.$t('secondary_medical_status')}: ${secondaryMedicalStatus}` : ''}`}

${this.automaticRelistingNote}${isInitialMedicalHold && this.isHeartJourneyOrHeartCluster ? `${this.$t('beacuse_patient_waitlisted')}

` : ''}${this.$t('proceed')}`;
    return result;
  }

  // API response keys on the left, id for our UI on the right
  public idLookup(): {[key: string]: string} {
    return {
      'addToWaitlist.factors.medical_status_code'            : 'wd-status',
      'addToWaitlist.factors.medical_status_secondary_code'  : 'wd-secondary-status',
      'addToWaitlist.factors.on_hold_medical'                : 'wd-medical-hold',
      'addToWaitlist.factors.on_hold_medical_reason_code'    : 'wd-hold-reason',
      'addToWaitlist.factors.on_hold_medical_other_reason'   : 'waitlist-decision-other-reason',
      'addToWaitlist.factors.on_hold_medical_reason_comment' : 'waitlist-decision-comments',
    };
  }
}
